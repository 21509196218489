import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService, StorageKey } from '../../common/storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService) { }

    dashboardDetails(data:any = {}): Observable<any>{
      if(data && data.user_id){
        return this.httpClient.get(`${environment.apiUrl}dashboard?user_id=`+data.user_id);
      }else{
        return this.httpClient.get(`${environment.apiUrl}dashboard`);
      }
    }  
}
