import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/core/common/storage.service';
import { CandidatejobsService } from 'src/app/core/services/candidatejobs/candidatejobs.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-appliedjobs',
  templateUrl: './appliedjobs.component.html',
  styleUrls: ['./appliedjobs.component.css']
})
export class AppliedjobsComponent implements OnInit {

  tenant: any;
  candidate_id: any
  logo_path: any
  appliedjoblist: any = [];
  safeDescriptions: SafeHtml[] = [];
  openedJobId: number | null = null;
  tenant_name:any;

  



  constructor(
    private route: ActivatedRoute, private storageService: StorageService,
    private CandidatejobsService: CandidatejobsService,private sanitizer: DomSanitizer) {


  }
  ngOnInit(): void {

    this.candidate_id = this.storageService.getValue('candidate_id')
    this.tenant = this.route.snapshot.paramMap.get('tenant_id');
    console.log(this.tenant)

    if (this.tenant) {
      this.getjobList(null, this.tenant);
    } else
      this.getjobList();
  }


  getjobList(data1: any = null, reset: boolean = false) {

    let data = {
      candidate_id: this.candidate_id,
      tenant_id: this.tenant
    };

    // if (data1) {
    //   // Assign data1 properties to 'data'
    //   data = { ...data, ...data1 };
    // }

    this.CandidatejobsService.getappliedjoblist(this.candidate_id, data).subscribe({
      next: (response: any) => {
        console.log(response)
        this.appliedjoblist = response.jobs_list;
        this.logo_path = response.logo_path;
        this.tenant_name = response.tenant_name;
        // console.log(this.appliedjoblist.data)
        this.updateSafeDescriptions();

        if (this.appliedjoblist && this.appliedjoblist.data.length > 0) {
          this.openJobDetails(this.appliedjoblist.data[0].id);
        }
      },
      error: (error: any) => {
      }
    });


  }

  openJobDetails(jobId: number) {
    this.openedJobId = jobId;
  }

  sanitizeDescription(description: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(description);
  }

  updateSafeDescriptions(): void {
    this.safeDescriptions = this.appliedjoblist.data.map((job: any) => this.sanitizeDescription(job.description));
  }



}
