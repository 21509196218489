import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService, StorageKey } from '../../common/storage.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  [x: string]: any;
  brand_logo = new Subject();
  tenantName = new Subject();
  incommingLogo: any


  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,

  ) { }

  isLoggedIn(): boolean {
    var token = this.storageService.getValue(StorageKey.authToken);
    var currentUser = this.storageService.getValue(StorageKey.currentUser);
    var currentAvtar = this.storageService.getValue(StorageKey.currentAvtar);
    var currentUsername = this.storageService.getValue(StorageKey.currentUsername);

    if (token && currentUser)
      return true;
    else
      return false;
  }


  // store the URL so we can redirect after logging in
  redirectUrl: string | null = null;

  logout() {
    localStorage.clear();

  }

  sign_out() {

    var currentTenant = this.storageService.getValue(StorageKey.currentTenantId);

    if (currentTenant == '0' || currentTenant == 'null')

      return this.httpClient.post(`${environment.apiUrl2}sign-out`, 1);
    else
      return this.httpClient.post(`${environment.apiUrl2}${currentTenant}/sign-out`, 1);
  }

  CheckUserCreditial(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}sign-in`, data);
  }

  getAccessToken(): any {
    var token = this.storageService.getValue(StorageKey.authToken);
    return token ? token : null;
  }



  ForgetPassword(data: any): Observable<any> {
    var currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    if (data.tenant == '0' || !data.tenant)
      return this.httpClient.post(`${environment.apiUrl2}forgot-password`, data);
    else
      return this.httpClient.post(`${environment.apiUrl2}${data.tenant}/forgot-password`, data);
    //  return this.httpClient.post(`${environment.apiUrl}forgot-password`, data);
  }

  ResetPassword(data: any): Observable<any> {
    var currentTenantforresetpw = this.storageService.getValue(StorageKey.currentTenantIdforresetpw);
    if (data.tenant == '0' || !data.tenant)
      return this.httpClient.post(`${environment.apiUrl2}reset-password`, data);
    else
      return this.httpClient.post(`${environment.apiUrl2}${data.tenant}/reset-password`, data);
    // return this.httpClient.post(`${environment.apiUrl}reset-password`, data);
  }

  CheckTenantCreditial(data: any): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl2}get-all-tenants?email=` + data.email);
  }


  twofactorGetOTP(data: any) {

    if (data.tenant == 0) {
      return this.httpClient.post(`${environment.apiUrl2}two-factor`, data, { observe: 'response' });
    }
    else {
      return this.httpClient.post(`${environment.apiUrl2}${data.tenant}/two-factor`, data, { observe: 'response' });
    }

  }


  twofactorResendOTP(data: any) {
    if (data.tenant == 0)
      return this.httpClient.post(`${environment.apiUrl2}resend-two-factor`, data);
    else
      return this.httpClient.post(`${environment.apiUrl2}${data.tenant}/resend-two-factor`, data);
  }

  twofactorSendOTP(data: any) {
    if (data.tenant == 0)
      return this.httpClient.post(`${environment.apiUrl2}authenticate`, data);
    else
      return this.httpClient.post(`${environment.apiUrl2}${data.tenant}/authenticate`, data);
  }


  CheckLoginCreditial(data: any): Observable<any> {

    if (data.tenant == 0)
      return this.httpClient.post(`${environment.apiUrl2}sign-in`, data);
    else
      return this.httpClient.post(`${environment.apiUrl2}${data.tenant}/sign-in`, data);
  }

  setlogo(logo: any) {
    this.brand_logo.next(logo)
  }

  setTenant(tenant: any) {
    this.tenantName.next(tenant);
  }

  // Register Candidate
  createCandidate(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl2}register`, data);
  }




}
