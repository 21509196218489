import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CandidateComponent } from './pages/candidate/candidate.component';
import { CandidateListComponent } from './pages/candidate-list/candidate-list.component';

const routes: Routes = [{
  path:'',
  component: CandidateListComponent
},{
  path:':id',
  component: CandidateComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CandidateRoutingModule { }
