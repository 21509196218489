import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { authGuard } from './guards/auth.guard';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { MainLayoutComponent } from './layout/main-layout/main-layout/main-layout.component';
import { AppHeaderComponent, AppNavComponent, AppSubHeaderComponent,  } from '../shared/components';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminComponent } from '../modules/admin/pages/admin/admin.component';
import { CandidateListComponent } from '../modules/candidate/pages/candidate-list/candidate-list.component';
import { CandidateComponent } from '../modules/candidate/pages/candidate/candidate.component';
import { ClientListComponent } from '../modules/client/pages/client-list/client-list.component';
import { ProjectListComponent } from '../modules/projects/pages/project-list/project-list.component';
import { TagsListComponent } from '../modules/tags/pages/tags-list/tags-list.component';
import { UserListComponent } from '../modules/users/pages/user-list/user-list.component';
import { TenantsListComponent } from '../modules/tenants/pages/tenants-list/tenants-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { ProjectComponent } from '../modules/projects/pages/project/project.component';
import { CandidateStatusListComponent } from '../modules/candidate-status/pages/candidate-status-list/candidate-status-list.component';
import { ClientStatusListComponent } from '../modules/client-status/pages/client-status-list/client-status-list.component';
import { HomeComponent } from '../modules/dashboard/pages/home/home.component';
import { NgChartsModule } from 'ng2-charts';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { BrowserModule } from '@angular/platform-browser';


@NgModule({
  declarations: [
    MainLayoutComponent,
    AppHeaderComponent,
    AppNavComponent,
    HomeComponent,
    CandidateComponent,
    CandidateListComponent,
    CandidateStatusListComponent,
    ClientStatusListComponent,
    ProjectListComponent,
    ProjectComponent,
    AdminComponent,
    UserListComponent,
    ClientListComponent,
    TagsListComponent,
    AppSubHeaderComponent,
    TenantsListComponent

  ],
  imports: [
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    CommonModule,
    FontAwesomeModule,
    ToastrModule.forRoot({
      positionClass :'toast-top-center',
    }),
    NgChartsModule,AngularEditorModule,BrowserModule
  ],
  providers: [
    HttpClientModule,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
   
})
export class CoreModule { }
