import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JobsRoutingModule } from './jobs-routing.module';
import { CandidateProfileComponent } from './candidate-profile/candidate-profile.component';
import { AppJobheaderComponent } from '../../shared/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PublicJoblistComponent } from './public-joblist/public-joblist.component';
import { JoblistComponent } from './joblist/joblist.component';
import { FormatDatePipe } from './format-date.pipe';
import { AppliedjobsComponent } from './appliedjobs/appliedjobs.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    CandidateProfileComponent,
    AppJobheaderComponent,
    PublicJoblistComponent,
    JoblistComponent,
    FormatDatePipe,
    AppliedjobsComponent

  ],
  imports: [
    CommonModule,
    JobsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,NgbTooltipModule,NgbModule,
  ]
})
export class JobsModule { }
