import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { StorageService ,StorageKey} from 'src/app/core/common/storage.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})

export class MainLayoutComponent implements OnInit {
  isOpen:boolean=true;  
  // brand_logo='http://35.154.99.45/tatrack-backend-api/public/images/TATrack.png';
  brand_logo:any='http://35.154.99.45/tatrack-backend-api/public/images/TATrack.png'
  brand_logo1: any;
  constructor(private authservice:AuthService,private storageService:StorageService)
  {

  }
  ngOnInit() {
    this.authservice.brand_logo.subscribe((data:any)=>{
      this.brand_logo = data
    })

    let brandLogoCompany = this.storageService.getValue(StorageKey.brandLogo);
    this.brand_logo1 = brandLogoCompany;

  }
  toggleMenu(){
    this.isOpen = !this.isOpen;
  }
}
