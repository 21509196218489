<div class="header">

  <div class="logo-left">
    <img *ngIf="tenantlist.logo_path" [src]="tenantlist.logo_path" alt="" height="80" >
    <img *ngIf="!tenantlist.logo_path" src="./assets/img/TATlogo.PNG" alt="" height="80">
  
  </div>


  <div class="logo-right">
    <img *ngIf="tenantlist.logo_path && !isLoggedIn()" src="./assets/img/TATlogo.PNG" alt="" height="60" width="100">

    <div *ngIf="isLoggedIn()" class="dropdown d-inline-block mx-3">
    
      <button class="profile-name" type="button" data-bs-toggle="dropdown" aria-expanded="false" style="padding: 0px;">
        <span style="height:35px;width:35px;border-radius: 50px;" *ngIf="imgSrc !== null">
          <img [src]="imgSrc" alt="" style="height:35px;width:35px;border-radius: 50px;margin-bottom: 3px;" />
        </span>
        <span *ngIf="imgSrc === null"
          style="height:35px;width:35px;border-radius: 50px;margin-bottom: 3px;padding-left: 0px;">{{userinitials}}</span>
      </button>
    
      <ul class="dropdown-menu">
        <li><a class="dropdown-item" (click)="gotoprofile()">My Profile</a></li>
        <li><a class="dropdown-item" (click)="logout()">Logout</a></li>
      </ul>
    </div>

    
  </div>

</div>

<div class="section-hr"></div>

<div class="search-group">
  <div class="search-bar">
    <input type="text" class="form-control"  placeholder="Type and press Enter to search" (keyup.enter)="onSearchClick(inputsearch.value)"
      tabindex="-1" #inputsearch aria-label="Search" aria-describedby="basic-addon2">
    <button (click)="onSearchClick(inputsearch.value)">Search</button>
  </div>

  <div class="filter-container">
    <select id="locationSelect" [(ngModel)]="selectedLocation" (change)="filterByLocation()">
      <option [ngValue]="null">Select Location</option>
      <option *ngFor="let location of allLocations" [ngValue]="location">
        <!-- {{ location.city }}, {{ location.state }} -->
        {{ formatLocation(location) }}
      </option>
    </select>

    
  
  </div>



</div>

<div class="container">

  <div class="left-column">

    <ul class="vertical-tab">
      <li *ngFor="let job of publicjoblist?.data" [class.active]="openedJobId === job.id" (click)="openJobDetails(job.id)">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <h3><strong>{{ job.name }}</strong></h3>
          <h6 style="margin: 0;">
            <span *ngIf="job?.applied_at" style="color: green; font-size: 0.75rem;">Applied</span>
          </h6>
        </div>
        <h6 style="margin-bottom: 2px; font-size: small;">{{ formatJobLocation(job) }}</h6>
        <h6 style="color: gray; font-size: small;">{{ job.tenant_name }}</h6>
        <small>Posted on {{ job?.updated_at | formatDate }}</small>
      </li>
    </ul>

    <div class="pagination-main">


      <div>
        <label for="" class="list-totalrecords">Total Records: {{totalRows}}</label>

      </div>
      
     <div>
          <ul *ngIf="pager.pages && pager.pages.length && totalRows > pageSize" class="pagination justify-content-end m-0">
            
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                <a *ngIf="pager.currentPage === 1; else elseFirst" class="page-link page-show"> <<</a>
                <ng-template #elseFirst>
                    <a (click)="setPage(1)" class="page-link"><<</a>
                </ng-template>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                <a *ngIf="pager.currentPage === 1; else elsePrevious" class="page-link page-show"> <</a>
                <ng-template #elsePrevious>
                    <a (click)="setPage(pager.currentPage - 1)" class="page-link"> <</a>
                </ng-template>
            </li>
          
            <li class="page-item ">
              <a class="page-link">{{pager.currentPage}}</a>
          </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a *ngIf="pager.currentPage === pager.totalPages; else elseNext" class="page-link page-show">></a>
                <ng-template #elseNext>
                    <a (click)="setPage(pager.currentPage + 1)" class="page-link">></a>
                </ng-template>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a *ngIf="pager.currentPage === pager.totalPages; else elseLast" class="page-link page-show">>></a>
                <ng-template #elseLast>
                    <a (click)="setPage(pager.totalPages)" class="page-link">>></a>
                </ng-template>
            </li>
        </ul>


        </div>


    </div>
    
  </div>

  <div class="right-column-container">
  
    <div class="right-column">
      <div *ngIf="publicjoblist">
        <div class="job-details" *ngFor="let job of publicjoblist.data" [id]="'job' + job.id"
          [class.active]="openedJobId === job.id">
          <div class="jd-heading">
            <div class="jdHeadLeft">
              <h3><strong>{{ job.name }}</strong></h3>
              <p>{{ formatJobLocation(job) }}</p>
            </div>
  
         
  
            <div class="jdHeadRight" *ngIf="job?.applied_at">
              <a>Applied on {{ job?.applied_at | formatDate }}</a>
            </div>
  
            <div class="jdHeadRight" *ngIf="!job?.applied_at">
              <a (click)="applyjob(job.id)">Apply</a>
            </div>
  
  
          </div>
          <div class="hr"></div>
          <div class="job-desc">
            <div [innerHTML]="safeDescriptions[publicjoblist.data.indexOf(job)]"></div>
          </div>
        </div>
      </div>
    </div>
  
  </div>

</div>




<ng-template #ApplyjobModal let-modal>

  <div class="modal-header">
  
    <h6 class="modal-title" id="modal-basic-title">Apply Job</h6>
  
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');resetapplyerror()">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <form [formGroup]="jobapplyForm">

    <!-- Show Error Msg  -->
    <div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="applyJobError">
        {{applyJobError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="project_idError">
        {{project_idError}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="urlError">{{urlError}}
      </div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="tenant_idError">
        {{tenant_idError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-succes" *ngIf="uploadresumesuccess">
        {{uploadresumesuccess}}</div>
    </div>


    <div class="modal-body">
    
      <div class="row">
    
        <p class="modal-title" id="modal-basic-title">Applying for <strong>'{{filteredJobs[0]?.name}}' </strong> , {{
          filteredJobs[0]?.city }}
          {{ filteredJobs[0]?.state }}
        </p>

        <div *ngIf="!applysuccessMsg && showlinkedininput" class="mb-6 mt-2">
          <label for="">Please provide your LinkedIn URL to populate your profile information</label>
          <input type="text" placeholder="Enter LinkedIn URL" formControlName="linkedinurl" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && validation['linkedinurl'].errors }">
          <div style="color: red; font-size: 14px;"
            *ngIf="submitted && validation['linkedinurl'] && validation['linkedinurl'].errors && validation['linkedinurl'].errors['required']">
            Linkedin URL is required
          </div>
        </div>
    
    
        <form [formGroup]="AddDocumentForm">
    
          <div *ngIf="showuploadButton" class="mb-3 mt-2">
            <label for="resume" >Resume (Required)</label>
            <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
            <input type="file" multiple accept=".pdf,.doc,.docx" placeholder="Degree is required" max-file-size="10000000"
              formControlName="document" class="form-control" (change)="attachFiles($event)"
              [ngClass]="{ 'is-invalid': submitted && documentDetails['document'].errors }">
            <div style="color: red; font-size: 14px;"
              *ngIf="submitted && documentDetails['document'] && documentDetails['document'].errors && documentDetails['document'].errors['required']">
              Document is required.
            </div>
            <div style="font-size: 14px; margin-top: 5px;">Only .docx, .pdf files are allowed</div>
          </div>
    
        </form>
    
        <div *ngIf="applysuccessMsg" class="mb-6 mt-2">
          <div style="color: green; font-size: 14px;">
            {{applysuccessMsg}}
          </div>
        </div>
    
      </div>
    
    </div>


    <div class="modal-footer">
      <ng-container *ngIf="!applysuccessMsg">
        <button type="button" class="filter"
          (click)="modal.close('Save click'); resetapplyerror();resetjobdetails();">Cancel</button>
        <button *ngIf="!showuploadButton" type="button" class="blue-btn ms-" (click)="onapplysubmit()">Apply</button>
        <button *ngIf="showuploadButton" type="button" class="blue-btn ms-" (click)="uploadresume()">Upload</button>
      </ng-container>
      <ng-container *ngIf="applysuccessMsg">
        <button type="button" class="filter" (click)="modal.close('OK click');resetapplyerror();gotoprofile()">Go To
          Profile</button>
        <button type="button" class="blue-btn ms-" (click)="modal.close('OK click');resetapplyerror()">OK</button>
      </ng-container>
    </div>

    </form>

    

  </ng-template>