import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../common/storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService) { }

  create(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}users`, data);
  }

  listAll(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}users?sort_field=name&sort_method=asc`);
  }

  listPagewise(data?: any): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}users?page=` + data.page);
  }
  listSearch(data?: any): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}users?search=` + data.search);
  }

  // listSort(data?: any): Observable<any>{  
  //   return this.httpClient.get(`${environment.apiUrl}tags?sort_field=`+ data.sort_field + `&sort_method= `+ data.sort_method);
  // }

  getUserData(data?: any): Observable<any> {
    // console.log(data,"in servce")
    return this.httpClient.get(`${environment.apiUrl}users/` + data.id);
  }
  updateUserData(id: number, data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}users/` + id, data);
  }

  deleteUserData(id: number): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}users/` + id);
  }

  listByFilter(data?: any): Observable<any> {
    let get_params: string = '';

    if (data.sort_field && data.sort_method) {
      get_params += `sort_field=` + data.sort_field + `&sort_method= ` + data.sort_method;
    }
    if (data.page) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `page=` + data.page;
    }
    if (data.search) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `search=` + data.search;
    }

    return this.httpClient.get(`${environment.apiUrl}users?` + get_params);
  }

  searchUserwiseList(data?: any) {
    let get_params: string = '';
    if (data && data.search) {
      get_params += `search=` + data.search;
    }
    return this.httpClient.get(`${environment.apiUrl}users?` + get_params);
  }

  userProfile(data?: any): Observable<any> {
    if (data == 0)
      return this.httpClient.get(`${environment.apiUrl2}profile`);
    else
      return this.httpClient.get(`${environment.apiUrl2}${data}/profile`);
  }

  // For Candidate 
  candidateuser_profile(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl2}profile`);
  }

  // userProfile(data:any): Observable<any>{
  //    return this.httpClient.get(`${environment.apiUrl}profile`);
  // }

  // updateProfile(data:any): Observable<any>{
  //   return this.httpClient.post(`${environment.apiUrl}profile`, data);
  // }


  updateProfile(data: any, data2: any): Observable<any> {
    if (data2 == 0)
      return this.httpClient.post(`${environment.apiUrl2}profile`, data);
    else
      return this.httpClient.post(`${environment.apiUrl2}${data2}/profile`, data);
  }

  candidateupdateProfile(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl2}profile`, data);
  }

  sendOTPforVerify(userID: any, data: any) {
    return this.httpClient.post(`${environment.apiUrl2}users/${userID}/email-verification`, data);
  }

  VerifyOTP(userID: any, data: any) {
    return this.httpClient.post(`${environment.apiUrl2}users/${userID}/email-verified`, data);
  }


  sendOTPforRegister(data: any) {
    return this.httpClient.post(`${environment.apiUrl2}send-email-verifcation`, data);
  }

  VerifyRegisterOTP(data: any) {
    return this.httpClient.post(`${environment.apiUrl2}email-verified`, data);
  }






}
