import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '../../common/storage.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftEmailService {

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService) { }

    authorize(data:any): Observable<any>{
        return this.httpClient.get(`${environment.apiUrl}microsoft/authorize?page=`+data.page);
    }  

    getMessages(data:any): Observable<any>{
      return this.httpClient.get(`${environment.apiUrl}microsoft/messages?candidate_id=`+data.candidate_id+`&folder_name=`+data.folder_name);
    }

    getMessageDetails(data:any): Observable<any>{
      return this.httpClient.get(`${environment.apiUrl}microsoft/messages/${data.id}`);
    }

    unauthorizeMs(data:any): Observable<any>{
      return this.httpClient.post(`${environment.apiUrl}microsoft/unauthorize/${data.user_id}`, data);
    }
}
