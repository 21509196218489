import { Component } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent {
  pageTitle: string="Admin";
  isSearchable: boolean= false;
  showCreateCandidate:boolean = false;
  showProjectCandidate:boolean = false;
  showCreateButton:string="";
  searchData(){

  }
}
