import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  getValue(key: string): string | null {
    return localStorage.getItem(key);

  }

  setValue(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  removeValue(key: string): void {
    localStorage.removeItem(key);
  }

  static getTenant(): string | null {
    return localStorage.getItem("currentTenantId");
  }
}

export class StorageKey {

  // public static currentLanguage = 'currentLanguage';
  public static currentUser = 'currentUser';
  public static authToken = 'authToken';
  public static currentAvtar = 'currentAvtar';
  public static currentUsername = 'currentUsername';
  public static currentUserid = 'currentUserid';
  public static currentUserRoleId = 'currentUserRoleId';
  public static listSettings = 'listSettings';
  public static currentTenantId = 'currentTenantId';
  public static currentTenantnameId = 'currentTenantnameId';
  public static currentTenantIdforresetpw = 'currentTenantIdforresetpw';
  public static brandLogo = 'brandLogo';
  public static is_client_module_access_disabled = 'is_client_module_access_disabled';
  static currentUserRole: string;
  public static candidate_id = 'candidate_id';
  public static is_public_search = 'is_public_search';
  

}
