import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CandidateStatusRoutingModule } from './candidate-status-routing.module';
import { CandidateStatusListComponent } from './pages/candidate-status-list/candidate-status-list.component';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CandidateStatusRoutingModule
  ]
})
export class CandidateStatusModule { }
