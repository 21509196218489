import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { UserService } from 'src/app/core/services/users/user.service';
import { Subscription, interval } from 'rxjs';
import { CandidatejobsService } from 'src/app/core/services/candidatejobs/candidatejobs.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  showAdminlogo: boolean = false;
  applogo: string = "";
  url: any;
  url2 = 'assets/img/TATlogo.PNG';
  tenantlogo: boolean = false;
  logo = 'assets/img/TATlogo.PNG'
  showLogin: boolean = true;
  registerForm!: FormGroup;
  showforgot = true
  submitted: boolean = false;
  loginData: any;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  signupPasswordError: any;
  signupEmailError: any;
  step: string = "1";
  optSendsuccesmsg: any;
  otpError: any;
  registersuccess: any;
  emailError: any;
  passwordError: any;
  showPassword: boolean = false;
  showResend = false;
  sub!: Subscription;
  otpcount: any;
  tenantId: any;
  tenantdetails: any

  job_id: any;
  job_name: any
  job_city_name: any
  job_state_name: any;


  get f() { return this.registerForm.controls; }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authservice: AuthService,
    private notificationService: NotificationService,
    private userservice: UserService,
    private CandidatejobsService: CandidatejobsService,
    private route: ActivatedRoute
  ) {

    this.registerForm = this.formBuilder.group({
      first_name: [null, [Validators.required]],
      last_name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern(this.emailPattern)]],
      password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      is_consent_receiving_emails: [null],
      otp: [null],
    });
  }



  ngOnInit() {

    this.route.queryParams.subscribe(params => {

      this.job_id = params['job_id'];
      this.job_name = params['job_name'];
      this.job_city_name = params['job_city_name'];
      this.job_state_name = params['job_state_name'];
      this.tenantId = params['tenantId'];
      if (this.tenantId == 'jobs') {
        console.log(this.tenantId)
      } else {
        this.GetTenantDetails();
      }
    });

  }



  gobacktologin() {
    this.router.navigate(['']);
  }



  // signup() {

  //   this.submitted = true;
  //   if (this.registerForm.invalid) {
  //     return;
  //   }

  //   this.signupPasswordError = '';
  //   this.signupEmailError = '';

  //   const is_consent = this.f['is_consent_receiving_emails'].value ? 1 : 0;

  //   this.loginData = {
  //     email: this.f['email'].value,
  //     password: this.f['password'].value,
  //     first_name: this.f['first_name'].value,
  //     last_name: this.f['last_name'].value,
  //     is_consent_receiving_emails: is_consent,
  //     otp: this.f['otp'].value,
  //   };

  //   // this.authservice.createCandidate(this.loginData).subscribe({
  //   //   next: (response: any) => {
  //   //     this.notificationService.showSuccess(response.message);
  //   //   },
  //   //   error: (error: any) => {
  //   //     // this.signupPasswordError = error.error.password;
  //   //     // this.signupEmailError = error.error.email
  //   //     if (error.error.password) {
  //   //       this.signupPasswordError = error.error.password;
  //   //     }

  //   //     if (error.error.email) {
  //   //       this.signupEmailError = error.error.email;
  //   //     }

  //   //   }
  //   // })

  // }



  verifyemail() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.emailError = ''
    this.passwordError = ''

    this.submitted = true;
    // const is_consent = this.f['is_consent_receiving_emails'].value ? 1 : 0;

    // this.loginData = {
    //   email: this.f['email'].value,
    //   password: this.f['password'].value,
    //   first_name: this.f['first_name'].value,
    //   last_name: this.f['last_name'].value,
    //   is_consent_receiving_emails: 1,
    //   is_register: 1,
    //   otp: this.f['otp'].value,
    // };

    // this.userservice.sendOTPforRegister(this.loginData).subscribe({
    //   next: (response: any) => {
    //     this.optSendsuccesmsg = response.message;
    //     this.step = '2';
    //     this.sixtySecondInterval();
    //   },
    //   error: (error) => {
    //     console.log(error)
    //     switch (error.status) {
    //       case 422:
    //         if (error.error.email) {
    //           this.emailError = error.error.email[0];
    //         }
    //         if (error.error.password) {
    //           this.passwordError = error.error.password[0];
    //         }
    //         break;
    //       default:
    //     }
    //     // this.notificationService.showError(error.message);
    //   }
    // })

    this.loginData = {
      email: this.f['email'].value,
      password: this.f['password'].value,
      first_name: this.f['first_name'].value,
      last_name: this.f['last_name'].value,
      is_consent_receiving_emails: 1,
      is_register: 1,
    };

    this.authservice.createCandidate(this.loginData).subscribe({
      next: (response: any) => {
        // this.step = '3';
        this.registersuccess = "Your account has been successfully registered."
        this.sendEmailLink()
      },
      error: (error: any) => {
        // console.log(error)
        switch (error.status) {
          case 422:
            if (error.error.email) {
              this.emailError = error.error.email[0];
            }
            if (error.error.password) {
              this.passwordError = error.error.password[0];
            }
            break;
          default:
        }
        // this.notificationService.showError(error.message);
      }
    })

  }


  sendEmailLink() {

    this.loginData = {
      email: this.f['email'].value,
      password: this.f['password'].value,
      first_name: this.f['first_name'].value,
      last_name: this.f['last_name'].value,
      is_consent_receiving_emails: 1,
      is_register: 1,
      otp: this.f['otp'].value,
    };

    this.userservice.sendOTPforRegister(this.loginData).subscribe({
      next: (response: any) => {
        this.optSendsuccesmsg = response.message;
        this.step = '2';
        this.sixtySecondInterval();
      },
      error: (error) => {
        console.log(error)
        switch (error.status) {
          case 422:
            if (error.error.email) {
              this.emailError = error.error.email[0];
            }
            if (error.error.password) {
              this.passwordError = error.error.password[0];
            }
            break;
          default:
        }
        // this.notificationService.showError(error.message);
      }
    })

  }


  submitotp() {

    let details = {
      email: this.f['email'].value,
      is_register: 1,
      otp: this.f['otp'].value,
    };

    this.userservice.VerifyRegisterOTP(details).subscribe({
      next: (response: any) => {
        this.verifyemail = response.message;
        this.step = '3';
        this.RegisterCandidate()
      },
      error: (error) => {
        switch (error.status) {
          case 422:
            if (error.error.otp) {
              this.otpError = error.error.otp[0];
            }
            break;
          default:
        }
      }
    })



  }

  RegisterCandidate() {
    // const is_consent = this.f['is_consent_receiving_emails'].value ? 1 : 0;

    this.loginData = {
      email: this.f['email'].value,
      password: this.f['password'].value,
      first_name: this.f['first_name'].value,
      last_name: this.f['last_name'].value,
      is_consent_receiving_emails: 1,
      is_register: 1,

      // otp: this.f['otp'].value,
    };

    this.authservice.createCandidate(this.loginData).subscribe({
      next: (response: any) => {
        this.registersuccess = "Your email address has been successfully verified! Please log in to access your account."
      },
      error: (error: any) => {
        console.log(error)
      }
    })

  }

  successregister() {
    this.router.navigate(['/jobs']);
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }



  resendOtp() {

    this.loginData = {
      email: this.f['email'].value,
      password: this.f['password'].value,
      first_name: this.f['first_name'].value,
      last_name: this.f['last_name'].value,
      is_consent_receiving_emails: 1,
      is_register: 1,
    };

    this.userservice.sendOTPforRegister(this.loginData).subscribe({
      next: (response: any) => {
        this.sixtySecondInterval();
        this.notificationService.showSuccess(response.message);
      },
      error: (error: any) => {
      }
    })
  }


  sixtySecondInterval() {
    this.showResend = false;
    var emittedNo = interval(1000); // Interval emits every 1000 milliseconds (1 second)
    console.log(emittedNo, "emittedno")
    this.sub = emittedNo.subscribe((res) => {
      this.otpcount = 120 - res; // Change 60 to 120
      if (this.otpcount == 0) {
        this.sub.unsubscribe();
        this.showResend = true;
      }
    });
  }


  GetTenantDetails() {
    this.CandidatejobsService.GetTenantdetails(this.tenantId).subscribe({
      next: (response: any) => {
        this.tenantdetails = response.data
        this.url = this.tenantdetails.logo_path;
        this.tenantlogo = true;
      },
      error: (error: any) => {

      }
    })
  }


  navigateToLogin(): void {
    const queryParams = {
      job_id: this.job_id,
      // job_name: this.job_name,
      // job_city_name: this.job_city_name,
      // job_state_name: this.job_state_name,
      tenantId: this.tenantId
    };

    this.router.navigate(['/jobs'], { queryParams: queryParams });
  }





}
