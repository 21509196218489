import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService, StorageKey } from '../../common/storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CandidatejobsService {

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService) { }


  candidateDetail(id: number) {
    return this.httpClient.get(`${environment.apiUrl2}candidates/` + id);
  }

  getstate() {
    return this.httpClient.get(`${environment.apiUrl2}states`);
  }

  updateCandidateData(id: number, data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl2}candidates/` + id, data);
  }

  createEducation(id: number, data: any) {
    return this.httpClient.post(`${environment.apiUrl2}candidates/` + id + "/qualifications", data);
  }

  deleteEducation(id: number, educationId: any): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl2}candidates/` + id + "/qualifications/" + educationId);
  }

  updateEducation(id: number, certificateId: any, data: any) {
    return this.httpClient.put(`${environment.apiUrl2}candidates/` + id + "/qualifications/" + certificateId, data);
  }

  createCertificate(id: number, data: any) {
    return this.httpClient.post(`${environment.apiUrl2}candidates/` + id + "/certifications", data);
  }

  deleteCertificate(id: number, certificateId: any): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl2}candidates/` + id + "/certifications/" + certificateId);
  }

  updateCertificate(id: number, certificateId: any, data: any) {
    return this.httpClient.put(`${environment.apiUrl2}candidates/` + id + "/certifications/" + certificateId, data);
  }

  createDocument(id: number, data: any) {
    return this.httpClient.post(`${environment.apiUrl2}candidates/` + id + "/documents", data);
  }

  deleteDocument(id: number, documentId: any) {
    return this.httpClient.delete(`${environment.apiUrl2}candidates/` + id + "/documents/" + documentId);
  }

  updateDocument(id: number, documentId: any, data: any) {
    return this.httpClient.post(`${environment.apiUrl2}candidates/` + id + "/documents/" + documentId, data);
  }

  getpublic_joblist(tenant: string) {
    return this.httpClient.get(`${environment.apiUrl2}${tenant}/projects/public`);
  }

  // getjobList(tenant: string, data?: any): Observable<any> {

  //   let get_params: string = '';

  //   // if (data.tenant) {
  //   //   get_params += `tenant=` + data.tenant + `&tenant= ` + data.tenant;
  //   // }

  //   if (data.per_page) {
  //     if (get_params != '') {
  //       get_params += `&`
  //     }
  //     get_params += `per_page=` + data.per_page;
  //   }


  //   if (data.search) {
  //     if (get_params != '') {
  //       get_params += `&`
  //     }
  //     get_params += `search=` + data.search;
  //   }

  //   if (data.location) {
  //     if (get_params != '') {
  //       get_params += `&`
  //     }
  //     get_params += `location=` + data.location;
  //   }


  //   if (data.page) {
  //     if (get_params != '') {
  //       get_params += `&`
  //     }
  //     get_params += `page=` + data.page;
  //   }

  //   if (data.candidate_id) {
  //     if (get_params != '') {
  //       get_params += `&`
  //     }
  //     get_params += `candidate_id=` + data.candidate_id;
  //   }



  //   if (tenant == 'jobs') {
  //     return this.httpClient.get(`${environment.apiUrl2}projects/public?` + get_params);
  //   } else {
  //     // return this.httpClient.get(`${environment.apiUrl2}twg/projects/public?` + get_params`);
  //     return this.httpClient.get(`${environment.apiUrl2}${tenant}/projects/public?` + get_params);
  //   }


  // }

  // getjobList(tenant: string, data?: any): Observable<any> {
  //   let get_params: string = '';

  //   if (data.per_page) {
  //     get_params += `per_page=${data.per_page}&`;
  //   }

  //   if (data.search) {
  //     get_params += `search=${data.search}&`;
  //   }

  //   if (data.location && data.location.city && data.location.state) {
  //     get_params += `city=${data.location.city}&state=${data.location.state}&`;
  //   }

  //   if (data.page) {
  //     get_params += `page=${data.page}&`;
  //   }

  //   if (data.candidate_id) {
  //     get_params += `candidate_id=${data.candidate_id}&`;
  //   }

  //   // Remove the trailing '&' if it exists
  //   if (get_params.endsWith('&')) {
  //     get_params = get_params.slice(0, -1);
  //   }

  //   const url = tenant === 'jobs' 
  //     ? `${environment.apiUrl2}projects/public?${get_params}` 
  //     : `${environment.apiUrl2}${tenant}/projects/public?${get_params}`;

  //   return this.httpClient.get(url);
  // }

  getjobList(tenant: string, data?: any): Observable<any> {
    let get_params: string = '';

    if (data.per_page) {
      get_params += `per_page=${data.per_page}&`;
    }

    if (data.search) {
      get_params += `search=${data.search}&`;
    }

    if (data.location && typeof data.location === 'object') {
      if (data.location.city !== null && data.location.city !== undefined) {
        get_params += `city=${encodeURIComponent(data.location.city)}&`;
      }
      if (data.location.state !== null && data.location.state !== undefined) {
        get_params += `state=${encodeURIComponent(data.location.state)}&`;
      }
    }

    if (data.page) {
      get_params += `page=${data.page}&`;
    }

    if (data.candidate_id !== undefined && data.candidate_id !== null) {
      get_params += `candidate_id=${data.candidate_id}&`;
    }

    // Remove the trailing '&' if it exists
    if (get_params.endsWith('&')) {
      get_params = get_params.slice(0, -1);
    }

    const url = tenant === 'jobs'
      ? `${environment.apiUrl2}projects/public?${get_params}`
      : `${environment.apiUrl2}${tenant}/projects/public?${get_params}`;

    return this.httpClient.get(url);
  }

  // getalltenentjobList(data?: any): Observable<any> {

  //   let get_params: string = '';

  //   // if (data.tenant) {
  //   //   get_params += `tenant=` + data.tenant + `&tenant= ` + data.tenant;
  //   // }

  //   if (data.per_page) {
  //     if (get_params != '') {
  //       get_params += `&`
  //     }
  //     get_params += `per_page=` + data.per_page;
  //   }


  //   if (data.search) {
  //     if (get_params != '') {
  //       get_params += `&`
  //     }
  //     get_params += `search=` + data.search;
  //   }

  //   if (data.location) {
  //     if (get_params != '') {
  //       get_params += `&`
  //     }
  //     get_params += `location=` + data.location;
  //   }


  //   if (data.page) {
  //     if (get_params != '') {
  //       get_params += `&`
  //     }
  //     get_params += `page=` + data.page;
  //   }

  //   if (data.candidate_id) {
  //     if (get_params != '') {
  //       get_params += `&`
  //     }
  //     get_params += `candidate_id=` + data.candidate_id;
  //   }

  //   // return this.httpClient.get(`${environment.apiUrl2}twg/projects/public?` + get_params`);
  //   return this.httpClient.get(`${environment.apiUrl2}projects/public?` + get_params);


  // }

  getalltenentjobList(data?: any): Observable<any> {
    let get_params: string = '';

    if (data && data.per_page) {
      get_params += `per_page=${data.per_page}&`;
    }

    if (data && data.search) {
      get_params += `search=${encodeURIComponent(data.search)}&`;
    }

    if (data.location && data.location.city !== null && data.location.city !== undefined) {
      get_params += `city=${data.location.city}&`;
    }

    if (data.location && data.location.state !== null && data.location.state !== undefined) {
      get_params += `state=${data.location.state}&`;
    }

    if (data && data.page) {
      get_params += `page=${data.page}&`;
    }

    if (data && data.candidate_id) {
      get_params += `candidate_id=${data.candidate_id}&`;
    }

    // Remove the trailing '&' if it exists
    if (get_params.endsWith('&')) {
      get_params = get_params.slice(0, -1);
    }

    return this.httpClient.get(`${environment.apiUrl2}projects/public?${get_params}`);
  }



  // applyjob(id: number, documentId: any, data: any) {
  //   return this.httpClient.post(`${environment.apiUrl2}candidates/` + id + "/documents/" + documentId);
  // }


  // api/candidates/{candidate_id}/jobs/{project_id}/apply
  applyjob(candidate_id: number, project_id: any, data: any) {
    return this.httpClient.post(`${environment.apiUrl2}candidates/${candidate_id}/jobs/${project_id}/apply`, data);
  }


  getapplied_job(candidateId: number) {
    return this.httpClient.get(`${environment.apiUrl2}candidates/${candidateId}/jobs/list`);
  }

  // public page
  getlocationlist(tenant: string) {
    if (tenant == 'jobs') {
      return this.httpClient.get(`${environment.apiUrl2}projects/public-locations`);
    } else {
      return this.httpClient.get(`${environment.apiUrl2}${tenant}/projects/public-locations`);
    }
  }

  // joblist page 
  getlocations() {
    return this.httpClient.get(`${environment.apiUrl2}projects/public-locations`);
  }


  updateURL(candidateId: any, data: any) {
    return this.httpClient.post(`${environment.apiUrl2}candidates/${candidateId}/update-linkedin-url`, data);
  }

  // Candidate tenant applied jobs list

  getappliedjoblist(candidateId: number, data: any) {


    let get_params: string = '';

    if (data.tenant_id) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `tenant_id=` + data.tenant_id;
    }

    // return this.httpClient.get(`${environment.apiUrl2}candidates/${candidateId}/jobs/accounts-jobs-list/` + get_params);
    return this.httpClient.get(`${environment.apiUrl2}candidates/${candidateId}/jobs/accounts-jobs-list?` + get_params);
  }



  GetTenantdetails(tenantId: any): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl2}get-tenant-logo/${tenantId}`);
  }







}
