import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { StorageKey, StorageService } from 'src/app/core/common/storage.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { CandidateService } from 'src/app/core/services/candidates/candidate.service';
import { ClientService } from 'src/app/core/services/clients/client.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { ProjectService } from 'src/app/core/services/projects/project.service';
import { TagserviceService } from 'src/app/core/services/tags/tagservice.service';
import { UserService } from 'src/app/core/services/users/user.service';
import { ProjectComponent } from 'src/app/modules/projects/pages/project/project.component';
import { TenantserviceService } from 'src/app/core/services/tenants/tenantservice.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { AngularEditorConfig } from '@kolkov/angular-editor';

// import {MomentDateAdapter} from '@angular/material-moment-adapter';
// import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
// import * as _moment from 'moment';
// // tslint:disable-next-line:no-duplicate-imports
// import {default as _rollupMoment} from 'moment';

// const moment = _rollupMoment || _moment;

// // See the Moment.js docs for the meaning of these formats:
// // https://momentjs.com/docs/#/displaying/format/
// export const MY_FORMATS = {
//   parse: {
//     dateInput: 'LL',
//   },
//   display: {
//     dateInput: 'MM-DD-YYYY',
//     monthYearLabel: 'YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'YYYY',
//   },
// };

@Component({
  selector: 'app-app-sub-header',
  templateUrl: './app-sub-header.component.html',
  styleUrls: ['./app-sub-header.component.css']
  // providers: [
  //   // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
  //   // application's root module. We provide it at the component level here, due to limitations of
  //   // our example generation script.
  //   {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

  //   {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  // ],
})

export class AppSubHeaderComponent implements OnInit {

  @ViewChild('inputsearch') inputsearch: any;

  clientsList = [];
  CreateCandidateForm !: FormGroup;
  CandidateBulkImportForm !: FormGroup
  CreateCandidateManuallyForm !: FormGroup;
  CreateProjectForm !: FormGroup;
  CreateClientForm !: FormGroup;
  CreateTagForm!: FormGroup;
  CreateCandidateStatusForm!: FormGroup;
  CreateClientStatusForm!: FormGroup;
  CreateUserForm!: FormGroup;
  CreateTenantForm!: FormGroup;
  submitted: boolean = false;
  projectapicall: boolean = false;
  csapicall: boolean = false;
  clsapicall: boolean = false;
  createCandidateData: any;
  createCandidateManuallyData: any;
  createProjectData: any;
  createClientData: any;
  createTagData: any;
  createCandidateStatusData: any;
  createClientStatusData: any;
  createUserData: any;
  projectList: any = [];
  clientList: any = [];
  tagList: any = [];
  userList: any = [];
  projectTypeList: any = [];
  CityList: any = [];
  page: number = 1;
  createCandidateError: string = "";
  candidateBulkImportError: string = "";
  createCandidateErrorUrl: string = "";
  createProjectError: string = "";
  createClientError: string = "";
  createTagError: string = "";
  createTagErrorName: string = "";
  createCSError: string = "";
  createCSErrorName: string = "";
  createCSErrorDescription: string = "";
  createClSError: string = "";
  createClSErrorName: string = "";
  createClSErrorDescription: string = "";
  createUserError: string = "";
  createProjectTypeError: string = "";
  createProjectClientError: string = "";
  projectListError: string = "";
  selectedOption: any = 2;
  linkedin_candidate: boolean = true;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  keypattern = /^[a-zA-Z]*$/;
  keypattern2 = /^[A-Za-z0-9-_]+$/;
  passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
  rectangleBox: boolean = true;
  logoBox: boolean = false;
  url: any;
  openLogoSection: boolean = false;
  openLogoButtton: boolean = true;
  files2: any;
  fileName2: any;
  filePath2: any;
  @Input() pageTitle: string = "";
  @Input() isSearchable: boolean = false;
  @Input() showCreateButton: string = "";
  @Input() EditId!: number;

  @Output() searchClicked = new EventEmitter<string>();
  @Output() onShowFilter = new EventEmitter<string>();
  @Output() onRefreshClicked = new EventEmitter<string>();

  closeResult: string = "";
  selectedprojectValue: any
  projectError: boolean = false;
  showProjectDropdown = false;
  candidateList: any;
  createClientErrorName: any;
  createClientErrorIndustry: any;
  createClientErrorCity: any;
  createTagErrorDescription: any;
  createUserErrorName: any;
  createUserErrorEmail: any;
  createUserErrorRoleid: any;
  createUserErrorPassword: any;
  createCandidateErrorProjectId: any;
  createProjectErrorName: any;
  createProjectErrorDescription: any;
  createProjectErrorClientname: any;
  createProjectErrorOfficialstartdate: any;
  createProjectErrorType: any;
  createProjectErrorCity: any;
  showPassword: boolean = false;
  getSearchList: any;
  createClientErrorPrimarycontact: any;
  createCandidateManuallyConfirmation: boolean = false;
  createCandidateManuallyError: any;
  files: any;
  fileName!: string;
  filePath!: string;
  candidate_exists: any;
  import_candidate_exists: any;
  notImportedCandidates: any;
  linkedin_submitted: boolean = false;
  manually_submitted: any = -1;
  candidateBulkImportSuccess: any;
  showtenantform = true;
  tenantSuccessMessage = ""
  showFormView: boolean = true;
  bulkImportErrorofimpotedCandidate: any;
  bulkImpotedCandidateError: any;
  errorArray: any;
  errorofimport = "";
  createTenantData: any;
  tenantList: any;
  createTenantError: any;
  createTenantErrorId: any;
  createTenantErrorName: any;
  createTenantErroruserName: any;
  createTenantErroruserEmail: any;
  createTenantErroruserPassword: any;
  currentTenantId = this.storageService.getValue(StorageKey.currentTenantId);
  is_client_module_access_disabled: string | null = "0";
  is_public_search: string | null = "0";
  AddJobPostingForm: FormGroup;
  statusofprojectList = [];
  stateList = [];
  createProjectErroris_public: any;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['insertImage', 'subscript', 'superscript', 'clearFormatting', 'heading', 'insertVideo', 'customClasses', 'toggleEditorMode']
    ],
  };

  get createCandidateForm() { return this.CreateCandidateForm.controls; }
  get candidateBulkImportForm() { return this.CandidateBulkImportForm.controls; }
  get createCandidateManuallyForm() { return this.CreateCandidateManuallyForm.controls; }
  get createProjectForm() { return this.CreateProjectForm.controls; }
  get createClientForm() { return this.CreateClientForm.controls; }
  get createTagForm() { return this.CreateTagForm.controls; }
  get createCandidateStatusForm() { return this.CreateCandidateStatusForm.controls; }
  get createClientStatusForm() { return this.CreateClientStatusForm.controls; }
  get createUserForm() { return this.CreateUserForm.controls; }
  get createTenantForm() { return this.CreateTenantForm.controls; }

  get addJobForm() { return this.AddJobPostingForm.controls; }
  currentUser: any;

  private routeSubscription: Subscription = new Subscription();
  typeQueryParam: any;

  constructor(private notificationService: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authservice: AuthService,
    private storageService: StorageService,
    private candidateService: CandidateService,
    private clientService: ClientService,
    private tagService: TagserviceService,
    private userService: UserService,
    private projectService: ProjectService,
    private tenantService: TenantserviceService,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {

    this.AddJobPostingForm = this.formBuilder.group({
      projectName: [null, [Validators.required]],
      description: [null],
      project_status_id: [null],
      city: [null],
      state: [null],
      client: [null],
    });

    this.pageTitle = localStorage.getItem('page_name') || 'Searches';

  }

  ngOnInit(): void {

    this.onLoad();
    this.is_client_module_access_disabled = this.storageService.getValue(StorageKey.is_client_module_access_disabled);
    this.is_public_search = this.storageService.getValue(StorageKey.is_public_search);


    this.routeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {

      let typeQueryParam = this.router.routerState.snapshot.root.queryParams['type'] || '';

      if (typeQueryParam === 'search') {
        this.pageTitle = "Searches";
        localStorage.setItem('page_name', "Searches");
      } else if (typeQueryParam === 'jobposting') {
        this.pageTitle = "Job Posting";
        localStorage.setItem('page_name', "Job Posting");
      }

      // if (typeQueryParam === 'search') {
      //   this.is_public = 0;
      //   this.pageTitle = "Searches";
      //   localStorage.setItem('page_name', "Searches");
      // } else if (typeQueryParam === 'jobposting') {
      //   this.is_public = 1;
      //   this.pageTitle = "Job Posting";
      //   localStorage.setItem('page_name', "Job Posting");
      // }


    })

    let loginUserDetail: any = this.storageService.getValue(StorageKey.currentUser)
    this.currentUser = JSON.parse(loginUserDetail)

    if (this.currentUser.role.name != "Super Admin") {
      this.getStatusofprojectList();
      this.getStateList();
    }

  }

  ngAfterViewInit() {
    let listSettings: any = this.storageService.getValue(StorageKey.listSettings);
    if (listSettings !== "undefined" && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.searches && this.getSearchList.searches.search && this.showCreateButton == 'project') {
      this.inputsearch.nativeElement.value = this.getSearchList.searches.search;
    }
    if (this.getSearchList && this.getSearchList.candidates && this.getSearchList.candidates.search && this.showCreateButton == 'candidate') {
      this.inputsearch.nativeElement.value = this.getSearchList.candidates.search;
    }
    if (this.getSearchList && this.getSearchList.clients && this.getSearchList.clients.search && this.showCreateButton == 'client') {
      this.inputsearch.nativeElement.value = this.getSearchList.clients.search;
    }
    if (this.getSearchList && this.getSearchList.tags && this.getSearchList.tags.search && this.showCreateButton == 'tag') {
      this.inputsearch.nativeElement.value = this.getSearchList.tags.search;
    }
    if (this.getSearchList && this.getSearchList.users && this.getSearchList.users.search && this.showCreateButton == 'user') {
      this.inputsearch.nativeElement.value = this.getSearchList.users.search;
    }
    if (this.getSearchList && this.getSearchList.tenants && this.getSearchList.tenants.search && this.showCreateButton == 'tenant') {
      this.inputsearch.nativeElement.value = this.getSearchList.tenants.search;
    }
    if (this.getSearchList && this.getSearchList.search_candidates && this.getSearchList.search_candidates.search) {
      this.inputsearch.nativeElement.value = this.getSearchList.search_candidates.search;
    }

  }

  onLoad() {
    this.onBuildForm();
  }
  onBuildForm() {
    this.CreateCandidateForm = this.formBuilder.group({
      linkedInUrl: [null, [Validators.required]],
      projectId: [null]
    });

    this.CandidateBulkImportForm = this.formBuilder.group({
      candidateFile: [null, Validators.required],
      searches: [null],
      tags: [null],
    })

    this.CreateCandidateManuallyForm = this.formBuilder.group({
      firstname: [null, [Validators.required]],
      lastname: [null, [Validators.required]],
      email: [null, [Validators.pattern(this.emailPattern)]],
      projectId: [null]
    });

    this.CreateClientForm = this.formBuilder.group({
      ClientId: [null],
      name: [null, [Validators.required]],
      user_id: [null, [Validators.required]],
      industry: [null],
      city: [null],
      state: [null],
      email: [null],
      contactNumber: [null],
      postalCode: [null],
      StreetAddress: [null],
      description: [null],
      website: [null],
      primary_contact: [null],
    })

    // flightSchedule = {
    //   startDate: new Date()
    // }

    // startDate = new FormControl(moment());
    //id : [null,Validators.pattern(this.keypattern2)]],
    this.CreateProjectForm = this.formBuilder.group({
      projectName: [null, [Validators.required]],
      description: [null],
      startDate: [null, [Validators.required]],
      endDate: [null],
      client: [null],
      user: [null],
      projectType: [null],
      city: [null],

    });

    this.CreateTagForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      // description: [null],
      description: [null, [Validators.minLength(0), Validators.maxLength(15)]],


    });

    this.CreateCandidateStatusForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      description: [null]
    });

    this.CreateClientStatusForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      description: [null]
    });

    this.CreateUserForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern(this.emailPattern)]],
      password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      role_id: [(this.currentTenantId != '0') ? null : 1, [Validators.required]]
    });

    this.CreateTenantForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      id: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(8)]],
      description: [null],
      disable_access_to_client_module: [null],
      user_name: [null, [Validators.required]],
      user_email: [null, [Validators.required, Validators.pattern(this.emailPattern)]],
      user_password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      logo: [null],
      is_public_search: [null],

    });

    this.getTagList();

  }

  attachFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;
  }

  resetBackendProjecterror() {
    this.createProjectErrorName = "";
    this.createProjectErrorDescription = "";
    this.createProjectErrorClientname = "";
    this.createProjectErrorOfficialstartdate = "";
    this.createProjectErrorType = "";
    this.createProjectErrorCity = "";
    this.createProjectError = "";
    this.CreateProjectForm.reset();
  }

  createProject() {

    this.submitted = true;
    if (this.CreateProjectForm.invalid) {
      return;
    }
    let userids = [];
    if (this.createProjectForm['user'].value)
      userids = this.createProjectForm['user'].value;

    let userRole = this.storageService.getValue(StorageKey.currentUserRoleId);
    let currentUserId = this.storageService.getValue(StorageKey.currentUserid);
    if ((userRole == '2' && userids.length > 0 && !userids.includes(currentUserId)) || (userRole == '2' && userids.length == 0))
      userids.push(currentUserId);


    this.createProjectData = {
      name: this.createProjectForm['projectName'].value,
      description: this.createProjectForm['description'].value,
      client_id: this.createProjectForm['client'].value,
      user_ids: userids,
      project_type_id: this.createProjectForm['projectType'].value,
      // project_status_id: 1,
      city: this.createProjectForm['city'].value,
      is_public: 0
    }

    if (this.createProjectForm['startDate'].value) {
      this.createProjectData.official_start_date = this.createProjectForm['startDate'].value;
    }

    if (this.createProjectForm['endDate'].value) {
      this.createProjectData.official_end_date = this.createProjectForm['endDate'].value;
    }
    this.projectapicall = true;
    this.projectService.create(this.createProjectData).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.CreateProjectForm.reset();
        this.notificationService.showSuccess(response.message);
        window.location.reload();
        this.projectapicall = false;
        this.submitted = false;
        this.getProjectList();
      },
      error: (error) => {
        this.createProjectError = error.error.message;
        this.projectapicall = false;
        switch (error.status) {
          case 422:
            if (error.error.name) {
              this.createProjectErrorName = error.error.name[0];
            }
            if (error.error.description) {
              this.createProjectErrorDescription = error.error.description[0];
            }
            if (error.error.client_id) {
              this.createProjectErrorClientname = error.error.client_id[0];
            }
            if (error.error.official_start_date) {
              this.createProjectErrorOfficialstartdate = error.error.official_start_date[0];
            }
            if (error.error.type) {
              this.createProjectErrorType = error.error.type[0];
            }
            if (error.error.city) {
              this.createProjectErrorCity = error.error.city[0];
            }
            break;
          default:

          //Nothing

        }
        // this.notificationService.showError(error.message);
      }
    });

  }


  resetImportCandidateForm() {
    this.candidateBulkImportError = "";
    this.CandidateBulkImportForm.reset();
    this.notImportedCandidates = "";
    this.candidateBulkImportSuccess = "";


  }
  resetBackendCandidateerror() {
    this.createCandidateErrorUrl = "";
    this.createCandidateErrorProjectId = "";
    this.createCandidateError = "";
    this.CreateCandidateForm.reset();
    // this.linkedin_candidate=true

  }

  resetBackendCandidateManuallyerror() {
    this.createCandidateManuallyError = "";
    this.CreateCandidateManuallyForm.reset();
    this.candidate_exists = [];
    // this.linkedin_candidate=true
  }

  createCandidate() {
    this.linkedin_submitted = true;
    if (this.CreateCandidateForm.invalid) {

      return;

    }

    // if (this.CreateCandidateForm.invalid){
    //   this.createCandidateError = 'Enter Valid Data';
    //   return;
    // }


    // if (this.createCandidateForm['linkedInUrl'].value.trim() == "") {
    //   this.notificationService.showError('Please Enter LinkedIn URL');
    //   this.createCandidateError = 'Please Enter LinkedIn URL';
    //   return;
    // }

    this.createCandidateData = {
      url: this.createCandidateForm['linkedInUrl'].value
    }

    if (this.createCandidateForm['projectId'].value) {
      this.createCandidateData.project_id = this.createCandidateForm['projectId'].value;
    }
    this.projectapicall = true;
    this.candidateService.create(this.createCandidateData).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.CreateCandidateForm.reset();
        this.notificationService.showSuccess(response.message);
        window.location.reload();
        this.projectapicall = false;
        this.linkedin_submitted = false;
        this.getCandidateList();
      },
      error: (error) => {
        this.createCandidateError = error.error.message;
        this.projectapicall = false;
        switch (error.status) {
          case 422:
            if (error.error.url) {
              this.createCandidateErrorUrl = error.error.url[0];
            }
            if (error.error.projectId) {
              this.createCandidateErrorProjectId = error.error.projectId[0];
            }
            break;
          default:
          //Nothing
        }
        // this.notificationService.showError(error.message);
      }
    });
  }

  candidateTypeChange(linkedin_candidate: boolean) {
    this.linkedin_candidate = linkedin_candidate;

  }
  createCandidateManually() {
    this.submitted = true;
    if (this.CreateCandidateManuallyForm.invalid) {
      return;
    }

    this.createCandidateManuallyData = {
      in_first_name: this.createCandidateManuallyForm['firstname'].value,
      in_last_name: this.createCandidateManuallyForm['lastname'].value,
      in_personal_email: this.createCandidateManuallyForm['email'].value,
      confirmed: (this.createCandidateManuallyConfirmation) ? 1 : 0
    }

    if (this.createCandidateManuallyForm['projectId'].value) {
      this.createCandidateManuallyData.project_id = this.createCandidateManuallyForm['projectId'].value;
    }
    this.projectapicall = true;
    this.candidateService.createManually(this.createCandidateManuallyData).subscribe({
      next: (response) => {
        this.manually_submitted = response?.status;

        if (response.status == 0) {
          this.projectapicall = false;
          this.createCandidateManuallyError = response.message;
          this.candidate_exists = response.candidates;
        } else {
          this.modalService.dismissAll();
          this.CreateCandidateManuallyForm.reset();
          this.notificationService.showSuccess(response.message);
          this.router.navigate(['/candidate/' + response.candidate.id]);
          this.projectapicall = false;
          this.submitted = false;
          // this.getCandidateList();
        }

      },
      error: (error) => {
        this.createCandidateError = error.error.message;
        this.projectapicall = false;
        switch (error.status) {
          case 422:
            if (error.error.url) {
              this.createCandidateErrorUrl = error.error.url[0];
            }
            if (error.error.projectId) {
              this.createCandidateErrorProjectId = error.error.projectId[0];
            }

            break;
          default:
          //Nothing
        }
        // this.notificationService.showError(error.message);
      }
    });
  }

  getTags() {
    this.tagService.listAll().subscribe({
      next: (response) => {
        this.tagList = response.tags.data;
        this.tagList = this.tagList.filter((ele: any) => ele.is_active == 1);
      },
      error: (err) => {
      }
    })
  }

  candidateBulkImport() {
    this.submitted = true;
    this.showFormView = true;
    if (this.CandidateBulkImportForm.invalid) {
      return;
    }


    if (!this.files) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('import', this.files);
    if (this.CandidateBulkImportForm.controls['searches'].value)
      formData.append('project_id', this.CandidateBulkImportForm.controls['searches'].value);

    if (this.CandidateBulkImportForm.controls['tags'].value)
      this.CandidateBulkImportForm.controls['tags'].value.forEach((tag: any) => {
        formData.append('tag_id[]', tag);
      });


    this.showFormView = true;
    this.projectapicall = true;
    this.candidateService.candidateBulkImport(formData).subscribe({
      next: (response: any) => {
        // this.onLoad();
        //  this.candidateBulkImportError = response.message;
        this.candidateBulkImportSuccess = response.message;
        this.showFormView = false;
        this.projectapicall = false;
        if (response.is_all_imported == 0) {
          this.notImportedCandidates = response.candidates[0];
        } else {
          //   this.modalService.dismissAll();
          // this.notificationService.showSuccess(response.message);
          // this.candidateBulkImportSuccess = response.message;
          this.submitted = false;
          this.showFormView = false;
          //  this.onLoad();  
        }
      },
      error: (error) => {
        this.projectapicall = false;
        this.candidateBulkImportError = error.error.message;
        this.showFormView = false;
        switch (error.status) {
          case 422:

            if (error.error.import) {
              this.errorArray = error.error.import;
              var len = this.errorArray.length;
              for (var i = 0; i < len; i++) {
                this.errorofimport = this.errorofimport + this.errorArray[i] + "\n";
              }
              this.bulkImpotedCandidateError = this.errorofimport;
            }
            break;
          default:
          //Nothing
        }
        // this.notificationService.showError(error.message);
      }
    })

  }

  closeImport() {
    this.modalService.dismissAll();
    this.submitted = false;
    this.showFormView = true;
    this.onLoad();
    this.candidateBulkImportError = "";
    this.candidateBulkImportSuccess = "";
    this.CandidateBulkImportForm.reset();
    this.notImportedCandidates = "";

  }

  resetBackendClienterror() {
    this.createClientErrorName = "";
    this.createClientErrorIndustry = "";
    this.createClientErrorCity = "";
    this.createClientErrorPrimarycontact = "";
    this.createClientError = "";
    this.CreateClientForm.reset();
  }

  createClients() {

    this.submitted = true;
    if (this.CreateClientForm.invalid) {
      return;
    }

    // if (this.CreateClientForm.invalid){
    //   this.createClientError = 'Enter Valid Data';
    //   return;
    // }

    // if (this.createClientForm['name'].value.trim() == "") {
    //   this.notificationService.showError('Please Enter name');
    //   this.createClientError="Please Enter Name";
    //   return;
    // }
    // if (this.createClientForm['industry'].value.trim() == "") {
    //   this.notificationService.showError('Please Enter industry');
    //   this.createClientError="Please Enter Industry";
    //   return;
    // }
    // if (this.createClientForm['city'].value.trim() == "") {
    //   this.notificationService.showError('Please Enter city');
    //   this.createClientError="Please Enter City";
    //   return;
    // }

    this.createClientData = {
      name: this.createClientForm['name'].value,
      industry: this.createClientForm['industry'].value,
      city: this.createClientForm['city'].value,
      primary_contact: this.createClientForm['primary_contact'].value,
      user_id: this.CreateClientForm.controls['user_id'].value,

    }

    this.projectapicall = true;
    this.clientService.create(this.createClientData).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.CreateClientForm.reset();
        this.notificationService.showSuccess(response.message);
        window.location.reload();
        this.projectapicall = false;
        this.submitted = false;
        this.getClientList();
      },
      error: (error) => {
        this.createClientError = error.error.message;
        this.projectapicall = false;
        //  this.createClientError = error.error.url;
        switch (error.status) {
          case 422:
            if (error.error.name) {
              this.createClientErrorName = error.error.name[0];
            }
            if (error.error.industry) {
              this.createClientErrorIndustry = error.error.industry[0];
            }

            if (error.error.city) {
              this.createClientErrorCity = error.error.city[0];
            }

            if (error.error.primary_contact) {
              this.createClientErrorPrimarycontact = error.error.primary_contact[0];
            }

            break;
          default:
          //Nothing
        }
        //  this.notificationService.showError(error.message);
      }
    });

  }

  resetBackendTagerror() {
    this.createTagErrorName = "";
    this.createTagErrorDescription = "";
    this.createTagError = "";
    this.CreateTagForm.reset();
  }

  createTags() {
    this.submitted = true;
    if (this.CreateTagForm.invalid) {
      return;
    }
    this.createTagData = {
      name: this.createTagForm['name'].value,
      description: this.createTagForm['description'].value,
    }
    this.projectapicall = true;
    this.tagService.create(this.createTagData).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.CreateTagForm.reset();
        this.notificationService.showSuccess(response.message);
        window.location.reload();
        this.projectapicall = false;
        this.submitted = false;
        this.getTagList();

      },
      error: (error) => {
        this.createTagError = error.error.message;
        this.projectapicall = false;
        switch (error.status) {
          case 422:
            if (error.error.name) {
              this.createTagErrorName = error.error.name[0];
            }
            if (error.error.description) {
              this.createTagErrorDescription = error.error.description[0];
            }

            break;
          default:
          //Nothing
        }
        //   this.notificationService.showError(error.message);
      }
    });

  }

  resetBackendCSerror() {
    this.createCSErrorName = "";
    this.createCSErrorDescription = "";
    this.createCSError = "";
    this.CreateCandidateStatusForm.reset();
  }

  resetBackendClSerror() {
    this.createClSErrorName = "";
    this.createClSErrorDescription = "";
    this.createClSError = "";
    this.CreateClientStatusForm.reset();
  }

  createCandidateStatus() {
    this.submitted = true;
    if (this.CreateCandidateStatusForm.invalid) {
      return;
    }
    this.createCandidateStatusData = {
      name: this.createCandidateStatusForm['name'].value,
      description: this.createCandidateStatusForm['description'].value,
    }
    this.csapicall = true;
    this.candidateService.createCandidateStatus(this.createCandidateStatusData).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.CreateCandidateStatusForm.reset();
        this.notificationService.showSuccess(response.message);
        window.location.reload();
        this.csapicall = false;
        this.submitted = false;
        //  this.getTagList();

      },
      error: (error) => {
        this.createCSError = error.error.message;
        this.csapicall = false;
        switch (error.status) {
          case 422:
            if (error.error.name) {
              this.createCSErrorName = error.error.name[0];
            }
            if (error.error.description) {
              this.createCSErrorDescription = error.error.description[0];
            }

            break;
          default:
          //Nothing
        }
        //   this.notificationService.showError(error.message);
      }
    });

  }

  createClientStatus() {
    this.submitted = true;
    if (this.CreateClientStatusForm.invalid) {
      return;
    }
    this.createClientStatusData = {
      name: this.createClientStatusForm['name'].value,
      description: this.createClientStatusForm['description'].value,
    }
    this.clsapicall = true;
    this.clientService.createClientStatus(this.createClientStatusData).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.CreateClientStatusForm.reset();
        this.notificationService.showSuccess(response.message);
        window.location.reload();
        this.csapicall = false;
        this.submitted = false;
        //  this.getTagList();

      },
      error: (error) => {
        this.createClSError = error.error.message;
        this.clsapicall = false;
        switch (error.status) {
          case 422:
            if (error.error.name) {
              this.createClSErrorName = error.error.name[0];
            }
            if (error.error.description) {
              this.createClSErrorDescription = error.error.description[0];
            }

            break;
          default:
          //Nothing
        }
        //   this.notificationService.showError(error.message);
      }
    });

  }


  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  resetBackendUsererror() {
    this.createUserErrorName = "";
    this.createUserErrorEmail = "";
    this.createUserErrorPassword = "";
    this.createUserErrorRoleid = "";
    this.createUserError = "";
    this.CreateUserForm.reset();
  }

  createUsers() {

    this.submitted = true;
    if (this.CreateUserForm.invalid) {
      return;
    }
    this.createUserData = {
      name: this.createUserForm['name'].value,
      email: this.createUserForm['email'].value,
      password: this.createUserForm['password'].value,
      role_id: (this.currentTenantId != '0') ? this.createUserForm['role_id'].value : 1,
    }


    this.projectapicall = true;
    this.userService.create(this.createUserData).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.CreateUserForm.reset();
        this.notificationService.showSuccess(response.message);
        window.location.reload();
        this.projectapicall = false;
        this.submitted = false;
        this.getUserList();
      },
      error: (error) => {
        this.createUserError = error.error.message;
        this.projectapicall = false;
        switch (error.status) {
          case 422:
            if (error.error.name) {
              this.createUserErrorName = error.error.name[0];
            }
            if (error.error.email) {
              this.createUserErrorEmail = error.error.email[0];
            }
            if (error.error.password) {
              this.createUserErrorPassword = error.error.password[0];
            }
            if (error.error.role_id) {
              this.createUserErrorRoleid = error.error.role_id[0];
            }
            break;
          default:
          //Nothing
        }
        // this.notificationService.showError(error.message);
      }
    });

  }

  getCandidateList() {
    this.candidateService.listAll().subscribe({
      next: (response) => {
        this.candidateList = response.candidates.data;
      },
      error: (error) => {
        this.createCandidateError = error.error.message;
        this.createCandidateErrorUrl = error.error.url;
        this.notificationService.showError(error.message);
      }
    });
  }

  getClientList() {
    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    if (currentTenant == '0') {
      return
    }
    this.clientService.listAll().subscribe({
      next: (response) => {
        this.clientList = response.clients.data;
      },
      error: (error) => {
        this.createProjectClientError = error.error.message;
        this.createProjectClientError = error.error.url;
        this.notificationService.showError(error.message);
      }
    });
  }

  getTagList() {
    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    if (currentTenant == '0') {
      return
    }
    this.tagService.listAll().subscribe({
      next: (response) => {
        this.tagList = response.tags.data;
      },
      error: (error) => {
        this.createTagError = error.error.message;
        this.createTagError = error.error.url;
        this.notificationService.showError(error.message);
      }
    });
  }

  onTagSearch(term: any) {
    let searchValue = term.term;
    if (true)
    // searchValue.length>=2
    {
      let data = { search: searchValue };
      this.tagService.searchTagList(data).subscribe({
        next: (response: any) => {
          this.tagList = response.tags.data;
          this.tagList = this.tagList.filter((ele: any) => ele.is_active == 1);
        },
        error: (err) => {

        }
      })
    }
  }

  getUserList() {
    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    if (currentTenant == '0') {
      return
    }
    this.userService.listAll().subscribe({
      next: (response) => {
        this.userList = response.users.data;
      },
      error: (error) => {
        this.createUserError = error.error.message;
        this.createUserError = error.error.url;
        this.notificationService.showError(error.message);
      }
    });
  }

  getProjectTypeList() {
    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    if (currentTenant == '0') {
      return
    }
    this.projectService.listTypeAll().subscribe({
      next: (response) => {
        this.projectTypeList = response.project_types;

      },
      error: (error) => {
        this.createProjectTypeError = error.error.message;
        this.createProjectTypeError = error.error.url;
        this.notificationService.showError(error.message);
      }
    });
  }

  onSearchClick(value: any) {
    this.searchClicked.emit(value);
  }

  onRefreshClick() {
    if (this.inputsearch)
      this.inputsearch.nativeElement.value = "";
    this.onRefreshClicked.emit();
  }

  onFilterClick() {
    this.onShowFilter.emit();
  }

  open(content: any | string) {
    // this.showFormView = true;
    this.selectedOption = 2;
    this.setupCreateProjectForm();

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //${this.getDismissReason(reason)}
      this.closeResult = `Dismissed `;
    });
  }


  // Open Add Job posting
  openaddjob(content: any | string) {

    this.AddJobPostingForm.controls['project_status_id'].setValue(2);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed `;
    });

    this.getClientsList();
  }



  setupCreateProjectForm() {
    this.getProjectList();
    this.getClientList();
    this.getProjectTypeList();
    this.getClientsList();
    this.getUserList();
  }


  onProjectSearch(term: any) {
    let searchValue = term.term;

    if (true)
    // searchValue.length>=2
    {
      let data = { search: searchValue };
      this.projectService.searchProjectwiseList(data).subscribe({
        next: (response: any) => {
          this.projectList = response.projects.data;
          this.projectList = this.projectList.filter((ele: any) => ele.project_status_id == 1);
          this.projectList.forEach((ele: any) => { ele["dropdown"] = ele?.name + (ele.client?.name ? " [" + ele.client?.name + "]" : "") })
        },
        error: (err) => {

        }
      })
    }
  }

  onUserSearch(term: any) {
    let searchValue = term.term;

    if (true)
    // searchValue.length>=2
    {
      let data = { search: searchValue };
      this.userService.searchUserwiseList(data).subscribe({
        next: (response: any) => {

          this.userList = response.users.data;
          //this.userList = this.userList.filter((ele:any) => ele.user_status_id == 1);
          this.userList.forEach((ele: any) => { ele["dropdown"] = ele?.name + (ele.user?.name ? " [" + ele.user?.name + "]" : "") })
        },
        error: (err) => {

        }
      })
    }
  }



  getProjectList() {
    //  this.projectselect?.clearModel();
    // this.showProjectDropdown=true;
    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    if (currentTenant == '0') {
      return
    }
    this.projectService.listAll().subscribe({
      next: (response) => {
        this.projectList = response.projects.data;
        this.projectList = this.projectList.filter((ele: any) => ele.project_status_id == 1);
        this.projectList.forEach((ele: any) => { ele["dropdown"] = ele?.name + (ele.client?.name ? " [" + ele.client?.name + "]" : "") })

      },
      error: (error) => {

      }
    });
  }

  onClientSearch(term: any) {
    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    if (currentTenant == '0') {
      return
    }
    let searchValue = term.term;

    if (true) {
      // searchValue.length>=2
      let data = { search_name: searchValue };
      this.clientService.searchClientwiseList(data).subscribe({
        next: (response: any) => {
          this.clientsList = response.clients.data;
          this.clientsList = this.clientsList.filter((ele: any) => ele.is_active == 1);

        },
        error: (err: any) => {

        }
      })
    }
  }



  getClientsList() {
    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    if (currentTenant == '0') {
      return
    }
    this.clientService.listAll().subscribe({
      next: (response) => {
        this.clientsList = response.clients.data;
        this.clientsList = this.clientsList.filter((ele: any) => ele.is_active == 1);
      },
      error: (error) => {

      }
    });
  }



  resetBackendTenanterror() {
    this.createTenantErrorId = "";
    this.createTenantErrorName = "";
    this.createTenantErroruserName = "";
    this.createTenantErroruserEmail = "";
    this.createTenantErroruserPassword = "";
    this.createTenantError = "";
    this.CreateTenantForm.reset();
    window.location.reload();
  }


  // for image
  openLogobox() {
    this.openLogoSection = true;
    this.openLogoButtton = false;
  }

  attachLogoFiles(event: any) {

    this.files2 = event.target.files[0];
    this.fileName2 = this.files2.name;
    this.filePath2 = event.target.value;

    if (event.target.files) {
      this.logoBox = true;
      this.rectangleBox = false;
      var reader = new FileReader;
      reader.onload = (event: any) => {
        this.url = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }


  }
  downloadSampleTemplate(sampleTemplateUrl: string) {
    const link = document.createElement('a');
    link.href = sampleTemplateUrl;
    link.target = '_blank';
    link.download = 'Candidates-Bulk-Import-Sample.xlsx';
    link.click();
  }
  sampleImport() {
    this.candidateService.candidateSampleFile().subscribe({
      next: (data: any) => {
        this.downloadSampleTemplate(data.import_sample_template)
      },
      error: (error) => {
      }
    })
  }


  createTenanats() {
    this.submitted = true;
    if (this.CreateTenantForm.invalid) {
      return;
    }
    this.createTenantData = {
      name: this.createTenantForm['name'].value,
      id: this.createTenantForm['id'].value,
      description: this.createTenantForm['description'].value,
      user_name: this.createTenantForm['user_name'].value,
      user_email: this.createTenantForm['user_email'].value,
      user_password: this.createTenantForm['user_password'].value,
      is_client_module_access_disabled: (this.createTenantForm['disable_access_to_client_module'].value) ? 1 : 0,
      is_public_search: (this.createTenantForm['is_public_search'].value) ? 1 : 0
    }

    this.projectapicall = true;
    const formData: FormData = new FormData();

    if (this.fileName2 != null) {
      formData.append('logoname', this.fileName2);
      formData.append('logo', this.files2);
      formData.append('name', this.createTenantForm['name'].value);
      formData.append('id', this.createTenantForm['id'].value);
      formData.append('description', this.createTenantForm['description'].value);
      formData.append('user_name', this.createTenantForm['user_name'].value);
      formData.append('user_email', this.createTenantForm['user_email'].value);
      formData.append('user_password', this.createTenantForm['user_password'].value);
      formData.append('is_public_search', (this.createTenantForm['is_public_search'].value) ? '1' : '0');
    } else if (this.fileName2 == null) {
      formData.append('name', this.createTenantForm['name'].value);
      formData.append('id', this.createTenantForm['id'].value);
      formData.append('description', this.createTenantForm['description'].value);
      formData.append('user_name', this.createTenantForm['user_name'].value);
      formData.append('user_email', this.createTenantForm['user_email'].value);
      formData.append('user_password', this.createTenantForm['user_password'].value);
      formData.append('is_public_search', (this.createTenantForm['is_public_search'].value) ? '1' : '0');
    }

    this.tenantService.create(formData).subscribe({
      next: (response) => {
        this.showtenantform = false
        this.CreateTenantForm.reset();
        this.notificationService.showSuccess(response.message);
        this.projectapicall = false;
        this.submitted = false;
        this.getTenantList();
      },
      error: (error) => {
        this.createTenantError = error.error.message;
        this.projectapicall = false;
        this.showtenantform = true;
        switch (error.status) {
          case 422:
            if (error.error.id) {
              this.createTenantErrorId = error.error.id[0];
            }
            if (error.error.name) {
              this.createTenantErrorName = error.error.name[0];
            }
            if (error.error.user_name) {
              this.createTenantErroruserName = error.error.user_name[0];
            }
            if (error.error.user_email) {
              this.createTenantErroruserEmail = error.error.user_email[0];
            }
            if (error.error.user_password) {
              this.createTenantErroruserPassword = error.error.user_password[0];
            }
            break;
          default:
        }
        // this.notificationService.showError(error.message);
      }
    });

  }

  getTenantList() {
    let data = {
      page: 1
    }
    this.tenantService.listByFilter(data).subscribe({
      next: (response) => {
        this.tenantList = response.tenants.data;
        // window.location.reload();
      },
      error: (error) => {
        this.createTenantError = error.error.message;
        this.createTenantError = error.error.url;
        this.notificationService.showError(error.message);
      }
    });
  }


  getStatusofprojectList() {
    if (this.statusofprojectList.length == 0) {
      this.projectService.statusofProjectList().subscribe({
        next: (response) => {
          this.statusofprojectList = response.project_statuses;

        },
        error: (error) => {
          this.notificationService.showError(error.message);
        }
      });
    }
  }

  getStateList() {
    this.projectService.listStateAll().subscribe({
      next: (response) => {
        this.stateList = response.states;
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      }
    });
  }

  createJobPosting() {

    this.submitted = true;
    if (this.AddJobPostingForm.invalid) {
      return;
    }

    let currentUserId = this.storageService.getValue(StorageKey.currentUserid);
    let userids = [currentUserId];
    let createjobappliedData = {
      name: this.addJobForm['projectName'].value,
      description: this.addJobForm['description'].value,
      project_status_id: this.addJobForm['project_status_id'].value,
      city: this.addJobForm['city'].value,
      state: this.addJobForm['state'].value,
      client_id: this.addJobForm['client'].value,
      is_public: 1,
      user_ids: userids,
    }

    this.projectService.create(createjobappliedData).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.AddJobPostingForm.reset();
        this.notificationService.showSuccess('Job Posting Ceeated Successfully');
        window.location.reload();
        this.projectapicall = false;
        this.submitted = false;
        this.getProjectList();
      },
      error: (error) => {
        this.createProjectError = error.error.message;
        this.projectapicall = false;
        switch (error.status) {
          case 422:
            if (error.error.name) {
              this.createProjectErrorName = error.error.name[0];
            }
            if (error.error.description) {
              this.createProjectErrorDescription = error.error.description[0];
            }
            if (error.error.city) {
              this.createProjectErrorCity = error.error.city[0];
            }
            if (error.error.state) {
              this.createProjectErrorCity = error.error.state[0];
            }
            if (error.error.is_public) {
              this.createProjectErroris_public = error.error.is_public[0];
            }
            if (error.error.client_id) {
              this.createProjectErrorClientname = error.error.client_id[0];
            }
            break;
          default:

          //Nothing

        }
      }
    });
  }



  ngOnDestroy(): void {
    // Unsubscribe from the route subscription to avoid memory leaks
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

}
