import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CandidateRoutingModule } from './candidate-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CandidateRoutingModule,
  ]
})
export class CandidateModule { }
