import { AbstractControl, ValidatorFn } from '@angular/forms';

export function dueDateNotPast(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const currentDate = new Date();
        const selectedDate = new Date(control.value);

        if (selectedDate < currentDate) {
            return { 'pastDate': { value: control.value } };
        }
        return null;
    };
}


export function birthDateNotFuture(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const currentDate = new Date();
      const selectedDate = new Date(control.value);
      // Clear time part to ensure date-only comparison
      currentDate.setHours(0, 0, 0, 0);
      selectedDate.setHours(0, 0, 0, 0);
      
      if (selectedDate > currentDate) {
        return { 'futureDate': { value: control.value } };
      }
      return null;
    };
  }
