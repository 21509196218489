import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './pages';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RegisterComponent } from './pages/register/register.component';
import { CandidateLoginComponent } from './pages/candidate-login/candidate-login.component';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [
    ResetPasswordComponent,
    RegisterComponent,
    CandidateLoginComponent,
    // LoginComponent
  ],
  imports: [
    CommonModule,
    LoginRoutingModule,
    FormsModule,
    ReactiveFormsModule,NgSelectModule
  ]
})
export class LoginModule { }
