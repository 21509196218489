<app-app-jobheader></app-app-jobheader>

<div class=" candidate_details">

    <div class="top-row d-flex justify-content-between">
        <div class="top-left-details w-60 d-flex align-middle">

            <div class="top_profile">

                <div class="container1">
                    <img *ngIf="url !== null" [src]="url" height="120" width="120"
                        class="clientlogobgimg img-fluid image1" alt="">
                    <a class="edit-icon1" (click)="selectFile.click()"
                        style="padding-right: 5px;padding-left: 5px; background:white">
                        <em class="bi bi-camera-fill"
                            title="Edit Image"></em></a>
                    <input type="file" id="file-input" height="120" width="120" style="display: none;" accept="image/*"
                        multiple #selectFile max-file-size="1024" name="logo"
                        class="form-control clientlogobgimg img-fluid image1" (change)="attachLogoFiles($event)" />
                </div>


            </div>

            <div class="top_right_profile ps-3 d-inline-block">
                <h3 class="d-inline-block align-middle m-0">
                    {{(candidateData?.in_first_name?candidateData?.in_first_name:"") +"
                    "+ (candidateData?.in_last_name?candidateData?.in_last_name:"")}}</h3>
                <p>{{candidateData?.current_position }} - {{candidateData?.current_company}}</p>

            </div>


        </div>
    </div>


    <div class="row details-bottom-table">

        <div class="accordion col-4" id="accordionPanelsStayOpenExample">

            <!-- Contact Summary -->
            <div class="accordion-item mb-3 rounded overflow-hidden">

                <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne">
                        Contact Summary
                    </button>

                    <a href="javascript:void(0)" class="edit"><img src="./assets/img/edit.svg" alt="" title="Edit"
                            (click)="open(UpdateCandidateDetails,'UpdateCandidateDetails')"></a>
                </h2>

                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne" title="Expand/Collapse">

                    <div class="accordion-body">

                        <ul class="row list-unstyled">
                            <li class="col-5">Full Name </li>
                            <li class="col-7">{{candidateData?.in_first_name +" "+ candidateData?.in_last_name}}</li>
                            <li class="col-5">Gender</li>
                            <li class="col-7">{{ capitalizeFirstLetter(candidateData?.in_gender) }}</li>

                            <li class="col-5">Current Company </li>
                            <li class="col-7">
                                {{candidateData?.current_company?candidateData?.current_company:"---"}}
                            </li>
                            <li class="col-5">Current Position </li>
                            <li class="col-7">{{candidateData?.current_position?candidateData?.current_position:"---"}}
                            </li>
                        
                            <li class="col-5">Address</li>
                            <li class="col-7">{{candidateData?.in_city?candidateData?.in_city:"---" }},
                                {{candidateData?.in_state }}
                            
                            <li class="col-5">Country </li>
                            <li class="col-7">
                                {{candidateData?.in_country_full_name?candidateData?.in_country_full_name:"---"}}</li>
                            <li class="col-5">Linked-In </li>
                            <li class="col-7"><span *ngIf="!candidateData?.linkedin_url"> --- </span><a
                                    *ngIf="candidateData?.linkedin_url" href="{{candidateData?.linkedin_url}}" title=""
                                    target="_blank">{{candidateData?.linkedin_url?"Open LinkedIn Profile":"---"}} <span
                                        *ngIf="candidateData?.linkedin_url !== null"
                                        style="width: 24px;height: 24px;"><img alt="" src="assets/img/linkedin-logo.png"
                                            style="width: 24px;height: 24px;" /> </span></a>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- Contact details -->
            <div class="accordion-item mb-3 rounded overflow-hidden">
                <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseTwo">
                        Contact Details
                    </button>
                    <a href="javascript:void(0)" class="edit" title="Edit"
                        (click)="open(UpdateContactDetails,'UpdateContactDetails')"><img src="./assets/img/edit.svg"
                            alt=""></a>
                </h2>
                <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingTwo">
                    <div class="accordion-body">
                        <ul class="row list-unstyled">
                            <li class="col-5">Work Email </li>
                            <li class="col-7"><img *ngIf="candidateData?.is_in_work_email_preffered"
                                    src="./assets/img/preferred.svg" style="width: 16px; height: auto;" alt=""><a
                                    href="mailto:{{candidateData?.in_work_email}}">{{candidateData?.in_work_email?candidateData?.in_work_email:"---"}}</a>
                            </li>
                            <li class="col-5">Personal Email </li>
                            <li class="col-7"><img *ngIf="candidateData?.is_in_personal_email_preffered"
                                    src="./assets/img/preferred.svg" style="width: 16px; height: auto;" alt=""><a
                                    href="mailto:{{candidateData?.in_personal_email}}">{{candidateData?.in_personal_email?candidateData?.in_personal_email:"---"}}</a>
                            </li>
                            <li class="col-5">Work Phone </li>
                            <li class="col-7"><img *ngIf="candidateData?.is_in_work_phone_preffered"
                                    src="./assets/img/preferred.svg" style="width: 16px; height: auto;"
                                    alt="">{{candidateData?.in_work_phone?candidateData?.in_work_phone:"---"}}</li>
                            <li class="col-5">Personal Phone </li>
                            <li class="col-7"><img title="preferred phone"
                                    *ngIf="candidateData?.is_in_personal_phone_preffered"
                                    src="./assets/img/preferred.svg" style="width: 16px; height: auto;"
                                    alt="">{{candidateData?.in_personal_phone?candidateData?.in_personal_phone:"---"}}
                            </li>


                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <div class="accordion col-4" id="accordionPanelsStayOpenExample-two">

            <!--Documents(Resumes)-->
            <div class="accordion-item mb-3 rounded overflow-hidden">
                <h2 class="accordion-header" id="panelsStayOpen-headingsix">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapsesix" aria-expanded="true"
                        aria-controls="panelsStayOpen-collapsesix">
                        Documents (e.g. Resume)
                    </button>
                    <a href="javascript:void(0)" title="Add" (click)="open(UploadDocument,'UploadDocument')"
                        class="edit"><img src="./assets/img/plus-black.svg" alt=""></a>
                </h2>
                <div id="panelsStayOpen-collapsesix" class="accordion-collapse collapse show scrolldocument"
                    aria-labelledby="panelsStayOpen-headingsix">
                    <div class="accordion-body" *ngFor="let document of documentData">
                        <div class="experience d-flex justify-content-between">
                            <div class="experience-left col-lg-10">
                                <h4 class="m-0"><a href="{{document?.path}}" target="_blank"> {{document?.name}}</a>
                                </h4>
                                <p *ngIf="document.description != 'null'" class="m-0">{{document?.description}} </p>
                                <p *ngIf="document.description == 'null'">-</p>
                            </div>
                            <div class="inner-edit col-lg-2 " style="text-align: right;">
                                <a href="javascript:void(0)" (click)="open(EditDocument,'EditDocument',document.id)"><em
                                        class="bi bi-pencil-square me-2" title="Edit"></em></a>
                                <a href="javascript:void(0)"
                                    (click)="open(deleteDocument,'deleteDocument',document.id,document.name)">
                                    <em class="bi bi-trash" title="Delete"></em></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-body" *ngIf="documentData?.length==0">No Documents added</div>
            </div>

            <!--License & Certifications-->
            <div class="accordion-item mb-3 rounded overflow-hidden">
                <h2 class="accordion-header" id="panelsStayOpen-headingfive">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapsefive" aria-expanded="true"
                        aria-controls="panelsStayOpen-collapsefive">
                        License & Certifications
                    </button>
                    <a href="javascript:void(0)" class="edit" title="Add"
                        (click)="open(AddCertificate,'AddCertificate')"><img src="./assets/img/plus-black.svg"
                            alt=""></a>
                </h2>
                <div id="panelsStayOpen-collapsefive" class="accordion-collapse collapse show scrolllicence"
                    aria-labelledby="panelsStayOpen-headingseven">
                    <div class="accordion-body pl-0" *ngFor="let certificate of certificateList">
                        <div class="experience d-flex justify-content-between">
                            <div class="experience-left">
                                <h4 class="m-0">{{certificate?.name}}</h4>
                                <p class="m-0">{{certificate?.authority}}</p>
                                <p class="m-0">{{certificate?.start_date | date: 'MM/dd/yyyy' }} <span
                                        *ngIf="certificate.start_date && certificate.end_date">to</span>
                                    {{certificate.end_date | date:
                                    'MM/dd/yyyy' }}</p>
                            </div>
                            <div class="inner-edit">
                                <a href="javascript:void(0)"
                                    (click)="open(EditCertificate,'EditCertificate',certificate.id)"><em
                                        class="bi bi-pencil-square me-2" title="Edit"></em></a>
                                <a href="javascript:void(0)"
                                    (click)="open(deleteCerticate,'deleteCerticate',certificate.id,certificate.name)">
                                    <em class="bi bi-trash" title="Delete"></em></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordion-body" *ngIf="certificateList?.length==0">No license and certifications added</div>
            </div>

            <!-- Education -->
            <div class="accordion-item mb-3 rounded overflow-hidden ">
                <h2 class="accordion-header" id="panelsStayOpen-headingseven">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseseven" aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseseven">
                        Education
                    </button>
                    <a href="javascript:void(0)" title="Add" class="edit"
                        (click)="open(AddEducation,'AddEducation')"><img src="./assets/img/plus-black.svg" alt=""></a>
                </h2>
                <div id="panelsStayOpen-collapseseven" class="accordion-collapse collapse show scrolleducation"
                    aria-labelledby="panelsStayOpen-headingseven">
                    <div class="accordion-body" *ngFor="let education of educationData">
                        <div class="experience d-flex justify-content-between">
                            <div class="experience-left">
                                <h4>{{education?.degree_name}}</h4>
                                <p class="m-0">{{education?.school}}</p>
                                <p class="m-0">{{education?.start_date | date: 'MM/dd/yyyy' }} <span
                                        *ngIf="education.start_date && education.end_date">to</span>
                                    {{education.end_date | date:
                                    'MM/dd/yyyy'}}</p>
                            </div>
                            <div class="inner-edit">
                                <a href="javascript:void(0)"
                                    (click)="open(UpdateEducation,'UpdateEducation',education.id)"><em
                                        class="bi bi-pencil-square me-2" title="Edit"></em></a>
                                <a href="javascript:void(0)"
                                    (click)="open(deleteEducation,'deleteEducation',education.id,education.degree_name)">
                                    <em class="bi bi-trash" title="Delete"></em></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-body" *ngIf="educationData?.length==0">No Education added</div>
            </div>


        </div>


        <!-- JOB Applied -->
        <div class="accordion col-4" id="accordionPanelsStayOpenExample-two">

            <div class="accordion-item mb-3 rounded overflow-hidden">
                <h2 class="accordion-header" id="panelsStayOpen-headingjobs">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapsejobs" aria-expanded="true"
                        aria-controls="panelsStayOpen-collapsejobs">
                        Activities
                    </button>
                </h2>
                <div id="panelsStayOpen-collapsejobs" class="accordion-collapse collapse show scrollapplyjob"
                    aria-labelledby="panelsStayOpen-headingjobs">
                    <div class="accordion-body" *ngFor="let job of applyjobData">
                        <div class="experience d-flex justify-content-between" style="padding: 5px;">
                            <div class="experience-left col-lg-7">
                                <h4 class="m-0">{{job?.tenant_name}}</h4>
                                <p *ngIf="job.city != 'null'" class="m-0 mt-1">{{job?.count}} job(s) applied </p>
                            </div>
                            <div class="inner-edit col-lg-5 jdHeadRight" style="text-align: right;">
                                <a (click)="handleOpen(job.tenant_id)">Open</a>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="accordion-body" *ngIf="applyjobData?.length==0">No Jobs added</div>
            </div>

        </div>

    </div>

</div>


<!-- Contact Summary -->
<ng-template #UpdateCandidateDetails let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit Contact Summary</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <form [formGroup]="CreateCandidateDetailsForm">

        <div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateCandidateDetailsError">{{CreateCandidateDetailsError}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateCandidateDetailsErrorFirstname">{{CreateCandidateDetailsErrorFirstname}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateCandidateDetailsErrorLastname">{{CreateCandidateDetailsErrorLastname}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateCandidateDetailsErrorGender">{{CreateCandidateDetailsErrorGender}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateCandidateDetailsErrorInbirthdate">{{CreateCandidateDetailsErrorInbirthdate}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateCandidateDetailsErrorCurrentcompany">{{CreateCandidateDetailsErrorCurrentcompany}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateCandidateDetailsErrorCurrentposition">{{CreateCandidateDetailsErrorCurrentposition}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateContactsDetailsErrorInstate">{{CreateContactsDetailsErrorInstate}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateContactsDetailsErrorIncity">{{CreateContactsDetailsErrorIncity}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateContactsDetailsErrorIncountryfullname">{{CreateContactsDetailsErrorIncountryfullname}}
            </div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateCandidateDetailsErrorLinkedinurl">{{CreateCandidateDetailsErrorLinkedinurl}}</div>

        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    <div class="mb-3">
                        <label for="">First name</label>
                        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                        <input type="text" placeholder="Enter first name" formControlName="firstname" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['firstname'].errors }">
                        <div style="color: red; font-size: 14px;"
                            *ngIf="submitted && f['firstname'] && f['firstname'].errors && f['firstname'].errors['required']">
                            First name is required
                        </div>
                        <div style="color: red; font-size: 14px; text-align: left" class="w-100 col-12 ps-0"
                            *ngIf="submitted && (f['firstname'].errors?.['minlength'] || f['firstname'].errors?.['maxlength'])">
                            First name must be between 1 - 15 characters.
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="mb-3">
                        <label for="">Last name</label>
                        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                        <input type="text" placeholder="Enter last name" formControlName="lastname" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f['lastname'].errors }">
                        <div style="color: red; font-size: 14px;"
                            *ngIf="submitted && f['lastname'] && f['lastname'].errors && f['lastname'].errors['required']">
                            Last name is required
                        </div>
                        <div style="color: red; font-size: 14px; text-align: left" class="w-100 col-12 ps-0"
                            *ngIf="submitted && (f['lastname'].errors?.['minlength'] || f['lastname'].errors?.['maxlength'])">
                            Last name must be between 1 - 15 characters.
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="mb-3">
                        <label for="">Gender</label>
                        <select name="" id="" class="form-select" formControlName="gender">
                            <option value=null>Select gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                </div>

                <div class="col-6">
                 
                    <div class="mb-3">
                        <label for="">Birth Date</label>
                        <input type="date" placeholder="Enter birth date" formControlName="in_birth_date" class="form-control">
                        <div style="color: red; font-size: 14px;" *ngIf="submitted && f['in_birth_date'].errors">
                            <div *ngIf="f['in_birth_date'].errors['futureDate']">
                                Birth Date cannot be in the future.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="mb-3">
                        <label for="">Current Company <small>(Max 30 characters)</small></label>
                        <input type="text" placeholder="Enter current company" formControlName="current_company"
                            class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="mb-3">
                        <label for="">Current Position <small>(Max 30 characters)</small></label>
                        <input type="text" placeholder="Enter current position" formControlName="current_position"
                            class="form-control">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <div class="mb-3">

                        <label for="">City</label>
                        <input type="text" placeholder="Enter city" formControlName="in_city" class="form-control">
                    </div>
                </div>
                <div class="col-6">
                    <div class="mb-3">
                        <label for="">State</label>
                        <ng-select [items]="stateData" class="form-control form-select " placeholder="Select state"
                            bindLabel="name" formControlName="in_state" bindValue="name">
                        </ng-select>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-6">
                    <div class="mb-3">
                        <label for="">Country</label>
                        <input type="text" placeholder="Enter country" formControlName="country" class="form-control">

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="mb-3">
                        <label for="">Linked-In URL</label>
                        <input type="text"
                            [readonly]="candidateData.creation_type !== 'Manual' && candidateData.linkedin_url !== null"
                            placeholder="Enter Linked-In URL" formControlName="linkedin_url" class="form-control">
                    </div>
                </div>
            </div>

        </div>

        <div class="modal-footer">
            <div class="spinner-border" *ngIf="projectapicall">
                <span class="visually-hidden">Loading...</span>
            </div>
            <button type="button" class="filter" (click)="modal.close('Save click')"
                *ngIf="!projectapicall">Cancel</button>
            <button type="button" class="blue-btn ms-" (click)="UpdateCandidatesDetails()"
                *ngIf="!projectapicall">Save</button>
        </div>

    </form>
</ng-template>

<!-- Contact Details -->
<ng-template #UpdateContactDetails let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit Contact Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <form [formGroup]="CreateCandidateDetailsForm">

        <!-- Show Error Msg  -->
        <div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateContactsDetailsError">{{CreateContactsDetailsError}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateContactsDetailsErrorInpersonalphone">{{CreateContactsDetailsErrorInpersonalphone}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateContactsDetailsErrorInpersonalemail">{{CreateContactsDetailsErrorInpersonalemail}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateContactsDetailsErrorInworkphone">{{CreateContactsDetailsErrorInworkphone}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateContactsDetailsErrorInworkemail">{{CreateContactsDetailsErrorInworkemail}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateContactsDetailsErrorisInpersonalphonepreffered">
                {{CreateContactsDetailsErrorisInpersonalphonepreffered}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateContactsDetailsErrorisInpersonalemailpreffered">
                {{CreateContactsDetailsErrorisInpersonalemailpreffered}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateContactsDetailsErrorisInworkphonepreffered">
                {{CreateContactsDetailsErrorisInworkphonepreffered}}
            </div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateContactsDetailsErrorisInworkemailpreffered">
                {{CreateContactsDetailsErrorisInworkemailpreffered}}
            </div>

        </div>

        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    <div class="mb-3">

                        <label for="">Personal Phone</label>
                        <input type="text" placeholder="Enter personal phone" (keypress)="restrictAlphabet($event)"
                            formControlName="in_personal_phone" maxlength="20" class="form-control">
                        <div class="form-check pt-1">
                            <input class="form-check-input" title="preferred" type="checkbox" value=""
                                formControlName="is_in_personal_phone_preffered">
                            <label class="form-check-label" title="preferred" for="flexCheckDefault">
                                Preferred phone
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-6">

                    <div class="mb-3">
                      
                        <label for="">Personal Email
                            <span>
                                <button style="margin-left: -30px;" type="button" class="btn btn-icon btn-sm"
                                    [ngbTooltip]="tooltipContent" placement="top">
                                    <em class="fa fa-info-circle" aria-hidden="true"></em>
                                </button>
                            </span>
                        </label>

                        <input type="text" placeholder="Enter personal Email" formControlName="in_personal_email"
                            class="form-control">
                        <div class="form-check pt-1">
                            <input class="form-check-input" title="preferred" type="checkbox" value=""
                                formControlName="is_in_personal_email_preffered">
                            <label class="form-check-label" title="preferred" for="flexCheckDefault">
                                Preferred email
                            </label>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-6">
                    <div class="mb-3">

                        <label for="">Work phone</label>
                        <input type="text" placeholder="Enter work phone" (keypress)="restrictAlphabet($event)" formControlName="in_work_phone"
                            maxlength="20"  class="form-control">
                        <div class="form-check pt-1">
                            <input class="form-check-input" title="preferred" type="checkbox" value=""
                                formControlName="is_in_work_phone_preffered">
                            <label class="form-check-label" title="preferred" for="flexCheckDefault">
                                Preferred phone
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="mb-3">
                        <label for="">Work Email</label>
                        <input type="text" placeholder="Enter work email" formControlName="in_work_email"
                            class="form-control">
                        <div class="form-check pt-1">
                            <input class="form-check-input" type="checkbox" value="" title="preferred"
                                formControlName="is_in_work_email_preffered">
                            <label class="form-check-label" for="flexCheckDefault" title="preferred">
                                Preferred email
                            </label>
                        </div>
                    </div>
                </div>
            </div>



        </div>

        <div class="modal-footer">
            <div class="spinner-border" *ngIf="projectapicall">
                <span class="visually-hidden">Loading...</span>
            </div>
            <button type="button" class="filter" (click)="modal.close('Save click')"
                *ngIf="!projectapicall">Cancel</button>
            <button type="button" class="blue-btn ms-" (click)="UpdateContactsDetails()"
                *ngIf="!projectapicall">Save</button>
        </div>

    </form>

</ng-template>

<!-- Add Education -->
<ng-template #AddEducation let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Education Details</h4>
        <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click') ; this.submitted = false ; resetBackendAddEducationDetailserror()">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <form [formGroup]="AddEducationForm">

        <div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateEducationDetailsError">{{CreateEducationDetailsError}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateEducationDetailsErrorDegreename">{{CreateEducationDetailsErrorDegreename}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateEducationDetailsErrorSchool">{{CreateEducationDetailsErrorSchool}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateEducationDetailsErrorStartdate">{{CreateEducationDetailsErrorStartdate}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateEducationDetailsErrorEnddate">{{CreateEducationDetailsErrorEnddate}}</div>
        </div>

        <div class="modal-body">
            <div class="mb-3">
                <label for="">Degree name</label>
                <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                <input type="text" placeholder="Degree is required" formControlName="degree_name" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && educationDetails['degree_name'].errors }">
                <div style="color: red; font-size: 14px;"
                    *ngIf="submitted && educationDetails['degree_name'] && educationDetails['degree_name'].errors && educationDetails['degree_name'].errors['required']">
                    Degree is required.
                </div>
            </div>

            <div class="mb-3">
                <label for="">School</label>
                <input type="text" placeholder="Enter school name" formControlName="school" class="form-control">
            </div>

            <div class="row mb-3">
              
                <div class="col-6">
                    <label for="">Start Date</label>
                    <input type="date" formControlName="start_date" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && AddEducationForm.controls['start_date'].errors }">
                    <div style="color: red; font-size: 14px;"
                        *ngIf="submitted && AddEducationForm.controls['start_date'].errors?.['futureDate']" class="invalid-feedback">
                        Start date cannot be a future date.
                    </div>
                </div>
                
                <div class="col-6">
                    <label for="">End Date</label>
                    <input type="date" formControlName="end_date" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && AddEducationForm.controls['end_date'].errors }">
                    <div style="color: red; font-size: 14px;"
                        *ngIf="submitted && AddEducationForm.controls['end_date'].errors?.['endDateLessThanStartDate']"
                        class="invalid-feedback">
                        End date cannot be less than start date.
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <div class="spinner-border" *ngIf="projectapicall">
                <span class="visually-hidden">Loading...</span>
            </div>
            <button type="button" class="filter"
                (click)="modal.close('Save click') ; this.submitted=false ; resetBackendAddEducationDetailserror()"
                *ngIf="!projectapicall">Cancel</button>
            <button type="button" class="blue-btn ms-" (click)="AddEducationDetails()"
                *ngIf="!projectapicall">Save</button>
        </div>

    </form>


</ng-template>

<!-- Delete Education -->
<ng-template #deleteEducation let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Education Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="mb-3">
            <label for="">Do you want to Delete <strong>{{deleteName}}</strong> Education ?</label>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary cancelbutton"
            (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-danger dangerbtn" (click)="deleteEducationDetail()">Delete</button>
    </div>
</ng-template>

<!-- Update Education -->
<ng-template #UpdateEducation let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit Education Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <form [formGroup]="AddEducationForm">
        <div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="UpdateEducationDetailsError">{{UpdateEducationDetailsError}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="UpdateEducationDetailsErrorDegreename">{{UpdateEducationDetailsErrorDegreename}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="UpdateEducationDetailsErrorSchool">{{UpdateEducationDetailsErrorSchool}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="UpdateEducationDetailsErrorStartdate">{{UpdateEducationDetailsErrorStartdate}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="UpdateEducationDetailsErrorEnddate">{{UpdateEducationDetailsErrorEnddate}}</div>
        </div>
        <div class="modal-body">
            <div class="mb-3">
                <label for="">Degree name</label>
                <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                <input type="text" placeholder="Degree is required" formControlName="degree_name" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && educationDetails['degree_name'].errors }">
                <div style="color: red; font-size: 14px;"
                    *ngIf="submitted && educationDetails['degree_name'] && educationDetails['degree_name'].errors && educationDetails['degree_name'].errors['required']">
                    Degree is required.
                </div>
            </div>
            <div class="mb-3">
                <label for="">School</label>
                <input type="text" placeholder="Enter school name" formControlName="school" class="form-control">
            </div>

            <div class="row mb-3">


                <div class="col-6">
                    <label for="">Start Date</label>
                    <input type="date" formControlName="start_date" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && AddEducationForm.controls['start_date'].errors }">
                    <div style="color: red; font-size: 14px;"
                        *ngIf="submitted && AddEducationForm.controls['start_date'].errors?.['futureDate']" class="invalid-feedback">
                        Start date cannot be a future date.
                    </div>
                </div>
                
                <div class="col-6">
                    <label for="">End Date</label>
                    <input type="date" formControlName="end_date" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && AddEducationForm.controls['end_date'].errors }">
                    <div style="color: red; font-size: 14px;"
                        *ngIf="submitted && AddEducationForm.controls['end_date'].errors?.['endDateLessThanStartDate']"
                        class="invalid-feedback">
                        End date cannot be less than start date.
                    </div>
                </div>

                
            </div>

        </div>

        <div class="modal-footer">
            <div class="spinner-border" *ngIf="projectapicall">
                <span class="visually-hidden">Loading...</span>
            </div>
            <button type="button" class="filter" (click)="modal.close('Save click')"
                *ngIf="!projectapicall">Cancel</button>
            <button type="button" class="blue-btn ms-" (click)="UpdateEducationDetails()"
                *ngIf="!projectapicall">Save</button>
        </div>
    </form>
</ng-template>

<!-- Add Certificate Details -->
<ng-template #AddCertificate let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Certificate Details</h4>
        <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click'); this.submitted = false ; ResetAddCertifcationerror()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <form [formGroup]="AddCertificateForm">

        <div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateCertificateDetailsError">{{CreateCertificateDetailsError}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateCertificateDetailsErrorName">{{CreateCertificateDetailsErrorName}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateCertificateDetailsErrorAuthority">{{CreateCertificateDetailsErrorAuthority}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateCertificateDetailsErrorStartdate">{{CreateCertificateDetailsErrorStartdate}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreateCertificateDetailsErrorEnddate">{{CreateCertificateDetailsErrorEnddate}}</div>
        </div>

        <div class="modal-body">
            <div class="mb-3">
                <label for="">Name</label>
                <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                <input type="text" placeholder="Enter certificate name" formControlName="name" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && certificateDetails['name'].errors }">
                <div style="color: red; font-size: 14px;"
                    *ngIf="submitted && certificateDetails['name'] && certificateDetails['name'].errors && certificateDetails['name'].errors['required']">
                    Name is required.
                </div>
            </div>

            <div class="mb-3">

                <label for="">Authority</label>
                <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                <input type="text" placeholder="Enter authority" formControlName="authority" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && certificateDetails['authority'].errors }">
                <div style="color: red; font-size: 14px;"
                    *ngIf="submitted && certificateDetails['authority'] && certificateDetails['authority'].errors && certificateDetails['authority'].errors['required']">
                    Authority is required.
                </div>
            </div>

            <div class="row mb-3">

                <div class="col-6">
                    <label for="">Start Date</label>
                    <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                    <input type="date" formControlName="start_date" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && AddCertificateForm.controls['start_date'].errors }">
                    <div style="color: red; font-size: 14px;"
                        *ngIf="submitted && AddCertificateForm.controls['start_date'].errors?.['futureDate']"
                        class="invalid-feedback">
                        Start date cannot be a future date.
                    </div>
                </div>

                <div class="col-6">
                    <label for="">End Date</label>
                    <input type="date" formControlName="end_date" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && AddCertificateForm.controls['end_date'].errors }">
                    <div style="color: red; font-size: 14px;"
                        *ngIf="submitted && AddCertificateForm.controls['end_date'].errors?.['endDateLessThanStartDate']"
                        class="invalid-feedback">
                        End date cannot be less than start date.
                    </div>
                </div>
            </div>


        </div>

        <div class="modal-footer">
            <div class="spinner-border" *ngIf="projectapicall">
                <span class="visually-hidden">Loading...</span>
            </div>
            <button type="button" class="filter"
                (click)="modal.close('Save click'); this.submitted=false ; ResetAddCertifcationerror()"
                *ngIf="!projectapicall">Cancel</button>
            <button type="button" class="blue-btn ms-" (click)="AddCertificateDetails()"
                *ngIf="!projectapicall">Save</button>
        </div>


    </form>
</ng-template>

<!-- delete Certificate Details -->
<ng-template #deleteCerticate let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Certificate Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="mb-3">
            <label for="">Do you want to Delete <strong>{{deleteName}}</strong> Certificate ?</label>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary cancelbutton"
            (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-danger dangerbtn" (click)="deleteCertificateDetail()">Delete</button>
    </div>
</ng-template>

<!-- update Certificate Details -->
<ng-template #EditCertificate let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit Certificate Details</h4>
        <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click');this.submitted=false">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <form [formGroup]="AddCertificateForm">

        <div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="UpdateCertificateDetailsError">{{UpdateCertificateDetailsError}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="UpdateCertificateDetailsErrorName">{{UpdateCertificateDetailsErrorName}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="UpdateCertificateDetailsErrorAuthority">{{UpdateCertificateDetailsErrorAuthority}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="UpdateCertificateDetailsErrorStartdate">{{UpdateCertificateDetailsErrorStartdate}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="UpdateCertificateDetailsErrorEnddate">{{UpdateCertificateDetailsErrorEnddate}}</div>
        </div>
        <div class="modal-body">
            <div class="mb-3">
                <label for="">Name</label>
                <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                <input type="text" placeholder="Enter certificate name" formControlName="name" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && certificateDetails['name'].errors }">
                <div style="color: red; font-size: 14px;"
                    *ngIf="submitted && certificateDetails['name'] && certificateDetails['name'].errors && certificateDetails['name'].errors['required']">
                    Name is required.
                </div>
            </div>

            <div class="mb-3">

                <label for="">Authority</label>
                <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                <input type="text" placeholder="Enter authority" formControlName="authority" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && certificateDetails['authority'].errors }">
                <div style="color: red; font-size: 14px;"
                    *ngIf="submitted && certificateDetails['authority'] && certificateDetails['authority'].errors && certificateDetails['authority'].errors['required']">
                    Authority is required.
                </div>
            </div>

            <div class="row mb-3">

                <div class="col-6">
                    <label for="">Start Date</label>
                    <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                    <input type="date" formControlName="start_date" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && AddCertificateForm.controls['start_date'].errors }">
                    <div style="color: red; font-size: 14px;"
                        *ngIf="submitted && AddCertificateForm.controls['start_date'].errors?.['futureDate']"
                        class="invalid-feedback">
                        Start date cannot be a future date.
                    </div>
                </div>

                <div class="col-6">
                    <label for="">End Date</label>
                    <input type="date" formControlName="end_date" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && AddCertificateForm.controls['end_date'].errors }">
                    <div style="color: red; font-size: 14px;"
                        *ngIf="submitted && AddCertificateForm.controls['end_date'].errors?.['endDateLessThanStartDate']"
                        class="invalid-feedback">
                        End date cannot be less than start date.
                    </div>
                </div>
            
            </div>
        </div>

        <div class="modal-footer">
            <div class="spinner-border" *ngIf="projectapicall">
                <span class="visually-hidden">Loading...</span>
            </div>
            <button type="button" class="filter" (click)="modal.close('Save click')"
                *ngIf="!projectapicall">Cancel</button>
            <button type="button" class="blue-btn ms-" (click)="updateCertificateDetails()"
                *ngIf="!projectapicall">Save</button>
        </div>
    </form>

</ng-template>

<!-- Upload Documents -->
<ng-template #UploadDocument let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Upload Document</h4>
        <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click') ; this.submitted = false ; resetBackendUploadDocumenterror()">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <form [formGroup]="AddDocumentForm">

        <div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreatDocumentsError">
                {{CreatDocumentsError}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="doctyoeerror">
                {{doctyoeerror}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="CreatDocumentsErrorDescription">{{CreatDocumentsErrorDescription}}</div>
        </div>

        <div class="modal-body">
            <div class="mb-3">
                <label for="">Document</label>
                <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                <input type="file" multiple accept=".pdf,.doc,.docx" placeholder="Degree is required"
                    max-file-size="10000000" formControlName="document" class="form-control"
                    (change)="attachFiles($event)"
                    [ngClass]="{ 'is-invalid': submitted && documentDetails['document'].errors }">
                <div style="color: red; font-size: 14px;"
                    *ngIf="submitted && documentDetails['document'] && documentDetails['document'].errors && documentDetails['document'].errors['required']">
                    Document is required.
                </div>

            </div>

         

            <div class="mb-3">
                <label for="">Description</label>
                <input type="text" placeholder="Enter description" formControlName="description" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && documentDetails['description'].errors }">

                <div style="color: red; font-size: 14px; text-align: left" class="w-100 col-12 ps-0"
                    *ngIf="submitted && (documentDetails['description'].errors?.['minlength'] || documentDetails['description'].errors?.['maxlength'])">
                    Description must be between 1 - 15 characters.
                </div>
             
                <small class="text-primary">Max 15 characters.</small>
            </div>

        </div>
        <div class="modal-footer">
            <div class="spinner-border" *ngIf="projectapicall">
                <span class="visually-hidden">Loading...</span>
            </div>
            <button type="button" class="filter"
                (click)="modal.close('Save click') ; this.submitted = false ; resetBackendUploadDocumenterror()"
                *ngIf="!projectapicall">Cancel</button>
            <button type="button" class="blue-btn ms-" (click)="UploadDocuments()" *ngIf="!projectapicall">Save</button>
        </div>
    </form>
</ng-template>

<!-- delete documents -->
<ng-template #deleteDocument let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Document</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="mb-3">
            <label for="">Do you want to Delete <strong>{{deleteName}}</strong> Document ?</label>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary cancelbutton"
            (click)="modal.close('Save click')">Cancel</button>
        <button type="button" class="btn btn-danger dangerbtn" (click)="deleteDocuments()">Delete</button>

    </div>
</ng-template>

<!-- Update Documents -->
<ng-template #EditDocument let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit Document</h4>
        <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click');this.submitted=false">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <form [formGroup]="AddDocumentForm">
        <div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="UpdateDocumentsError">
                {{UpdateDocumentsError}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="UpdateDocumentsErrorDocument">{{UpdateDocumentsErrorDocument}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="UpdateDocumentsErrorDescription">{{UpdateDocumentsErrorDescription}}</div>
        </div>
        <div class="modal-body">
            <div class="mb-3">
                <label for="">Document</label>
                <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                <input type="file" multiple accept=".pdf,.doc,.docx" max-file-size="10000000" formControlName="document"
                    class="form-control" (change)="attachFiles($event)"
                    [ngClass]="{ 'is-invalid': submitted && documentDetails['document'].errors }">
                <div style="color: red; font-size: 14px;"
                    *ngIf="submitted && documentDetails['document'] && documentDetails['document'].errors && documentDetails['document'].errors['required']">
                    Document is required.
                </div>
            </div>

            <div class="mb-3">
                <label for="">Description</label>
                <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                <input type="text" placeholder="Enter description" formControlName="description" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && documentDetails['description'].errors }">

                <div style="color: red; font-size: 14px; text-align: left" class="w-100 col-12 ps-0"
                    *ngIf="submitted && (documentDetails['description'].errors?.['minlength'] || documentDetails['description'].errors?.['maxlength'])">
                    Description must be between 1 - 15 characters.
                </div>

                <div style="color: red; font-size: 14px;"
                    *ngIf="submitted && documentDetails['description'].errors?.['required']">
                    Description is required.
                </div>

                <small class="text-primary">Max 15 characters.</small>
            </div>


        </div>
        <div class="modal-footer">
            <div class="spinner-border" *ngIf="projectapicall">
                <span class="visually-hidden">Loading...</span>
            </div>
            <button type="button" class="filter" (click)="modal.close('Save click');this.submitted=false"
                *ngIf="!projectapicall">Cancel</button>
            <button type="button" class="blue-btn ms-" (click)="UpdateDocuments()" *ngIf="!projectapicall">Save</button>
        </div>
    </form>
</ng-template>


<!--  -->
<ng-template #addURLModal let-modal>

    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">Add Linkedin URL</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <form [formGroup]="UpdateUrlForm">

        <!-- Show Error Msg  -->
        <div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
                *ngIf="addLinkedinURLError">
                {{addLinkedinURLError}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="linkedinurlError">
                {{linkedinurlError}}</div>


        </div>

        <div class="modal-body">

            <div class="row">

                <div *ngIf="!updateURLsuccessMsg" class="mb-6">
                    <label for="">Please provide your LinkedIn URL</label>
                    <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                    <input type="text" placeholder="Enter LinkedIn URL" formControlName="linkedinurl"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && validation['linkedinurl'].errors }">
                    <div style="color: red; font-size: 14px;"
                        *ngIf="submitted && validation['linkedinurl'] && validation['linkedinurl'].errors && validation['linkedinurl'].errors['required']">
                        Linkedin URL is required
                    </div>
                </div>


            </div>

        </div>

        <div class="modal-footer">
            <ng-container *ngIf="!updateURLsuccessMsg">
                <button type="button" class="filter" (click)="modal.close('Save click');">Cancel</button>
                <button type="button" class="blue-btn ms-" (click)="Updatesubmit()">Submit</button>
            </ng-container>
            <ng-container *ngIf="updateURLsuccessMsg">
                <button type="button" class="blue-btn ms-" (click)="modal.close('OK click')">OK</button>
            </ng-container>
        </div>

    </form>

</ng-template>



<ng-template #metrics>
    <div>
        Enable metrics to add servers, applications, plugins, and more. Gain a complete overview of your organization at
        a glance with our smart dashboard, providing a synopsis of your resources.
    </div>
</ng-template>




<ng-template #existing_job let-modal>
    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">Apply Job</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <form [formGroup]="jobapplyForm">

        <div class="modal-body">

            <div class="row">
                <p class="modal-title" id="modal-basic-title">Applying for <strong>'{{jobName}}' </strong> ,
                    {{ jobCity }}
                    {{ jobState }}
                </p>
            </div>

            <div *ngIf="!applysuccessMsg && showlinkedininput" class="mb-6 mt-2">
                <label for="">Please provide your LinkedIn URL</label>
                <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                <input type="text" placeholder="Enter LinkedIn URL" formControlName="linkedinurl" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && job['linkedinurl'].errors }">
                <div style="color: red; font-size: 14px;"
                    *ngIf="submitted && job['linkedinurl'] && job['linkedinurl'].errors && job['linkedinurl'].errors['required']">
                    Linkedin URL is required
                </div>
            </div>

            <form [formGroup]="AddDocumentForm">

                <div *ngIf="showuploadButton" class="mb-3 mt-2">
                    <label for="resume">Resume</label>
                    <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                    <input type="file" multiple accept=".pdf,.doc,.docx" placeholder="Degree is required"
                        max-file-size="10000000" formControlName="document" class="form-control"
                        (change)="attachFiles($event)"
                        [ngClass]="{ 'is-invalid': submitted && documentDetails['document'].errors }">
                    <div style="color: red; font-size: 14px;"
                        *ngIf="submitted && documentDetails['document'] && documentDetails['document'].errors && documentDetails['document'].errors['required']">
                        Document is required.
                    </div>
                </div>

            </form>

            <div *ngIf="applysuccessMsg" class="mb-6 mt-2">
                <div style="color: green; font-size: 14px;">
                    {{applysuccessMsg}}
                </div>
            </div>

        </div>

        <div class="modal-footer">
            <ng-container *ngIf="!applysuccessMsg">
                <button type="button" class="filter"
                    (click)="modal.close('Save click'); resetapplyerror()">Cancel</button>
                <button *ngIf="!showuploadButton" type="button" class="blue-btn ms-"
                    (click)="onapplysubmit()">Apply</button>
                <button *ngIf="showuploadButton" type="button" class="blue-btn ms-"
                    (click)="uploadresume()">Upload</button>
            </ng-container>
            <ng-container *ngIf="applysuccessMsg">
                <button type="button" class="blue-btn ms-"
                    (click)="modal.close('OK click');resetapplyerror()">OK</button>
            </ng-container>
        </div>

    </form>
</ng-template>