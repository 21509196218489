<div class="modal-header">
    <h4 class="modal-title">Session Idle</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>{{ message }}</p>
    <p>Time remaining: {{ countdown }} seconds</p>
</div>
<div class="modal-footer">
    <button type="button" class="blue-btn ms ng-star-inserted" (click)="closeModal()">Continue</button>
</div>