<div class="d-flex align-items-center">
    <h4 class="text-black fw-bold heading_main">Dashboard</h4>
    <!-- All admin can see the dropdown  -->
    <form [formGroup]='DashboardForm' style="margin-top: 8px;" *ngIf="currentUser.role.id==1">
        <ng-select [items]="userList" style="width: 250px;" class="form-control form-select" bindLabel="name"
            bindValue="id" formControlName="userId" placeholder="All Users" (clear)="getUserList()"
            (search)="getUserList($event)" (change)="getDashboardDetails()">
        </ng-select>
    </form>
</div>

<section *ngIf="is_client_module_access_disabled == '0' " id="main-content" class="d-inline-block bg-white my-3">

    <h5 class="text-black fw-bold heading_main">New Business Pipeline ({{totalClientCount}})</h5>
    <div class="row">

      

        <div class="col-12 col-lg-2" *ngFor="let bp of newBusinessPipeline">
            <div class="card pipeline">
                <div class="stat-widget-one" style="cursor: pointer;" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="stat-icon text-success border-success">{{bp.client_count}}</div>
                    <div class="stat-content dib">
                        <div class="dropdown d-inline-block">
                            <span>{{bp.name}}</span>
                            <ul class="dropdown-menu" *ngIf="bp.client_names"
                                style="max-height: 180px; overflow-y: auto;">
                                <ng-container *ngFor="let client of bp.client_names.split('#'); let i = index">
                                    <li class="contact-person-link dropdown-item ">
                                        <ng-container *ngIf="bp.candidate_ids.split(',')[i] !== '0'; else noLink">
                                            <a title="View Contact Person"
                                                style="cursor: pointer; text-decoration: none;"
                                                [routerLink]="['/candidate/', bp.candidate_ids.split(',')[i]]">
                                                {{client}}
                                            </a>
                                        </ng-container>
                                        <ng-template #noLink>
                                            <span title="No Contact Person Associated">{{client}}</span>
                                        </ng-template>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<section *ngIf="is_client_module_access_disabled == '0' " id="main-content" class="d-inline-block bg-white  my-3">
    <h5 class="text-black fw-bold heading_main">Client Expansion ({{totalclientExpansionCount}})</h5>
    <div class="row">

     

        <div class="col-12 col-lg-2" *ngFor="let ce of clientExpansion">
            <div class="card expansion">
                <div class="stat-widget-one" style="cursor: pointer;" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="stat-icon text-success border-success">{{ce.client_count}}</div>
                    <div class="stat-content dib">
                        <div class="dropdown d-inline-block">
                            <span>{{ce.name}}</span>
                            <ul class="dropdown-menu" *ngIf="ce.client_names"
                                style="max-height: 180px; overflow-y: auto;">
                                <ng-container *ngFor="let client of ce.client_names.split('#'); let i = index">
                                    <li class="contact-person-link dropdown-item">
                                        <ng-container *ngIf="ce.candidate_ids.split(',')[i] !== '0'; else noLink">
                                            <a title="View Contact Person"
                                                style="cursor: pointer; text-decoration: none;"
                                                [routerLink]="['/candidate/', ce.candidate_ids.split(',')[i]]">
                                                {{client}}
                                            </a>
                                        </ng-container>
                                        <ng-template #noLink>
                                            <span title="No Contact Person Associated">{{client}}</span>
                                        </ng-template>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>


<!-- NEW -->
<section *ngIf="is_client_module_access_disabled == '0' " id="main-content" class="d-inline-block bg-white my-3">
    <div class="row">

        <div class="col-12 col-lg-2" *ngFor="let bp of referenceable_clients">
            <div style="margin-top: 0px;cursor: pointer;" class="card h-100" data-bs-toggle="dropdown"
                aria-expanded="false">
                <div class="fw-bold d-flex flex-column justify-content-center align-items-center">
                    <p class="text-secondary fs-6 fw-bold text-center">Referenceable Client</p>
                    <span class="fs-2">{{bp.client_count}}</span>
                </div>
                <div class="stat-widget-one">
                    <div class="stat-content dib">
                        <div class="dropdown d-inline-block">
                            <ul class="dropdown-menu" *ngIf="bp.client_names"
                                style="max-height: 180px; overflow-y: auto;">
                                <ng-container *ngFor="let client of bp.client_names.split('#'); let i = index">
                                    <li class="contact-person-link dropdown-item ">
                                        <ng-container *ngIf="bp.candidate_ids.split(',')[i] !== '0'; else noLink">
                                            <a title="View Contact Person"
                                                style="cursor: pointer; text-decoration: none;"
                                                [routerLink]="['/candidate/', bp.candidate_ids.split(',')[i]]">{{client}}</a>
                                        </ng-container>
                                        <ng-template #noLink>
                                            <span title="No Contact Person Associated">{{client}}</span>
                                        </ng-template>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-3">
            <table class="table table-bordered h-100 shadow text-center">
                <thead>
                    <tr>
                        <th colspan="2" class="text-secondary fs-6 fw-bold m-0 text-center mb-0">Client Closed (won)
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Inception to date</td>
                        <td>Trailing 12 months</td>
                    </tr>
                    <tr>
                        <td>{{client_YTD}}</td>
                        <td>{{client_TTM}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</section>


<!-- Active Searches -->
<section id="main-content" class="d-inline-block bg-white my-3">
    <h5 class="text-black fw-bold heading_main mb-2">Active Searches ({{activeSearchesCount}})</h5>
    <div class="row">

        <div class="col-12 col-lg-2" *ngFor="let sc of statusWiseSearchesCount">
            <div class="card">
                <div class="fs-1 fw-bold d-flex flex-column justify-content-center align-items-center">
                    <p class="text-secondary fs-6 fw-bold m-0 text-center mb-0">{{sc.name}}</p>
                    <span class="stat-icon">{{(activeSearchesCount>0)?sc.status_count:0}}</span>
                </div>
            </div>
        </div>

    </div>
</section>

<!-- Last Card -->
<section id="main-content" class="d-inline-block bg-white my-3">
    <div class="row">

        <!-- Candidates Placed/Hired  -->
        <div class="col-12 col-lg-3">
            <table class="table table-bordered h-100 shadow text-center">
                <thead>
                    <tr>
                        <th colspan="2" class="text-secondary fs-6 fw-bold m-0 text-center mb-0"> Candidates
                            Placed/Hired
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Year to date</td>
                        <td>Trailing 12 months</td>
                    </tr>
                    <tr>
                        <td>{{candidate_YTD}}</td>
                        <td>{{candidate_TTM}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Ave. length of Search (Days)  -->
        <div class="col-12 col-lg-3">
            <table class="table table-bordered h-100 shadow text-center">
                <thead>
                    <tr>
                        <th colspan="2" class="text-secondary fs-6 fw-bold m-0 text-center mb-0">Ave. length of Search
                            (Days)
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Year to date</td>
                        <td>Trailing 12 months</td>
                    </tr>
                    <tr>
                        <td>{{AveLength_YTD}}</td>
                        <td>{{AveLength_TTM}}</td>
                    </tr>
                <tbody>
            </table>
        </div>

        <!-- Candidate Sources -->
        <div class="col-12 col-lg-3">
            <ng-container *ngIf="candidate_sources.length > 0; else noDataTemplate">
                <table class="table table-bordered h-100 shadow text-center">
                    <thead>
                        <tr>
                            <th class="text-secondary fs-6 fw-bold m-0 text-center mb-0">Candidate Sources
                            </th>
                            <th class="text-secondary fs-6 fw-bold m-0 text-center mb-0">No
                            </th>
                            <th class="text-secondary fs-6 fw-bold m-0 text-center mb-0">%
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let source of candidate_sources">
                            <td>{{ source.source }}</td>
                            <td>{{ source.no_of_candidates}}</td>
                            <td>{{ source.percentage }}</td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            <ng-template #noDataTemplate>
                <table class="simple-table">
                    <thead>
                      <tr>
                        <th scope="col">
                          <p class="text-secondary fs-6 fw-bold m-0 text-center mb-0">Candidate Sources</p>
                        </th>
                        <th scope="col">
                          <p class="text-secondary fs-6 fw-bold m-0 text-center mb-0">No</p>
                        </th>
                        <th scope="col">
                          <p class="text-secondary fs-6 fw-bold m-0 text-center mb-0">%</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colspan="3" class="text-center">No Data Found</td>
                      </tr>
                    </tbody>
                  </table>
                  
            </ng-template>
        </div>

    </div>
</section>



