import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';

// Validator to check if the date is in the future
export function futureDateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const today = new Date();
    const inputDate = new Date(control.value);
    return inputDate > today ? { 'futureDate': { value: control.value } } : null;
  };
}

// Validator to check if the end date is less than the start date
export function endDateValidator(startDateControlName: string, endDateControlName: string) {
    return (formGroup: FormGroup) => {
      const startDateControl = formGroup.controls[startDateControlName];
      const endDateControl = formGroup.controls[endDateControlName];
  
      if (endDateControl.errors && !endDateControl.errors['endDateLessThanStartDate']) {
        return;
      }
  
      if (startDateControl.value && endDateControl.value && new Date(endDateControl.value) < new Date(startDateControl.value)) {
        endDateControl.setErrors({ 'endDateLessThanStartDate': true });
      } else {
        endDateControl.setErrors(null);
      }
    };
  }