import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../common/storage.service';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService) { }

  create(data:any): Observable<any>{
    return this.httpClient.post(`${environment.apiUrl}candidates/`+data.id+`/notes`, data);
  }

  list(data:any): Observable<any>{
    return this.httpClient.post(`${environment.apiUrl}notes`, data);
  }

  deleteNote(candidateId:number, id:number): Observable<any>{
    return this.httpClient.delete(`${environment.apiUrl}candidates/`+candidateId+`/notes/`+id);
  }

  updateNote(candidateId:number,noteId:number,data:any): Observable<any>{
    return this.httpClient.put(`${environment.apiUrl}candidates/`+candidateId+"/notes/"+noteId, data);
  }

  listByFilter(data?: any): Observable<any>{
    let get_params:string = '';
    if(data.sort_field && data.sort_method){
      get_params += `sort_field=`+ data.sort_field + `&sort_method= `+ data.sort_method;
    }
    if(data.page){
      if(get_params != ''){
        get_params +=`&`
      }
      get_params += `page=`+ data.page ;
    }
    if(data.search){
      if(get_params != ''){
        get_params +=`&`
      }
      get_params += `search=`+ data.search;
    }
    if(data.project_id>=0){
      if(get_params != ''){
        get_params +=`&`
      }
      get_params += `project_id=`+ data.project_id;
    }
    
    if(data.id)
      return this.httpClient.get(`${environment.apiUrl}candidates/`+data.id+`/notes?`+get_params);
    else
      return this.httpClient.get(`${environment.apiUrl}notes?`+get_params);
  }

}