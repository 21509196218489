

<header class="job-head-wrapper custom-header {{(!production_environment) ? 'development-env' : ''}} {{(uat_environment) ? 'uat-env' : ''}}">


    <div class="left-header-wrap">
        <!-- Logo Section -->

        <a href="https://tatracker.cloud" target="_blank">
            <img src="./assets/img/TATlogo.PNG" alt="TATracker Logo" height="40">
        </a>


        <!-- TATracker Heading -->
        <a href="https://tatracker.cloud" target="_blank" class="me-3 text-decoration-none text-white">
            <h3>TATracker</h3>
        </a>

        <!-- Profile and Navigation Links -->

        <h6 class="me-3 headers" [ngClass]="{'active': isActiveTab('/candidate-profile')}">
            <a style="color: white; text-decoration: none;" [routerLink]="['/candidate-profile']">
                <span [ngClass]="{'bold-text': isActiveTab('/candidate-profile')}">My Profile</span>
            </a>
        </h6>

        <h6 class="headers" [ngClass]="{'active': isActiveTab('/jobslist')}">
            <a style="color: white; text-decoration: none;" [routerLink]="['/jobslist']">
                <span [ngClass]="{'bold-text': isActiveTab('/jobslist')}">All Jobs</span>
            </a>
        </h6>

    </div>
    <!-- Right Header Section -->
    <div class="ms-auto">
        <div class="dropdown mx-3">
            <button class="profile-name" type="button" data-bs-toggle="dropdown" aria-expanded="false" style="padding: 0px;">
          <span *ngIf="imgSrc !== null" style="height:35px;width:35px;border-radius: 50px;">
            <img [src]="imgSrc" alt="" style="height:35px;width:35px;border-radius: 50px;margin-bottom: 3px;" />
          </span>
          <span *ngIf="imgSrc === null" style="height:35px;width:35px;border-radius: 50px;margin-bottom: 3px;padding-left: 0px;">
            {{userinitials}}
          </span>
        </button>
            <ul class="dropdown-menu">
                <li><a class="dropdown-item" (click)="open(EditProfile, 'EditProfile')">Edit Login Details</a></li>
                <li><a class="dropdown-item" (click)="logout()">Logout</a></li>
            </ul>
        </div>
    </div>

</header>




<ng-template #EditProfile let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit Login Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click') ;clearall()">
      <span aria-hidden="true">×</span>
    </button>
    </div>
    <form [formGroup]="CreateUserForm">

        <div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserError">
                {{createUserError}}</div>
            <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorName">
                {{createUserErrorName}}</div>
            <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorPassword">
                {{createUserErrorPassword}}</div>
        </div>
        <div class="modal-body">

            <div class="row">

                <!-- STEP 1st TO SEND 6 Digit OTP -->
                <div *ngIf="step == '1'">

                    <div>
                        <div style="padding:10px; font-size: 14px;padding-left: 1px;" class="text-danger" *ngIf="emailError">
                            {{emailError}}</div>
                    </div>


                    <div class="col-12">
                        <div class="mb-2 password-container">
                            <label for="">Email</label>
                            <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                            <div class="d-flex align-items-center">
                                <input type="text" placeholder="Enter Email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }">&nbsp;
                                <button type="button" class="blue-btn btn-sm ml-2" (click)="editEmail()">Edit</button>
                            </div>
                            <div style="color: red; font-size: 14px;" *ngIf="submitted && f['email'] && f['email'].errors && f['email'].errors['required']">
                                Email is required
                            </div>
                        </div>
                    </div>

                    <div *ngIf="showPasswordinput" class="col-md-6">
                        <div class="mb-3 password-container">
                            <label for="" >Password</label>
                            <input type="password" placeholder="Enter password" formControlName="password" class="form-control" *ngIf="!showPassword">
                            <input type="text" placeholder="Enter password" formControlName="password" class="form-control" *ngIf="showPassword">
                            <em class="fa fa-eye" (click)="togglePassword()" *ngIf="showPassword" style="top:47%"></em>
                            <em class="fa fa-eye-slash" (click)="togglePassword()" *ngIf="!showPassword" style="top:47%"></em>
                            <small class="text-primary">Leave this field empty unless you want to change it.</small>
                        </div>
                    </div>

                </div>


                <div *ngIf="step == '2'">

                    <div>
                        <div style="padding:10px; font-size: 14px;padding-left: 1px;" class="text-success" *ngIf="optsuccesmsg">
                            {{optsuccesmsg}}</div>
                        <div style="padding:10px; font-size: 14px;padding-left: 1px;" class="text-danger" *ngIf="otpError">
                            {{otpError }}</div>
                    </div>

                   

                </div>


                <div *ngIf="step == '3'">
                    <div>
                        <div style="padding:10px; font-size: 14px;padding-left: 1px;" class="text-success" *ngIf="verifyemail">
                            {{verifyemail}}</div>
                    </div>
                </div>
            </div>

        </div>

        <div class="modal-footer">
            <div class="spinner-border" *ngIf="projectapicall">
                <span class="visually-hidden">Loading...</span>
            </div>
            <button type="button" class="filter" (click)="modal.close('Save click') ; clearall()" *ngIf="!projectapicall">Cancel</button>
            <button *ngIf="!showsubmitButton" type="button" class="blue-btn ms" (click)="UpdateProfiles()">Save</button>
           
            <button *ngIf="showsubmitButton && step !== '2' && step !== '3'" type="button" class="blue-btn ms" (click)="submitforOTP()">Submit</button>
            <button *ngIf="step === '2'" type="button" class="blue-btn ms" (click)="closed()">Ok</button>

        </div>
    </form>
</ng-template>