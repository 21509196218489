import { trigger, transition, style, animate } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StorageKey, StorageService } from 'src/app/core/common/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-app-nav',
  templateUrl: './app-nav.component.html',
  styleUrls: ['./app-nav.component.css'],
  animations: [
    trigger('slidein', [
      transition(':enter', [
        // when ngif has true
        style({ transform: 'translateX(-100%)' }),
        animate(250, style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        // when ngIf has false
        animate(250, style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]

})

export class AppNavComponent {
  expanded: boolean = true;
  @Output() isOpen = new EventEmitter<boolean | any>();
  userRole: any;
  userRoleshow: boolean = false;
  tenantRole: any;
  tenantRoleshow: boolean = false;
  tenantRoleNotshow: boolean = false;
  is_client_module_access_disabled: string | null = "0";
  is_public_search: string | null = "0";
  isSuperAdmin: string | null = "0";

  constructor(private storageService: StorageService, private router: Router) {

  }

  ngOnInit(): void {
    let currentUserrole = this.storageService.getValue(StorageKey.currentUserRoleId);
    this.userRole = currentUserrole;
    // console.log(this.userRole, "Current User Role")
    if (this.userRole == 1) {
      this.userRoleshow = true;
    } else if (this.userRole == 2) {
      this.userRoleshow = false;
    }


    let currentTenantrole = this.storageService.getValue(StorageKey.currentTenantId);
    this.tenantRole = currentTenantrole;
    // console.log(this.tenantRole, "Side Bar")
    if (this.tenantRole == 0) {
      this.tenantRoleshow = true;
      this.tenantRoleNotshow = false;
      // console.log("role is 0")
    } else if (this.tenantRole != 0) {
      this.tenantRoleshow = false;
      this.tenantRoleNotshow = true;
      // console.log("role is Not 0")
    }

    this.is_client_module_access_disabled = this.storageService.getValue(StorageKey.is_client_module_access_disabled);
    this.is_public_search = this.storageService.getValue(StorageKey.is_public_search);
    this.isSuperAdmin = localStorage.getItem('superadminflag');

  }
  toggleMenu() {
    this.isOpen.emit(this.isOpen);
  }


  handleClick() {
    localStorage.setItem('page_name', "Job Posting");
  }

  Searches(){
    localStorage.setItem('page_name', "Searches");
  }

}
