<div class="container-fluid">
  <div class="row custom-section vh-100">
    <div class="{{(isOpen)?'col-1':'col-2 showicon'}} navbar navbar-expand-xxl navbar-light">
      <button style="display: flex; flex-wrap: nowrap; align-items: center;" type="button" class="sidelogobar"
        aria-label="Toggle navigation" (click)="toggleMenu()" data-bs-toggle="tooltip" data-bs-placement="top"
        title="Main Menu">
        <img class="sidebar-logo" style=" display: inline-block; vertical-align: middle;width: 55px;height: 34px;"
          [src]="brand_logo1" alt="Brand Logo">
        <span class="nav-item" style="min-width: 100px;margin: 0; display: inline-block; vertical-align: middle; ">
          <span class="tat_heading" style="font-weight: 700; font-size: 18px;color: #000;">TATracker</span>
        </span>
        <em class="bx bx-arrow-from-right logo-name__icon" id="logo-name__icon"></em>
      </button>
      <app-app-nav></app-app-nav>
    </div>
    <div id="content" class="{{(isOpen)?'col-11':'col-10'}} bg-right" [ngClass]="isOpen ? 'active' : ''">
      <app-app-header></app-app-header>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>