<div class="bg-right">
  <div class="top-row d-flex justify-content-between align-items-end">
    <div class="top-left-details w-50 d-flex align-middle">

      <div class="top_right_profile d-inline-block">
     
        <!-- Search -->
        <h3 *ngIf="pageTitle !== 'Job Posting'" class="d-inline-block align-middle mb-1 m-0">Search:
          {{projectDetail?.fullName}}</h3> &nbsp;
        <!-- Job Posting -->
        <h3 *ngIf="pageTitle === 'Job Posting'" class="d-inline-block align-middle mb-1 m-0">Job Posting:
          {{projectDetail?.fullName}}</h3> &nbsp;

      
        <a *ngIf="pageTitle !== 'Job Posting'" class="filter me-3" (click)="openEditSerch(UpdateProject,searchInfoid)"
          style="margin-right: 0px !important;">Edit
          Search</a>
        
        <a *ngIf="pageTitle === 'Job Posting'" class="filter me-3" (click)="openEditSerch(UpdateProject,searchInfoid)"
          style="margin-right: 0px !important;">Edit
          Job Posting</a>
      
        <p *ngIf="projectDetail?.client?.name">
          {{projectDetail?.city}} | Start: {{projectDetail?.official_start_date|date:'MM/dd/yyyy'}} - End:
          {{projectDetail.official_end_date|date:'MM/dd/yyyy'}}</p>
          
        <div class="custom-row">
          <h3 class="d-inline-block align-middle m-0">Candidates</h3>
          <div class="d-inline-block align-middle m-0 ps-3 pt-1" style="cursor:pointer;" title="reset"
            (click)="onRefreshClick()"><em class="fa fa-refresh"></em></div>
        

          <a href="javascript:void(0)" class="radiusbtn"
            (click)="open(CreateCandidates,'CreateCandidates')">＋ Candidate
          </a>


          <a *ngIf="pageTitle !== 'Job Posting'" href="javascript:void(0)" (click)="open(ExportDocument,'ExportDocument')"
            class="radiusbtn">Export</a>
          
          <a *ngIf="pageTitle === 'Job Posting'" href="javascript:void(0)" (click)="open(ExportDocument,'ExportDocument')"
            class="radiusbtn">Report</a>

        </div>
      </div>


    </div>

    <div class="top-right-details top-right-sec w-50 text-end">
      <div class="custom-row" style="margin-bottom: 20px;  position: relative;">
        <button class="profile-name" type="button" data-bs-toggle="dropdown"
          (click)="open(addUserToSearch,'addUserToSearch')" aria-expanded="false"
          style="padding: 0px;margin-right: 3px;background: #63c9c1;">
    
          <span> <a class="plus" data-bs-toggle="dropdown" aria-expanded="false">
              <em class="fa fa-plus" style="color: white;padding-top: 3px;font-weight: 100;"></em>
            </a></span>
     
        </button>
        <span *ngFor="let user of searchUserList; index as i">
        <button  *ngIf="i<3"class="profile-name"  type="button" data-bs-toggle="dropdown" aria-expanded="false" style="padding: 0px;">
          <span style="height:35px;width:35px;border-radius: 50px;" *ngIf="user.avatar_path != null">
            <img [src]="user.avatar_path" alt="" style="height:35px;width:35px;border-radius: 50px;margin-bottom: 3px;" />
          </span>
          <span *ngIf="(user.avatar_path == null) " style="height:35px;width:35px;border-radius: 50px;margin-bottom: 3px;padding-left: 0px;" >{{getInitials(user.name)}}</span>
      </button>
    </span>
     
  <button *ngIf="searchUserList.length > 3" class="profile-name" type="button" data-bs-toggle="dropdown" aria-expanded="false" style="padding: 0px;background: white;border:1px solid 343a40 !important;">
  
    <span  style="height:35px;width:35px;border-radius: 50px;margin-bottom: 3px;padding-left: 0px;color: #343a40;">+{{searchUserList.length-3}}</span>
  </button>

      </div>

    

      <div class="custom-row" style=" position: relative;" >
        <div style="display:flex !important;float: right !important;">
          <div _ngcontent-ng-c3929272049="" class="input-group small-search me-3 " style="width: 200px;"><input _ngcontent-ng-c3929272049="" type="text"
            placeholder="Search" tabindex="-1" aria-label="Search" aria-describedby="basic-addon2" class="form-control pt-1 pb-1"
            (keyup.enter)="onSearchClick(inputsearch.value)" 
            value="{{searchvalue}}" #inputsearch  >
          <div _ngcontent-ng-c3929272049="" class="input-group-append rounded">
            <button _ngcontent-ng-c3929272049="" type="button" class="btn"><img _ngcontent-ng-c3929272049=""
                src="./assets/img/search-black.svg" alt=""></button>
          </div>
        </div> 
        <a class="filter me-3" (click)="showFilter()" >Filter</a>
        </div >
      </div>
   

    </div>
  </div>
  <form [formGroup]="FilterForm" *ngIf="showFilterOptions">
    <div class="row mb-3 filter-open">
      <div class="col-2">
        <select  class="form-select" formControlName="f_candidate_status_id">
          <option value="null" disabled>Select status</option>
          <option  *ngFor="let status of candidateStatus" [value]="status.id">{{status.name}}</option>
        </select>
      </div>
      <div class="col-2">
        <div class="dropdown">
          <button class="btn btn-secondary custom-d w-100 bg-white border-0 dropdown-toggle text-start" type="button" id="dropdownMenuButto"
            data-bs-toggle="dropdown" aria-expanded="false">
            <span  class="strength" *ngIf="!imageselected">Select strength</span>
            <img *ngIf="imageselected" src="{{imagepath}}" alt="" />
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li (click)="showfilterimage('./assets/img/1.png','1')"><a class="dropdown-item"><img src="./assets/img/1.png"
                  alt="" /></a></li>
            <li (click)="showfilterimage('./assets/img/2.png','2')"><a class="dropdown-item"><img src="./assets/img/2.png"
                  alt="" /></a></li>
            <li (click)="showfilterimage('./assets/img/3.png','3')"><a class="dropdown-item"><img src="./assets/img/3.png"
                  alt="" /></a></li>
            <li (click)="showfilterimage('./assets/img/4.png','4')"><a class="dropdown-item"><img src="./assets/img/4.png"
                  alt="" /></a></li>
            <li (click)="showfilterimage('./assets/img/5.png','5')"><a class="dropdown-item"><img src="./assets/img/5.png"
                  alt="" /></a></li>
          </ul>
        </div>
      </div>
      <div class="col-2">
        <select name="" id="" class="form-select" formControlName="f_gender">
          <option disabled value="null">Select gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </select>
      </div>
      <div class="col-2">
        <ng-select [items]="diverseList" class="form-control form-select " placeholder="Diverse candidate"
        bindLabel="name" formControlName="f_diversity" bindValue="value">
        </ng-select >
      </div>

      <div class="col-2">
        <input type="text" formControlName="f_compensation" class="form-control" placeholder="Compensation">
      </div>




      <div class="col-2">
        <button type="button" class="filter"
          (click)="clearFilterForm();">Clear</button>
        <button type="button" class="blue-btn ms-2" (click)="applyFilter()">Apply</button>
      </div>
    </div>


  </form>


  <div class="row details-bottom-table producttable">
    <div class="col-8">
      <div class="bottom-table mt-0">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">
                <div>
                    Select 
                </div>
              </th>
              <th scope="col">
                    <div>
                        Rank 
                    </div>
                </th>

                <th scope="col" style="width:20%" (mouseenter)="mouseEnter('in_last_name')" (mouseleave)="mouseLeave('in_last_name')">
                  Name
                  <span *ngIf="currentSortedColumn != 'in_last_name' && mouseOnColumnName == 'in_last_name'" (click)="getCandidateList({sort_field:'in_last_name',sort_method:'desc',column_name:'in_last_name'})" class="sortbyspanr"
                    [hidden]="!iconVisible ">
                    <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
                  </span>
                  <span class="sortbyspanr" *ngIf="currentSortedColumn == 'in_last_name'" [hidden]="!iconVisibleDesc" (click)="getCandidateList({sort_field:'in_last_name',sort_method:'asc',column_name:'in_last_name'})">
                    <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
                  </span>
                  <span class="sortbyspanl"  *ngIf="currentSortedColumn == 'in_last_name'" [hidden]="!iconVisibleAsc" (click)="getCandidateList({sort_field:'in_last_name',sort_method:'desc',column_name:'in_last_name'})">
                    <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
                  </span>
                </th>

                <th scope="col" style="width:20%" (mouseenter)="mouseEnter('status')" (mouseleave)="mouseLeave('status')">
                  Status
                  <span *ngIf="currentSortedColumn != 'status' && mouseOnColumnName == 'status'" (click)="getCandidateList({sort_field:'status',sort_method:'desc',column_name:'status'})" class="sortbyspanr"
                    [hidden]="!iconVisible ">
                    <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
                  </span>
                  <span class="sortbyspanr" *ngIf="currentSortedColumn == 'status'" [hidden]="!iconVisibleDesc" (click)="getCandidateList({sort_field:'status',sort_method:'asc',column_name:'status'})">
                    <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
                  </span>
                  <span class="sortbyspanl"  *ngIf="currentSortedColumn == 'status'" [hidden]="!iconVisibleAsc" (click)="getCandidateList({sort_field:'status',sort_method:'desc',column_name:'status'})">
                    <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
                  </span>
                </th>

                <th scope="col" style="width:20%" (mouseenter)="mouseEnter('strength')" (mouseleave)="mouseLeave('strength')">
                  Strength
                  <span *ngIf="currentSortedColumn != 'strength' && mouseOnColumnName == 'strength'" (click)="getCandidateList({sort_field:'strength',sort_method:'desc',column_name:'strength'})" class="sortbyspanr"
                    [hidden]="!iconVisible ">
                    <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
                  </span>
                  <span class="sortbyspanr" *ngIf="currentSortedColumn == 'strength'" [hidden]="!iconVisibleDesc" (click)="getCandidateList({sort_field:'strength',sort_method:'asc',column_name:'strength'})">
                    <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
                  </span>
                  <span class="sortbyspanl"  *ngIf="currentSortedColumn == 'strength'" [hidden]="!iconVisibleAsc" (click)="getCandidateList({sort_field:'strength',sort_method:'desc',column_name:'strength'})">
                    <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
                  </span>
                </th>

            
              <th *ngIf="pageTitle !== 'Job Posting'" scope="col">Export</th>
              <th *ngIf="pageTitle === 'Job Posting'" scope="col">Report</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>

            <tr *ngIf="candidateList.length ==0">
              <td colspan="7" style="text-align: center;"> No records found</td>
            </tr>
            <tr>
              <tr *ngFor="let cl of candidateList;let i=index" [ngClass]="{'active': cl.checked}" >
                <td style="width:5% " >
                  <input type="radio"  name="rank" [checked]="cl.checked" (change)="onCandidateSelected(cl)">
                </td>
                <td style="width:10%">
                <div class="form-check d-flex align-items-center">                 
                  <input name="candidateRank_{{cl.id}}"  id="candidateRank_{{cl.id}}" (blur)="candidatechange(cl.id,'candidateRank')"  type="text" (keypress)="restrictAlphabet($event)" class="form-control inputrank" aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-sm" min="1" value="{{cl.rank}}">
                
                </div>
              </td>
              <td style="width:30%" (click)="showCandidate(cl.id)">
                <div class="form-check">
                  <label class="form-check-label" for="flexCheckDefault">
                    <div  class="top-left-details w-60 d-flex align-middle">
                      <div  class="top_profile">
                        <img src="{{ cl?.in_profile_pic_url }}" alt="" />
                      </div>
                        <div class="top_right_profile ps-3 d-inline-block">
                          <p><span style="padding-left:0px;" > {{cl?.in_first_name}} {{cl?.in_last_name}}</span></p>
                       </div>
                    </div>

                  </label>
                </div>
              </td>
              <td style="width:25%">
                <div class="form-check pl-0">
                  <select  class="custom-dropdown form-select" [(ngModel)]="cl.candidate_status_id" (change)="candidatechange(cl.id,'candidateStatus',cl.candidate_status_id)">
                    <option value="null">Select status</option>
                    <option  *ngFor="let status of candidateStatus" id="projectStatus_{{cl.candidate_status_id}}" [value]="status.id">{{status.name}}</option>
                  </select>
                </div>
              </td>
              <td style="width:20%">
                <div class="dropdown">
                  <button class="btn btn-secondary bg-white border-0 dropdown-toggle" type="button"
                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <span  class="strength" *ngIf="!cl.strength">Select strength</span>
                    <img *ngIf="cl.strength" src="{{'./assets/img/'+cl.strength+'.png'}}" alt="" />
                  </button>
                  <!-- './assets/img/'+project.strength+'.png' -->
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li (click)="showimage('./assets/img/1.png',1,cl.id,'candidate_strength')"><a class="dropdown-item"><img
                          src="./assets/img/1.png" alt="" /></a></li>
                    <li (click)="showimage('./assets/img/2.png',2,cl.id,'candidate_strength')"><a class="dropdown-item"><img
                          src="./assets/img/2.png" alt=""/></a></li>
                    <li (click)="showimage('./assets/img/3.png',3,cl.id,'candidate_strength')"><a class="dropdown-item"><img
                          src="./assets/img/3.png" alt=""/></a></li>
                    <li (click)="showimage('./assets/img/4.png',4,cl.id,'candidate_strength')"><a class="dropdown-item"><img
                          src="./assets/img/4.png" alt=""/></a></li>
                    <li (click)="showimage('./assets/img/5.png',5,cl.id,'candidate_strength')"><a class="dropdown-item"><img
                          src="./assets/img/5.png" alt=""/></a></li>
                  </ul>
                </div>
              </td>

              <!-- Export -->
              <td *ngIf="pageTitle !== 'Job Posting'" style="width:5%">
                <div class="d-flex align-items-center">
                  <div class="form-check form-switch mr-4 mb-0">
                    <input class="form-check-input" (change)="allowExportchecked($event,cl.id,'allow_export')"
                      style="cursor:pointer" title="Enable export" type="checkbox" id="flexSwitchCheckChecked"
                      [checked]="cl.allow_export">
                  </div>
                </div>
              </td>

              <!-- Report -->
              <td *ngIf="pageTitle === 'Job Posting'" style="width:5%">
                <div class="d-flex align-items-center">
                  <div class="form-check form-switch mr-4 mb-0">
                    <input class="form-check-input" (change)="allowExportchecked($event,cl.id,'allow_export')"
                      style="cursor:pointer" title="Enable export" type="checkbox" id="flexSwitchCheckChecked"
                      [checked]="cl.allow_export">
                  </div>
                </div>
              </td>

              <td style="width:5% ; text-align: center;">
                <a href="javascript:void(0)" (click)="open(deleteCandidate,'deleteCandidate',cl.id,cl.in_first_name+' '+cl.in_last_name)"> 
                  <em class="bi bi-trash" ></em></a>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
      </div>
      <div class="accordion col-4" id="accordionPanelsStayOpenExample-three">
        <div class="accordion-item mb-3 rounded overflow-hidden">
          <h2 class="accordion-header position-relative" id="panelsStayOpen-heading-three-one">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse-three-one" aria-expanded="true" aria-controls="panelsStayOpen-collapse-three-one">
           <span *ngIf="!CandidateName">Notes</span><span *ngIf="CandidateName" style="width: 320px;">  Notes {{"for" +" "+ CandidateName}}</span>
            </button>
            <a href="javascript:void(0)" class="edit" (click)="open(createNote,'createNote',undefined,undefined,'xl')"> 
              <img src="./assets/img/plus-black.svg" alt=""></a>
          </h2>
          <div id="panelsStayOpen-collapse-three-one" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-heading-three-one">
            <div class="accordion-body notescroll">
              <form [formGroup]="CreateNoteForm" #formDirective="ngForm">
              </form>
              <div class="border rounded py-3 px-2  mt-2" *ngFor="let nl of notesList">
                <div class="d-flex justify-content-between mb-3 ">
                    <div class="sub-profile d-flex align-items-center">
                    <button class="profile-name"  type="button" data-bs-toggle="dropdown" aria-expanded="false" style="padding: 0px;">
                      <span  style="height:35px;width:35px;border-radius: 50px;"  *ngIf="nl?.creator?.avatar_path != null"  >
                        <img src="{{nl?.creator?.avatar_path}}" alt=""   style="height:35px;width:35px;border-radius: 50px;margin-bottom: 3px;">
                      </span>
                      <span *ngIf="nl?.creator?.avatar_path == null" style="height:35px;width:35px;border-radius: 50px;margin-bottom: 3px;padding-left: 0px;">{{nl?.initials}}</span>
                  </button>
                        <h5>{{nl.creator?.name?nl.creator?.name:""}} <br>
                          <p class="online m-0">{{nl?.updated_at | date: "MM-dd-yyyy"}} at {{nl?.updated_at | date: "hh:mm a"}}</p>
                        </h5>
                    </div>
                    <div class="inner-edit">
                      <em class="bi bi-share-fill me-2" *ngIf="nl.allow_export"></em>
                      <em *ngIf="nl.ms_mail_id" class="bi bi-envelope me-2"></em>
                      <a href="javascript:void(0)" (click)="open(editNote,'editNote',nl.id,nl.project.name,'xl')"><em class="bi bi-pencil-square me-2"></em></a>
                     <a href="javascript:void(0)" (click)="open(deleteNote,'deleteNote',nl.id,nl.project.name)"> <em class="bi bi-trash" ></em></a>
                     
                    </div>
                    
                </div>
                <p class="m-0" style="white-space: pre-line">{{nl?.note}}</p>
              </div>

              <div class="accordion-body" *ngIf="notesList.length==0" style="text-align:center;">No notes added</div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pagination-main col-8 mb-4">
    <div class="row">
      
        <div class="col-4 my-auto">
           <label for="" class="list-totalrecords">Total Records: {{totalRows}}</label>
        </div>

        <div class="col-8 " style="position: relative;">
    <ul style="position: absolute;
        right: 0;" *ngIf="pager.pages && pager.pages.length && totalRows > pageSize" class="pagination me-0">
    
      <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
        <a *ngIf="pager.currentPage === 1; else elseFirst" class="page-link">
          <<</a>
            <ng-template #elseFirst>
              <a (click)="setPage(1)" class="page-link">
                <<</a>
            </ng-template>
      </li>
      <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
        <a *ngIf="pager.currentPage === 1; else elsePrevious" class="page-link">
          <</a>
            <ng-template #elsePrevious>
              <a (click)="setPage(pager.currentPage - 1)" class="page-link">
                <</a>
            </ng-template>
      </li>
   
      <li class="page-item ">
        <a class="page-link">{{pager.currentPage}}</a>
    </li>
      <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a *ngIf="pager.currentPage === pager.totalPages; else elseNext" class="page-link">></a>
        <ng-template #elseNext>
          <a (click)="setPage(pager.currentPage + 1)" class="page-link">></a>
        </ng-template>
      </li>
      <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a *ngIf="pager.currentPage === pager.totalPages; else elseLast" class="page-link">>></a>
        <ng-template #elseLast>
          <a (click)="setPage(pager.totalPages)" class="page-link">>></a>
        </ng-template>
      </li>
    </ul>
        </div>

    </div>
  </div>
</div>




<ng-template #createNote let-modal> 
  <div class="modal-header">
    <h4 class="modal-title flex-grow-1" id="modal-basic-title">Add Notes for {{CandidateName}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click') ;this.submitted = false ; resetBackendNoteerror()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="CreateNoteForm" #formDirective="ngForm">
    <div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createNoteError">{{createNoteError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createNoteErrorNotes">{{createNoteErrorNotes}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createNoteErrorAllowexport">{{createNoteErrorAllowexport}}</div>
    </div>
    <div class="modal-body">
     
    <div class="row">  
      <div class="col-12">
        <div class="mb-3">
          <label for="">Notes</label>
          <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
          <textarea  class="form-control" formControlName="notes"  placeholder="Start typing new note" aria-label="Start typing new note" aria-describedby="button-add" [ngClass]="{ 'is-invalid': submitted && createNoteForm['notes_project'].errors }" rows="8"> </textarea>
          <span style="color: red; font-size: 14px;text-align: left;" class="w-100 col-12 ps-0" *ngIf="submitted && createNoteForm['notes'] && createNoteForm['notes'].errors && createNoteForm['notes'].errors['required']">
            Note is required
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
      <div class="form-check">
      <input class="form-check-input" type="checkbox" formControlName="allow_export" >
      <label class="form-check-label" for="allow_export">
          &nbsp;Export
      </label>
    </div>
    </div>
    </div>
  </div>
 
  <div class="modal-footer">
    <div class="spinner-border" *ngIf="projectapicall">
      <span class="visually-hidden">Loading...</span>
    </div>
    <button type="button" class="filter" (click)="modal.close('Save click')" *ngIf="!projectapicall">Cancel</button>
    <button type="button" class="blue-btn ms-" (click)="addNote()" >Save</button>

  </div>
</form>
</ng-template> 

<ng-template #deleteNote let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Note</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="mb-3">
      <label for="">Do you want to delete note ?</label>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="filter" (click)="modal.close('Save click')">Cancel</button>
    <button type="button" class="blue-btn ms-2" (click)="deleteNoteDetail()">Delete</button>
  </div>
</ng-template>


 <ng-template #editNote let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Notes for {{CandidateName}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <form [formGroup]="UpdateNoteForm">
    <div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="updateNoteError">{{updateNoteError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="updateNoteErrorNotes">{{updateNoteErrorNotes}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="updateNoteErrorAllowexport">{{updateNoteErrorAllowexport}}</div>
    </div>
    <div class="modal-body">
      
      <div class="row">
        <div class="col-12">
          <div class="mb-3">
            <label for="">Notes</label>
            <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
            <textarea class="form-control" formControlName="notes" placeholder="Start typing new note" rows="8"
              aria-label="Start typing new note" aria-describedby="button-add"
              [ngClass]="{ 'is-invalid': submitted && updateNoteForm['notes'].errors }"> </textarea>
            <span style="color: red; font-size: 14px;text-align: left;" class="w-100 col-12 ps-0"
              *ngIf="submitted && updateNoteForm['notes'] && updateNoteForm['notes'].errors && updateNoteForm['notes'].errors['required']">
              Note is required
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" formControlName="allow_export">
            <label class="form-check-label" for="allow_export">
              &nbsp;Export
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-success" *ngIf="isEmailNotes">Notes taken from email</div>
      </div>
    </div>


    <div class="modal-footer">

      <div class="spinner-border" *ngIf="projectapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <input type="hidden" formControlName="notes_id">
      <button type="button" class="filter" (click)="modal.close('Save click')" *ngIf="!projectapicall">Cancel</button>
      <button type="button" class="blue-btn ms-" (click)="updateNoteDetail()"  *ngIf="!projectapicall">Save</button>
    </div>
  </form>
</ng-template> 

<ng-template #CreateCandidates let-modal class="my-modal">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Candidate</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');this.CreateCandidateForm.reset();this.eitherSelectError=false ;this.submitted = false ; resetBackendCandidateerror()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  
  <form [formGroup]="CreateCandidateForm">
    <div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createCandidateError">{{createCandidateError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createCandidateErrorUrl">{{createCandidateErrorUrl}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createCandidateErrorProjectId">{{createCandidateErrorProjectId}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="eitherSelectError">Please either select candidate or enter LinkedIn URL</div>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <div class="form-check form-check-inline" >
          <input class="form-check-input" type="radio" (click)="candidateTypeChange(1)"  name="candidate_type" id="existedCandidate" value="1" checked >
          <label class="form-check-label" for="existedCandidate" (click)="candidateTypeChange(1)" >
            From Candidates
          </label>
        </div>
        <div class="form-check form-check-inline" >
          <input class="form-check-input" type="radio" (click)="candidateTypeChange(2)"  name="candidate_type" id="linkedIn" value="2" >
          <label class="form-check-label" for="linkedIn" (click)="candidateTypeChange(2)" >
            From LinkedIn
          </label>
        </div>
        <div class="form-check form-check-inline" >
          <input class="form-check-input" type="radio" (click)="candidateTypeChange(3)" name="candidate_type" id="manually" value="3" >
          <label class="form-check-label" for="manually" (click)="candidateTypeChange(3)" >
            Manually
          </label>
        </div>
      </div>
      <div class="mb-3" *ngIf="candidateTypeSelected == 1">
        <label for="linkedin">Candidate</label>
        <ng-select [items]="candidateData" class="form-control form-select " placeholder="Select Candidate" bindLabel="name"
          bindValue="id" formControlName="candidateId" (search)="onCandidateSearch($event)"
          [ngClass]="{ 'is-invalid': submitted && createCandidateForm['candidateId'].errors }">
        </ng-select>
      </div>
     
      <div class="mb-3" *ngIf="candidateTypeSelected == 2">
        <label for="linkedin">LinkedIn URL</label>
        <input type="text" placeholder="Enter LinkedIn URL" id="linkedin" formControlName="linkedInUrl"
          class="form-control" [ngClass]="{ 'is-invalid': submitted && createCandidateForm['linkedInUrl'].errors }">
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && createCandidateForm['linkedInUrl'] && createCandidateForm['linkedInUrl'].errors && createCandidateForm['linkedInUrl'].errors['required']">
          Linkedin URL is required
        </div>
      </div>
      <div class="mb-3" *ngIf="candidateTypeSelected == 2 || candidateTypeSelected == 1">

        <label *ngIf="pageTitle !== 'Job Posting'" for="project_main">Search</label>
        <label *ngIf="pageTitle === 'Job Posting'" for="project_main">Job Posting</label>
        <input type="text" [value]="projectDetail.name" name="projectId" class="form-control"
          formControlName="projectId">
      </div>

    </div>

    <div class="modal-footer" *ngIf="candidateTypeSelected == 1 || candidateTypeSelected == 2">
      <div class="spinner-border" *ngIf="projectapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter" (click)="modal.close('Save click');this.CreateCandidateForm.reset();this.eitherSelectError=false;this.createCandidateError=false;this.createCandidateErrorProjectId=false ;this.submitted = false ; resetBackendCandidateerror()" *ngIf="!projectapicall">Cancel</button>
      <button type="button" class="blue-btn ms-" [disabled]="isdisable" (click)="createCandidate()"  *ngIf="!projectapicall">Save</button>
    </div>
  </form>
  <form [formGroup]="CreateCandidateManuallyForm" *ngIf="candidateTypeSelected == 3">
    <div class="modal-body">
    <div class="row">
      <div class="col-6">
        <div class="mb-3">
          <label for="firstname">First name</label>
          <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
          <input type="text" placeholder="Enter first name" formControlName="firstname" class="form-control" [ngClass]="{ 'is-invalid': submitted && createCandidateManuallyForm['firstname'].errors }" >
          <div style="color: red; font-size: 14px;" *ngIf="submitted && createCandidateManuallyForm['firstname'] && createCandidateManuallyForm['firstname'].errors && createCandidateManuallyForm['firstname'].errors['required']">
          First name is required
            </div>
        </div>
      </div>
      <div class="col-6">
        <div class="mb-3">
          <label for="lastname">Last name</label>
          <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
          <input type="text" placeholder="Enter last name" formControlName="lastname" class="form-control" [ngClass]="{ 'is-invalid': submitted && createCandidateManuallyForm['lastname'].errors }" >
          <div style="color: red; font-size: 14px;" *ngIf="submitted && createCandidateManuallyForm['lastname'] && createCandidateManuallyForm['lastname'].errors && createCandidateManuallyForm['lastname'].errors['required']">
          Last name is required
            </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <label for="email">Email</label>
          <input type="text" placeholder="Enter Email" formControlName="email" class="form-control" >
            <span style="color: red; font-size: 14px;text-align: left;" class="w-100 col-12 ps-0" *ngIf="createCandidateManuallyForm['email'] && createCandidateManuallyForm['email'].errors && createCandidateManuallyForm['email'].errors['pattern']">
              Invalid Email Address
            </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">  
        <div class="mb-3" *ngIf="candidateTypeSelected == 3">
          <label for="project_main">Search</label>
          <input type="text" [value]="projectDetail.name" name="projectId" class="form-control"
            formControlName="projectId">
        </div>
      </div>
    </div>  
     
    
    <div *ngIf="candidate_exists"  class="text-danger" >
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="!linkedin_candidate && !projectapicall && this.submitted">{{createCandidateManuallyError}}</div>
        <div  *ngFor="let ce of candidate_exists">
          <div class="row">
            <div class="col-3" style="padding-left: 20px;">Name</div>
            <div class="col-9">{{ce.in_first_name}} {{ce.in_last_name}}</div>
          </div>  
          <div class="row">
            <div class="col-3" style="padding-left: 20px;">Email</div>
            <div class="col-9">{{ce.in_personal_email}}</div>
          </div>  
          <hr>
        </div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;padding-top: 0px;" class="text-danger" *ngIf="createCandidateManuallyError">Do you want to still create new?</div>
      </div>
    </div>

    <div class="modal-footer" >
      <div class="spinner-border" *ngIf="projectapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter" (click)="modal.close('Save click') ; this.submitted=false ; resetBackendCandidateerror() ; this.linkedin_candidate=true" *ngIf="!projectapicall && manually_submitted != 0 ">Cancel</button>
      <button type="button" class="blue-btn" (click)="createCandidateManually()" *ngIf="candidateTypeSelected == 3 && !projectapicall && manually_submitted != 0">Save</button>
      <button type="button" class="filter" (click)="modal.close('Save click') ; this.submitted=false ;this.manually_submitted = -1; resetBackendCandidateerror() ;resetBackendCandidateManuallyerror()" *ngIf="candidateTypeSelected == 3 && !projectapicall && this.submitted && manually_submitted == 0">No</button>
      <button type="button" class="blue-btn" (click)="this.createCandidateManuallyConfirmation = true; createCandidateManually()" *ngIf="candidateTypeSelected == 3  && !projectapicall && this.submitted && manually_submitted == 0">Yes</button>

    </div>
  </form>
</ng-template>

<ng-template #ExportDocument let-modal>
  <div class="modal-header">
    <h4 *ngIf="pageTitle !== 'Job Posting'" class="modal-title" id="modal-basic-title">Export</h4>
    <h4 *ngIf="pageTitle === 'Job Posting'" class="modal-title" id="modal-basic-title">Report</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="pageTitle !== 'Job Posting'" class="mb-3">
      Search data successfully exported.
    </div>
    <div *ngIf="pageTitle === 'Job Posting'"class="mb-3">
      Selected record report generated successfully.
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="filter" (click)="modal.close('Save click')">Cancel</button>
    <button type="button" (click)="download();modal.close('Save click')" class="blue-btn "><a class="download-link" href="{{downloadUrl}}" > Download</a></button>
  </div>
</ng-template>

<ng-template #deleteCandidate let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Remove Candidate</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="mb-3">
      <label for="deleteCandidateName">Do you want to remove <strong>{{deleteCandidateName}}</strong>  from <strong>{{projectDetail?.name}}
      </strong> ?</label>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="filter" (click)="modal.close('Save click')">Cancel</button>
    <button type="button" class="blue-btn ms-2" (click)="removeCandidate()">Remove</button>
  </div>
</ng-template>


<ng-template #UpdateProject let-modal style="width: 1000px;">
  <div style="width: 1000px;" class="new">
    <div class="row">
      <div class="col-lg-12">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Edit Search</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click') ; this.uploadsubmitted=false ;  this.submitted = false ; resetBackendProjecterror()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
    </div>
    </div>
    <div class="row">
   <div class="col-lg-6">
    <form [formGroup]="UpdateProjectForm">  
      <div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectError">{{createProjectError}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorName">{{createProjectErrorName}}</div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectErrorClientname">{{createProjectErrorClientname}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorType">{{createProjectErrorType}}</div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectErrorProjectstatusid">{{createProjectErrorProjectstatusid}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorOfficialstartdate">{{createProjectErrorOfficialstartdate}}</div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectErrorOfficialenddate">{{createProjectErrorOfficialenddate}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorStreetaddress">{{createProjectErrorStreetaddress}}</div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectErrorPostalcode">{{createProjectErrorPostalcode}}</div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectErrorCity">{{createProjectErrorCity}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorState">{{createProjectErrorState}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorDescription">{{createProjectErrorDescription}}</div>
      </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for="name">Name</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="text" placeholder="Enter search name" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" >
        <div style="color: red; font-size: 14px;" *ngIf="submitted && f['name'] && f['name'].errors && f['name'].errors['required']">
          Name is required.
          </div>
      </div>
      <div class="mb-3">
        <label for="clientname">Client Name</label>
        <ng-select [items]="clientsList" class="form-control form-select" 
        bindLabel="name" bindValue="id" (search)="onClientSearch($event)" (blur)="getClientsList()" (change)="onClientSearch($event)"
        formControlName="clientname" placeholder="Select client" >
        </ng-select>
      </div>
  
      <div class="row">
        <div class="col-6">
          <div class="mb-3">
            <label for="type">Type</label>
            <ng-select [items]="projectTypeList" class="form-control form-select" placeholder="Select type" bindLabel="name"
              bindValue="id" formControlName="type">
            </ng-select>
          </div>
        </div>
        <div class="col-6">
          <label for="project_status_id"> Status</label>
          <ng-select [items]="statusofprojectList" class="form-control form-select" placeholder="Select type" bindLabel="name"
            bindValue="id" formControlName="project_status_id">
          </ng-select>
        </div>
      </div>
      
      <div class="row">
        <div class="col-6">
          <div class="mb-3">
            <label for="official_start_date">Start Date</label>
            <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
            <input type="date"  formControlName="official_start_date" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['official_start_date'].errors }">
            <div style="color: red; font-size: 14px;" *ngIf="submitted && f['official_start_date'] && f['official_start_date'].errors && f['official_start_date'].errors['required']">
              Start Date is required.
          </div>
             
          </div>
        </div>    
        <div class="col-6">
      
      <div class="mb-3">
        <label for="official_end_date">End Date</label>
        <input type="date"  formControlName="official_end_date" class="form-control"   >
      </div>
        </div>
    </div>
          <div class="mb-3">
            <label for="">Street address</label>
            <input type="text" placeholder="Enter street address" formControlName="street_address" class="form-control" >
          </div>
          <div class="row">
            <div class="col-6">
              <div class="mb-3">
                <label for="">Postal code</label>
                <input type="number"  onKeyPress="if(this.value.length==10) return false;"  placeholder="Enter postal code" formControlName="postal_code" class="form-control" >
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="">City</label>
                <input type="text" placeholder="Enter city name" formControlName="city" maxlength="30" class="form-control"  >
              </div>
            </div>
          </div>
        
      <div class="row">
        <div class="col-6">
             <div class="mb-3">
              <label for="" >State</label>
              <ng-select [items]="stateList" class="form-control form-select"
              bindLabel="name" bindValue="name"
              formControlName="state" placeholder="Select state"   >
              </ng-select>
            </div>
        </div>
        
      </div>
      <div class="mb-3">
        <label for="description">Description</label>
        <textarea type="text" placeholder="Enter description" formControlName="description" class="form-control" ></textarea>
      </div>
    </div>
  
    <div class="modal-footer">
      <div class="spinner-border" *ngIf="projectapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter" (click)="modal.close('Save click') ; this.uploadsubmitted=false  ; this.submitted = false ; resetBackendProjecterror()" *ngIf="!projectapicall">Cancel</button>
      <button type="button" class="blue-btn" (click)="UpdateProjects()"  *ngIf="!projectapicall">Save</button>
     
    </div>
  </form>
 
   </div>
   <div class="col-lg-1" style="width: 0.33333%;">
    <div class="vl"></div>
   </div>
   <div class="col-lg-5" style="padding: 0px 0px 0px 10px;">
    <div class="modal-header" style="border-bottom: unset !important;padding-bottom: 0px;">
      <div style="display: flex;">
        <h5 class="modal-title" id="modal-basic-title"><strong>Documents</strong></h5> 
      </div>
    
    </div>
    <form [formGroup]="UpdateProjectForm">  
      <div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="CreatDocumentsErrorDocuments">{{CreatDocumentsErrorDocuments}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorWeblinkone">{{createProjectErrorWeblinkone}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorWeblinktwo">{{createProjectErrorWeblinktwo}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorWeblinkthree">{{createProjectErrorWeblinkthree}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="CreatDocumentsErrorDocumentsname">{{CreatDocumentsErrorDocumentsname}}</div>
      </div>
    <div class="modal-body ">
      <div class="row">
        <div class="col-lg-12">
         <div >
          <div class="mb-3" >
            <input type="file" multiple accept=".pdf,.doc,.docx" placeholder="Document is required" max-file-size="10000000" formControlName="document" class="form-control" (change)="attachFiles($event)" >
          </div> 
          <div class="col-lg-12" *ngIf="projectapicall">{{fileName}}</div>
        </div>
         <div class="row" >
          <div id="panelsStayOpen-collapsesix" class="accordion-collapse collapse show scrolldoc" aria-labelledby="panelsStayOpen-headingsix">
            <div class="accordion-body" *ngFor="let document of documentData ">
              <div class="experience d-flex justify-content-between"  >
             <div class="experience-left col-lg-10" >
                <h6 class="modal-title" id="modal-basic-title"><a href="{{document?.path}}" target="_blank" style="color: gray;text-decoration: unset !important;font-size: 14px;"> {{document?.name}}</a></h6> 
            </div>
            <div class="inner-edit col-lg-2 " style="text-align: right;">
              <a href="javascript:void(0)" (click)="deleteDocuments(document.id)"> <em class="bi bi-trash" title="Delete"
                  style="color: black;"></em></a>
            </div>
              </div>
              <hr style="margin-top: 2px;" >
            </div>
          </div>
          <div class="accordion-body" *ngIf="documentData?.length==0" style="margin-left: 20px;">No Documents added</div>
          <div style="margin-top: 10px;">
            <div class="spinner-border" *ngIf="projectapicall3">
              <span class="visually-hidden">Uploading...</span>
            </div>
            <button  type="button" class="blue-btn ms-" (click)="UploadDocuments()" style="margin-top: 10px;float: right;" *ngIf="!projectapicall3" >Upload & Save </button>
          </div>
         </div>
        </div>
      </div>
     
      <div class="row" style="margin-top: 20px;">
          <div class="row" style="display: flex;justify-content: space-between;">
          
            <label for="" >Web link 1</label>
            <label for="" *ngIf="showexternallink1 && !editexternallink1"> <span
                (click)="editexternallink1 =true; showexternallink1=false;">{{weblink1| slice:0:40 }}{{(weblink1.length>40?'...
                ':' ')}}</span><a href="{{weblink1}}" target="_blank" title="Open web link"><em class="fa fa-external-link"
                  aria-hidden="true"></em> </a></label>
            <input *ngIf="!showexternallink1 && editexternallink1" type="text" placeholder="Enter URL"
              formControlName="web_link_one" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f['web_link_one'].errors }">
            <div style="color: red; font-size: 14px;"
              *ngIf="f['web_link_one'] && f['web_link_one'].errors && f['web_link_one'].errors['pattern']">
              Please enter a valid URL. (i.e. http://www.google.com)
            </div>
          
       
          </div>
        <div class="row" style="display: flex;justify-content: space-between;">
        
          <label for="">Web link 2</label>
          <label for="" *ngIf="showexternallink2 && !editexternallink2"><span
              (click)="editexternallink2 =true; showexternallink2=false;">{{weblink2| slice:0:40 }}{{(weblink2.length>40?'...
              ':' ')}} </span><a href="{{weblink2}}" target="_blank" title="Open web link"><em class="fa fa-external-link"
                aria-hidden="true"></em> </a></label>
          <input *ngIf="!showexternallink2 && editexternallink2" type="text" placeholder="Enter URL"
            formControlName="web_link_two" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f['web_link_two'].errors }">
          <div style="color: red; font-size: 14px;"
            *ngIf="f['web_link_two'] && f['web_link_two'].errors && f['web_link_two'].errors['pattern']">
            Please enter a valid URL. (i.e. http://www.google.com)
          </div>
        
       
        </div>
        <div class="row" style="display: flex;justify-content: space-between;">
        
          <label for="" *ngIf="!showexternallink3">Web link 3</label>
          <label for="" *ngIf="showexternallink3 && !editexternallink3"><span
              (click)="editexternallink3 =true; showexternallink3=false;">{{weblink3| slice:0:40 }}{{(weblink3.length>40?'...
              ':' ')}}</span> <a href="{{weblink3}}" target="_blank" title="Open web link"><em class="fa fa-external-link"
                aria-hidden="true"></em> </a></label>
          <input type="text" placeholder="Enter URL" formControlName="web_link_three" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f['web_link_three'].errors }">
          <div style="color: red; font-size: 14px;"
            *ngIf="f['web_link_three'] && f['web_link_three'].errors && f['web_link_three'].errors['pattern']">
            Please enter a valid URL. (i.e. http://www.google.com)
          </div>
        
        
        </div>

       <div class="row" style="display: flex;justify-content: space-between;margin-top:28px">
          <label for="">Users</label>
     
            <ng-select [items]="userList" class="form-control form-select"  [multiple]="true"
            bindLabel="name" bindValue="id" (search)="onUserSearch($event)" (click)="getUserList()" (keyup)="getUserList()" (change)="onUserSearch($event)"
            formControlName="user" placeholder="Select user">
            </ng-select>
         
    
       
       </div>
      </div>
    </div>
  </form>
  </div>
</div>
  </div>
</ng-template>

<ng-template #addUserToSearch let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add User</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="AddUserToSearchForm">
    <div class="modal-body">
      
      <div class="row" style="display: flex;justify-content: space-between;">
        <div class="col-lg-12">

        

          <div class="mb-3">
            <label for="">Users</label>
            <ng-select [items]="userList" class="form-control form-select"
            bindLabel="name" bindValue="id" (search)="onUserSearch($event)" 
            formControlName="user" placeholder="Select user" >
            </ng-select>
          </div>


        </div>  
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="filter" (click)="modal.close('Save click')">Cancel</button>
      <button type="button" (click)="modal.close('Save click')" class="blue-btn" (click)="adduserstosearch()">Add User</button>
    </div>
  </form>
</ng-template>


<ng-template #ispublic let-modal>

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Job Posting</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="row">
  
    <div class="col-lg-12">
      <form [formGroup]="updateJobPostingForm">
  
        <div>
          <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectError">
            {{createProjectError}}</div>
          <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
            *ngIf="createProjectErrorName">{{createProjectErrorName}}</div>
          <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
            *ngIf="createProjectErrorProjectstatusid">{{createProjectErrorProjectstatusid}}</div>
          <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
            *ngIf="createProjectErrorCity">{{createProjectErrorCity}}</div>
          <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
            *ngIf="createProjectErrorState">{{createProjectErrorState}}</div>
          <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
            *ngIf="createProjectErrorDescription">{{createProjectErrorDescription}}</div>
        </div>
  
        <div class="modal-body">
  
          <div class="row">
  
            <div class="col-6">
              <div class="mb-3">
                <label for="" >Position</label><span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
                <input type="text" placeholder="Enter Position Title" formControlName="name" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && jobupdate['name'].errors }">
                <div style="color: red; font-size: 14px;"
                  *ngIf="submitted && jobupdate['name'] && jobupdate['name'].errors && jobupdate['name'].errors['required']">
                  Position is required
                </div>
              </div>
            </div>
  
            <div class="col-6">
              <div class="mb-3">
                <label for="" > Status</label>
                <ng-select [items]="statusofprojectList" class="form-control form-select" placeholder="Select type"
                  bindLabel="name" bindValue="id" formControlName="project_status_id">
                </ng-select>
              </div>
            </div>
  
          </div>
  
          <div class="row">
          
            <div class="col-4">
              <div class="mb-3">
                <label for="" >Client Name</label>
                <ng-select [items]="clientsList" class="form-control form-select" bindLabel="name" bindValue="id"
                  (search)="onClientSearch($event)" (blur)="getClientsList()" (change)="onClientSearch($event)"
                  formControlName="clientname" placeholder="Select client">
                </ng-select>
              </div>
            </div>
          
            <div class="col-4">
              <div class="mb-3">
                <label for="">City</label>
                <input type="text" placeholder="Enter city name" formControlName="city" maxlength="30" class="form-control">
              </div>
            </div>
          
            <div class="col-4">
              <div class="mb-3">
                <label for="">State</label>
                <ng-select [items]="stateList" class="form-control form-select" bindLabel="name" bindValue="name"
                  formControlName="state" placeholder="Select state">
                </ng-select>
              </div>
            </div>
          
          </div>
  
          <div class="row">
              <div class="mb-6">
              <label for="">Description</label>
              <angular-editor formControlName="description" [config]="config"></angular-editor>
            </div>
          </div>

        </div>
  
        <div class="modal-footer">
          <div class="spinner-border" *ngIf="projectapicall">
            <span class="visually-hidden">Loading...</span>
          </div>
          <button type="button" class="filter"
            (click)="modal.close('Save click') ; this.uploadsubmitted=false  ; this.submitted = false ; resetBackendProjecterror()"
            *ngIf="!projectapicall">Cancel</button>
          <button type="button" class="blue-btn" (click)="Updatejobposting()" *ngIf="!projectapicall">Save</button>
  
        </div>
  
      </form>
  
    </div>

    <div class="col-lg-6">
      <div class="modal-header" style="border-bottom: unset !important;padding-bottom: 0px;">
        <div style="display: flex;">
          <h5 class="modal-title" id="modal-basic-title"><strong>Documents</strong></h5>
        </div>
    
      </div>
    
      <form [formGroup]="UpdateProjectForm">
    
        <div>
          <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
            *ngIf="CreatDocumentsErrorDocuments">{{CreatDocumentsErrorDocuments}}</div>
          <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
            *ngIf="CreatDocumentsErrorDocumentsname">{{CreatDocumentsErrorDocumentsname}}</div>
        </div>
    
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <div>

                <div class="mb-3">
                  <input type="file" multiple accept=".pdf,.doc,.docx" placeholder="Document is required"
                    max-file-size="10000000" formControlName="document" class="form-control" (change)="attachFiles($event)">
                </div>

                <div class="col-lg-12" *ngIf="projectapicall">{{fileName}}</div>

              </div>
              <div class="row">
                <div id="panelsStayOpen-collapsesix" class="accordion-collapse collapse show scrolldoc"
                  aria-labelledby="panelsStayOpen-headingsix">
                  <div class="accordion-body" *ngFor="let document of documentData ">
                    <div class="experience d-flex justify-content-between">
                      <div class="experience-left col-lg-10">
                        <h6 class="modal-title" id="modal-basic-title"><a href="{{document?.path}}" target="_blank"
                            style="color: gray;text-decoration: unset !important;font-size: 14px;">
                            {{document?.name}}</a></h6>
                      </div>
                      <div class="inner-edit col-lg-2 " style="text-align: right;">
                        <a href="javascript:void(0)" (click)="deleteDocuments(document.id)"> <em class="bi bi-trash"
                            title="Delete" style="color: black;"></em></a>
                      </div>
                    </div>
                    <hr style="margin-top: 2px;">
                  </div>
                </div>
                <div class="accordion-body" *ngIf="documentData?.length==0" style="margin-left: 20px;">No Documents
                  added</div>
                <div style="margin-top: 10px;">
                  <div class="spinner-border" *ngIf="projectapicall3">
                    <span class="visually-hidden">Uploading...</span>
                  </div>
                  <button type="button" class="blue-btn ms-" (click)="UploadDocuments()" style="margin-top: 10px;float: right;"
                    *ngIf="!projectapicall3">Upload & Save </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>

    </div>
  </div>


</ng-template>
