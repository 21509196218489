import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../common/storage.service';

@Injectable({
  providedIn: 'root'
})
export class TagserviceService {

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService) { }

  create(data:any): Observable<any>{
    return this.httpClient.post(`${environment.apiUrl}tags`, data);
  }

  listAll(): Observable<any>{
    return this.httpClient.get(`${environment.apiUrl}tags`);
  }

  listPagewise(data?: any): Observable<any>{
    return this.httpClient.get(`${environment.apiUrl}tags?page=`+ data.page);
  }
  listSearch(data?: any): Observable<any>{  
    return this.httpClient.get(`${environment.apiUrl}tags?search=`+ data.search);
  } 

  // listSort(data?: any): Observable<any>{  
  //   return this.httpClient.get(`${environment.apiUrl}tags?sort_field=`+ data.sort_field + `&sort_method= `+ data.sort_method);
  // }

  getTagData(data?: any): Observable<any>{  
   // console.log(data,"in servce")
    return this.httpClient.get(`${environment.apiUrl}tags/`+ data.id);
  } 
  updateTagData(id:number,data:any): Observable<any>{
    return this.httpClient.put(`${environment.apiUrl}tags/`+id, data);
  }

  deleteTagData(id:number): Observable<any>{
    return this.httpClient.delete(`${environment.apiUrl}tags/`+id);
  }

  searchTagList(data?:any){
    let get_params:string = '';
    if(data && data.search){
      get_params += `search=`+ data.search;
    }
    return this.httpClient.get(`${environment.apiUrl}tags?`+get_params);
  }

  listByFilter(data?: any): Observable<any>{
    let get_params:string = '';
    if(data.sort_field && data.sort_method){
      get_params += `sort_field=`+ data.sort_field + `&sort_method= `+ data.sort_method;
    }
    if(data.page){
      if(get_params != ''){
        get_params +=`&`
      }
      get_params += `page=`+ data.page ;
    }
    if(data.search){
      if(get_params != ''){
        get_params +=`&`
      }
      get_params += `search=`+ data.search;
    }
    return this.httpClient.get(`${environment.apiUrl}tags?`+get_params);
  }

}
