import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RegisterComponent } from './pages/register/register.component';
import { CandidateLoginComponent } from './pages/candidate-login/candidate-login.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '', children: [{ path: 'login', component: LoginComponent }] },
  { path: ':token', component: ResetPasswordComponent },
  // { path: 'register', children: [{ path: 'register', component: RegisterComponent }],

  {
    path: 'register',
    component: RegisterComponent
  },

  {
    path: 'jobs',
    component: CandidateLoginComponent
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class LoginRoutingModule { }
