<div class="login_page">
    <div class="container custom_login" id="container">
        <div class="form-container log-in-container">
            <div class="left-container">

                <h5 style="padding: 0">Email verification is successfully done.<br>Please login.</h5>

                <div style="margin-top: 10px">
                    <a class="forgot_password signup-btn-l" style="cursor: pointer; margin: 0;"
                        (click)="gobacktologin()">Login</a>
                </div>

            </div>
        </div>
        <div class="overlay-container">
            <div class="overlay">
                <div class="overlay-panel overlay-right">
                    <img style="width:100%" src="assets/img/TATlogo.PNG" alt="">
                </div>
            </div>
        </div>
    </div>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4"
        crossorigin="anonymous"></script>
</div>