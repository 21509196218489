<app-app-sub-header class="top-row d-flex justify-content-between" (onRefreshClicked)="resetListSettings()" (searchClicked)="searchData($event)" [isSearchable]="isSearchable" [pageTitle]="pageTitle" [showCreateButton]="showCreateButton" (onShowFilter)="showFilter()"></app-app-sub-header>
    
<div class="bottom-table">
  <form [formGroup]="FilterForm" *ngIf="showFilterOptions">
    <div class="row">
      <div class="col-2">
        <ng-select [items]="projectStatusList" class="form-control form-select " placeholder="Status" bindLabel="name"
          formControlName="f_project_status" (click)="this.getProjectStatusList()" bindValue="id">
        </ng-select>
      </div>
      <div *ngIf="pageTitle === 'Searches'" class="col-2">
        <ng-select [items]="projectTypeList" class="form-control form-select " placeholder="Engagement Type"
          bindLabel="name" formControlName="f_project_type" (click)="this.getProjectTypeList()" bindValue="id">
        </ng-select>
      </div>


      <div *ngIf="is_client_module_access_disabled == '0'" class="col-2">
        <ng-select [items]="clientList" class="form-control form-select " placeholder="Client" bindLabel="name"
          formControlName="f_client" bindValue="id" #f_client (keydown)="this.getClientList(f_client.searchTerm)"
          (click)="this.getClientList()">
        </ng-select>
      </div>
      <div class="col-2">
        <input type="text" formControlName="f_city" class="form-control" placeholder="City">
      </div>

      <div class="col-2">
        <ng-select [items]="stateList" class="form-control form-select " placeholder="State" bindLabel="name"
          formControlName="f_state" bindValue="name" (click)="this.getStateList()">
        </ng-select>
      </div>

      <div class="col-2 mb-3" style="text-align: right;">
        <button type="button" class="filter" (click)="clearFilterForm()">Clear</button>
        <button type="button" class="blue-btn ms-2" (click)="applyFilter()">Apply</button>
      </div>
    </div>
  </form>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col" style="width:16%" (mouseenter)="mouseEnter('name')" (mouseleave)="mouseLeave('name')">
          Position
          <span *ngIf="currentSortedColumn != 'name' && mouseOnColumnName == 'name'"
            (click)="getProjectList({sort_field:'name',sort_method:'desc',column_name:'name'})" class="sortbyspanr"
            [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'name'" [hidden]="!iconVisibleDesc"
            (click)="getProjectList({sort_field:'name',sort_method:'asc',column_name:'name'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'name'" [hidden]="!iconVisibleAsc"
            (click)="getProjectList({sort_field:'name',sort_method:'desc',column_name:'name'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th> 

        <th *ngIf="is_client_module_access_disabled == '0' " scope="col" style="width:20%"
          (mouseenter)="mouseEnter('client_id')" (mouseleave)="mouseLeave('client_id')">
          Client
          <span *ngIf="currentSortedColumn != 'client_id' && mouseOnColumnName == 'client_id'"
            (click)="getProjectList({sort_field:'client_id',sort_method:'desc',column_name:'client_id'})" class="sortbyspanr"
            [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'client_id'" [hidden]="!iconVisibleDesc"
            (click)="getProjectList({sort_field:'client_id',sort_method:'asc',column_name:'client_id'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'client_id'" [hidden]="!iconVisibleAsc"
            (click)="getProjectList({sort_field:'client_id',sort_method:'desc',column_name:'client_id'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>

        <th *ngIf="pageTitle === 'Searches'" scope="col" style="width:10%" (mouseenter)="mouseEnter('project_type_id')"
          (mouseleave)="mouseLeave('project_type_id')">
          Type
          <span *ngIf="currentSortedColumn != 'project_type_id' && mouseOnColumnName == 'project_type_id'"
            (click)="getProjectList({sort_field:'project_type_id',sort_method:'desc',column_name:'project_type_id'})"
            class="sortbyspanr" [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'project_type_id'" [hidden]="!iconVisibleDesc"
            (click)="getProjectList({sort_field:'project_type_id',sort_method:'asc',column_name:'project_type_id'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'project_type_id'" [hidden]="!iconVisibleAsc"
            (click)="getProjectList({sort_field:'project_type_id',sort_method:'desc',column_name:'project_type_id'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>

        <th class="text-center" scope="col" style="width:12%" (mouseenter)="mouseEnter('days_open')"
          (mouseleave)="mouseLeave('days_open')">
          Days Open
          <span *ngIf="currentSortedColumn != 'days_open' && mouseOnColumnName == 'days_open'"
            (click)="getProjectList({sort_field:'days_open',sort_method:'desc',column_name:'days_open'})"
            class="sortbyspanr" [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'days_open'" [hidden]="!iconVisibleDesc"
            (click)="getProjectList({sort_field:'days_open',sort_method:'asc',column_name:'days_open'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'days_open'" [hidden]="!iconVisibleAsc"
            (click)="getProjectList({sort_field:'days_open',sort_method:'desc',column_name:'days_open'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>
        <th class="text-center" scope="col" style="width:12%" (mouseenter)="mouseEnter('candidates_count')"
          (mouseleave)="mouseLeave('candidates_count')">
          Candidates
          <span *ngIf="currentSortedColumn != 'candidates_count' && mouseOnColumnName == 'candidates_count'"
            (click)="getProjectList({sort_field:'candidates_count',sort_method:'desc',column_name:'candidates_count'})"
            class="sortbyspanr" [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'candidates_count'" [hidden]="!iconVisibleDesc"
            (click)="getProjectList({sort_field:'candidates_count',sort_method:'asc',column_name:'candidates_count'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'candidates_count'" [hidden]="!iconVisibleAsc"
            (click)="getProjectList({sort_field:'candidates_count',sort_method:'desc',column_name:'candidates_count'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>
        <th scope="col" style="width:15%" (mouseenter)="mouseEnter('city')" (mouseleave)="mouseLeave('city')">
          City
          <span *ngIf="currentSortedColumn != 'city' && mouseOnColumnName == 'city'"
            (click)="getProjectList({sort_field:'city',sort_method:'desc',column_name:'city'})" class="sortbyspanr"
            [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'city'" [hidden]="!iconVisibleDesc"
            (click)="getProjectList({sort_field:'city',sort_method:'asc',column_name:'city'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'city'" [hidden]="!iconVisibleAsc"
            (click)="getProjectList({sort_field:'city',sort_method:'desc',column_name:'city'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>
        <th class="text-center" scope="col" style="width:10%" (mouseenter)="mouseEnter('project_status_id')"
          (mouseleave)="mouseLeave('project_status_id')">
          Status
          <span *ngIf="currentSortedColumn != 'project_status_id' && mouseOnColumnName == 'project_status_id'"
            (click)="getProjectList({sort_field:'project_status_id',sort_method:'desc',column_name:'project_status_id'})"
            class="sortbyspanr" [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'project_status_id'" [hidden]="!iconVisibleDesc"
            (click)="getProjectList({sort_field:'project_status_id',sort_method:'asc',column_name:'project_status_id'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'project_status_id'" [hidden]="!iconVisibleAsc"
            (click)="getProjectList({sort_field:'project_status_id',sort_method:'desc',column_name:'project_status_id'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>
        <th>
          Action
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let pl of projectList">

        <td (click)="showProject(pl.id)">
          <span class="name"> {{pl?.name}}</span>
        </td>

        <td *ngIf="is_client_module_access_disabled == '0' " (click)="showProject(pl.id)">{{pl.client?.name}}</td>

        <td *ngIf="pageTitle === 'Searches'" (click)="showProject(pl.id)">{{pl.type?.name}}</td>

        <td (click)="showProject(pl.id)" class="text-center" title="{{pl.days_open_tooltip}}"><span
            class="circle-num">{{pl?.days_open}}</span></td>
        <td (click)="showProject(pl.id)" class="text-center" title="{{pl.candidates_count}} Candidate(s) added."><span
            class="circle-num">{{pl?.candidates_count}}</span></td>
        <td (click)="showProject(pl.id)">{{pl?.city}}</td>
        <td (click)="showProject(pl.id)" class="text-center"><span class="badge"
            [ngClass]="pl.status.name === 'Active' ? 'badge text-bg-success' : 'badge text-bg-warning'">
            {{ pl.status?.name }}
          </span></td>
        <td style="display: flex;padding-top: 12px;padding-bottom: 12px;">
          <a *ngIf="!pl.is_pinned" (click)="togglePinned(pl)"><em class="bi bi-pin-angle me-2"
              title="Pin this search"></em></a>
          <a *ngIf="pl.is_pinned" (click)="togglePinned(pl)"><em class="bi bi-pin-angle-fill me-2"
              title="Unpin this search"></em></a>
          <a (click)="open(UpdateProject,pl.id)"><em class="bi bi-pencil-square me-2" title="Edit"></em></a>
          <a (click)="openDeleteModal(content,pl.id,pl)"><em class="bi bi-trash" title="Delete"></em></a>
        </td>
      </tr>

    </tbody>
  </table>
</div>

<div class="pagination-main">
  <label for="totalRows" class="list-totalrecords">Total Records: {{totalRows}}</label>
  <ul *ngIf="pager.pages && pager.pages.length && totalRows > pageSize" class="pagination justify-content-end m-0">
    
    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
        <a *ngIf="pager.currentPage === 1; else elseFirst" class="page-link page-show"> <<</a>
        <ng-template #elseFirst>
            <a (click)="setPage(1)" class="page-link"><<</a>
        </ng-template>
    </li>
    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
        <a *ngIf="pager.currentPage === 1; else elsePrevious" class="page-link page-show"><</a>
        <ng-template #elsePrevious>
            <a (click)="setPage(pager.currentPage - 1)" class="page-link"> <</a>
        </ng-template>
    </li>
    <li class="page-item ">
      <a class="page-link">{{pager.currentPage}}</a>
  </li>
    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a *ngIf="pager.currentPage === pager.totalPages; else elseNext" class="page-link page-show">></a>
        <ng-template #elseNext>
            <a (click)="setPage(pager.currentPage + 1)" class="page-link">></a>
        </ng-template>
    </li>
    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a *ngIf="pager.currentPage === pager.totalPages; else elseLast" class="page-link page-show">>></a>
        <ng-template #elseLast>
            <a (click)="setPage(pager.totalPages)" class="page-link">>></a>
        </ng-template>
    </li>
</ul>
</div>



<ng-template #UpdateProject let-modal style="width: 1000px;">
<div style="width: 1000px;" class="new">
<div class="row">
  <div class="col-lg-12">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Edit Search</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click') ; this.uploadsubmitted=false ;  this.submitted = false ; resetBackendProjecterror()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
</div>
</div>
<div class="row">
<div class="col-lg-6">
<form [formGroup]="UpdateProjectForm">  
  <div>
    <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectError">{{createProjectError}}</div>
    <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorName">{{createProjectErrorName}}</div>
    <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectErrorClientname">{{createProjectErrorClientname}}</div>
    <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorType">{{createProjectErrorType}}</div>
    <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectErrorProjectstatusid">{{createProjectErrorProjectstatusid}}</div>
    <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorOfficialstartdate">{{createProjectErrorOfficialstartdate}}</div>
    <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectErrorOfficialenddate">{{createProjectErrorOfficialenddate}}</div>
    <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorStreetaddress">{{createProjectErrorStreetaddress}}</div>
    <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectErrorPostalcode">{{createProjectErrorPostalcode}}</div>
    <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectErrorCity">{{createProjectErrorCity}}</div>
    <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorState">{{createProjectErrorState}}</div>
    <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorDescription">{{createProjectErrorDescription}}</div>
  </div>

<div class="modal-body">
  <div class="mb-3">
    <label for="">Position</label>
    <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
    <input type="text" placeholder="Enter search name" formControlName="name" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f['name'].errors }">
    <div style="color: red; font-size: 14px;"
      *ngIf="submitted && f['name'] && f['name'].errors && f['name'].errors['required']">
      Position is required.
    </div>
  </div>
  <div class="mb-3">
    <label for="">Client Name</label>
    <ng-select [items]="clientsList" class="form-control form-select" bindLabel="name" bindValue="id"
      (search)="onClientSearch($event)" formControlName="clientname" placeholder="Select client">
    </ng-select>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="mb-3">
        <label for="">Engagement Type</label>
        <ng-select [items]="projectTypeList" class="form-control form-select" placeholder="Select type" bindLabel="name"
          bindValue="id" formControlName="type">
        </ng-select>

      </div>
    </div>
    <div class="col-6">
      <label for=""> Status</label>
      <ng-select [items]="statusofprojectList" class="form-control form-select" placeholder="Select type"
        bindLabel="name" bindValue="id" formControlName="project_status_id">
      </ng-select>

    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="mb-3">
        <label for="" >Start Date</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="date" formControlName="official_start_date" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f['official_start_date'].errors }">
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && f['official_start_date'] && f['official_start_date'].errors && f['official_start_date'].errors['required']">
          Start Date is required.
        </div>
      </div>
    </div>
    <div class="col-6">

      <div class="mb-3">
        <label for="">End Date</label>
        <input type="date" formControlName="official_end_date" class="form-control">

      </div>
    </div>
  </div>
  <div class="mb-3">
    <label for="">Street address</label>
    <input type="text" placeholder="Enter street address" formControlName="street_address" class="form-control">
  </div>
  <div class="row">
    <div class="col-6">
      <div class="mb-3">
        <label for="">Postal code</label>
        <input type="number" onKeyPress="if(this.value.length==10) return false;" placeholder="Enter postal code"
          formControlName="postal_code" class="form-control">
      </div>
    </div>
    <div class="col-6">
      <div class="mb-3">
        <label for="">City</label>
        <input type="text" placeholder="Enter city name" formControlName="city" maxlength="30" class="form-control">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">

      <div class="mb-3">
        <label for="">State</label>
        <ng-select [items]="stateList" class="form-control form-select" bindLabel="name" bindValue="name"
          formControlName="state" placeholder="Select state">
        </ng-select>

      </div>
    </div>

  </div>
  <div class="mb-3">
    <label for="">Description</label>
    <textarea type="text" placeholder="Enter description" formControlName="description" class="form-control"></textarea>
  </div>
</div>

<div class="modal-footer">
  <div class="spinner-border" *ngIf="projectapicall">
    <span class="visually-hidden">Loading...</span>
  </div>
  <button type="button" class="filter"
    (click)="modal.close('Save click') ; this.uploadsubmitted=false  ; this.submitted = false ; resetBackendProjecterror()"
    *ngIf="!projectapicall">Cancel</button>
  <button type="button" class="blue-btn" (click)="UpdateProjects()" *ngIf="!projectapicall">Save</button>

</div>
</form>

</div>
<div class="col-lg-1" style="width: 0.33333%;">
<div class="vl"></div>
</div>
<div class="col-lg-5" style="padding: 0px 0px 0px 10px;">
<div class="modal-header" style="border-bottom: unset !important;padding-bottom: 0px;">
  <div style="display: flex;">
    <h5 class="modal-title" id="modal-basic-title"><strong>Documents</strong></h5> 
  </div>

</div>
<form [formGroup]="UpdateProjectForm">  
  <div>
    <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="CreatDocumentsErrorDocuments">{{CreatDocumentsErrorDocuments}}</div>
    <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorWeblinkone">{{createProjectErrorWeblinkone}}</div>
    <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorWeblinktwo">{{createProjectErrorWeblinktwo}}</div>
    <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createProjectErrorWeblinkthree">{{createProjectErrorWeblinkthree}}</div>
    <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="CreatDocumentsErrorDocumentsname">{{CreatDocumentsErrorDocumentsname}}</div>
  </div>
<div class="modal-body ">
  <div class="row">
    <div class="col-lg-12">
     <div >
      <div class="mb-3" >
        <input type="file" multiple accept=".pdf,.doc,.docx" placeholder="Document is required" max-file-size="10000000" formControlName="document" class="form-control" (change)="attachFiles($event)" >
      </div> 
      <div class="col-lg-12" *ngIf="projectapicall">{{fileName}}</div>
    </div>
     <div class="row" >
      <div id="panelsStayOpen-collapsesix" class="accordion-collapse collapse show scrolldoc" aria-labelledby="panelsStayOpen-headingsix">
        <div class="accordion-body" *ngFor="let document of documentData ">
          <div class="experience d-flex justify-content-between"  >
         <div class="experience-left col-lg-10" >
            <h6 class="modal-title" id="modal-basic-title"><a href="{{document?.path}}" target="_blank" style="color: gray;text-decoration: unset !important;font-size: 14px;"> {{document?.name}}</a></h6> 
        </div>
        <div class="inner-edit col-lg-2 " style="text-align: right;">
         <a href="javascript:void(0)" (click)="deleteDocuments(document.id)"> 
          <em class="bi bi-trash" title="Delete" style="color: black;"></em></a>
        </div>
          </div>
          <hr style="margin-top: 2px;" >
        </div>
      </div>
      <div class="accordion-body" *ngIf="documentData?.length==0" style="margin-left: 20px;">No Documents added</div>
      <div style="margin-top: 10px;">
        <div class="spinner-border" *ngIf="projectapicall2">
          <span class="visually-hidden">Uploading...</span>
        </div>
        <button  type="button" class="blue-btn ms-" (click)="UploadDocuments()" style="margin-top: 10px;float: right;" *ngIf="!projectapicall2" >Upload & Save </button>
      </div>
     </div>
    </div>
  </div>
 
  <div class="row" style="margin-top: 20px;">
      <div class="row" style="display: flex;justify-content: space-between;">
        <label for="">Web link 1</label>  

        <label for="" *ngIf="showexternallink1 && !editexternallink1" ><span (click)="editexternallink1 =true; showexternallink1=false;">{{weblink1| slice:0:40 }}{{(weblink1.length>40?'...  ':'')}}</span><a href="{{weblink1}}" target="_blank" title="Open web link" ><em class="fa fa-external-link" aria-hidden="true"></em> </a></label> 
        
            <input  *ngIf="!showexternallink1 && editexternallink1" type="text" placeholder="Enter url" formControlName="web_link_one" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['web_link_one'].errors }" >
            <div style="color: red; font-size: 14px;" *ngIf="f['web_link_one'] && f['web_link_one'].errors && f['web_link_one'].errors['pattern']">
              Please enter a valid URL.  (i.e. http://www.google.com)
              </div> 
          
        
      </div>
    <div class="row" style="display: flex;justify-content: space-between;">
      <label for="" >Web link 2</label>
      <label for="" *ngIf="showexternallink2 && !editexternallink2" ><span (click)="editexternallink2 =true; showexternallink2=false;">{{weblink2| slice:0:40  }}{{(weblink2.length>40?'...  ':'')}} </span><a href="{{weblink2}}" target="_blank" title="Open web link" > <em   class="fa fa-external-link" aria-hidden="true"></em> </a></label>
      
         <input *ngIf="!showexternallink2 && editexternallink2" type="text" placeholder="Enter url" formControlName="web_link_two" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['web_link_two'].errors }" >
         <div style="color: red; font-size: 14px;" *ngIf="f['web_link_two'] && f['web_link_two'].errors && f['web_link_two'].errors['pattern']">
          Please enter a valid URL.  (i.e. http://www.google.com)
          </div> 
       
   
   </div>
    <div class="row" style="display: flex;justify-content: space-between;">
      <label for="" *ngIf="!showexternallink3">Web link 3</label>
      <label for="" *ngIf="showexternallink3 && !editexternallink3" ><span (click)="editexternallink3 =true; showexternallink3=false;">{{weblink3| slice:0:40  }}{{(weblink3.length>40?'...  ':'')}}</span> <a href="{{weblink3}}" target="_blank" title="Open web link" ><em   class="fa fa-external-link" aria-hidden="true"></em> </a></label>
      <input for="" *ngIf="!showexternallink3 && editexternallink3" type="text" placeholder="Enter url" formControlName="web_link_three" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['web_link_three'].errors }">
         <div style="color: red; font-size: 14px;" *ngIf="f['web_link_three'] && f['web_link_three'].errors && f['web_link_three'].errors['pattern']">
          Please enter a valid URL.  (i.e. http://www.google.com)
          </div> 
        
  
   </div>
  <div class="row" style="display: flex;justify-content: space-between;margin-top:28px">
    <div class="col-lg-10">
      <div class="mb-3">
        <label for="">Users</label>
  
        <ng-select [items]="userList" class="form-control form-select" [multiple]="true" bindLabel="name" bindValue="id"
          (search)="onUserSearch($event)" formControlName="user" placeholder="Select user">
        </ng-select>
  
  
      </div>
    </div>
  </div>
  </div>
</div>
</form>
</div>
</div>
</div>
</ng-template>

<ng-template #content let-modal>
<div class="modal-header">
<h4 class="modal-title" id="modal-basic-title">Delete Search</h4>
<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
  <span aria-hidden="true">×</span>
</button>
</div>
<div class="modal-body">
<form>
  <div class="mb-3">
    <p>
      Are you sure you want to delete {{selectDeletedProject}} ?
    </p>
  </div>
</form>
</div>

<div class="modal-footer">
<button type="button" class="btn btn-outline-secondary cancelbutton" (click)="modal.dismiss('cancel click')">Cancel</button>
<button type="button" class="btn btn-danger dangerbtn" (click)="modal.close('Ok click')">Delete</button>
</div>
</ng-template>


<ng-template #ispublic let-modal>

<div class="modal-header">
<h4 class="modal-title" id="modal-basic-title">Edit Job Posting</h4>
<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
  <span aria-hidden="true">×</span>
</button>
</div>

<div class="row">

<div class="col-lg-12">
  <form [formGroup]="updateJobPostingForm">

    <div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createProjectError">
        {{createProjectError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createProjectErrorName">{{createProjectErrorName}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createProjectErrorProjectstatusid">{{createProjectErrorProjectstatusid}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createProjectErrorCity">{{createProjectErrorCity}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createProjectErrorState">{{createProjectErrorState}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="createProjectErrorDescription">{{createProjectErrorDescription}}</div>
    </div>

    <div class="modal-body">

      <div class="row">

        <div class="col-6">
          <div class="mb-3">
            <label for="" >Position</label><span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
            <input type="text" placeholder="Enter Position Title" formControlName="name" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && jobupdate['name'].errors }">
            <div style="color: red; font-size: 14px;"
              *ngIf="submitted && jobupdate['name'] && jobupdate['name'].errors && jobupdate['name'].errors['required']">
              Position is required
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="mb-3">
            <label for="" > Status</label>
            <ng-select [items]="statusofprojectList" class="form-control form-select" placeholder="Select type"
              bindLabel="name" bindValue="id" formControlName="project_status_id">
            </ng-select>
          </div>
        </div>

      </div>

      <div class="row">
      
        <div class="col-4">
        
          <div class="mb-3">
            <label for="" >Client Name</label>
            <ng-select [items]="clientsList" class="form-control form-select" bindLabel="name" bindValue="id"
              (search)="onClientSearch($event)"
              formControlName="clientname" placeholder="Select client">
            </ng-select>
          </div>
        </div>
      
        <div class="col-4">
          <div class="mb-3">
            <label for="" >City</label>
            <input type="text" placeholder="Enter city name" formControlName="city" maxlength="30" class="form-control">
          </div>
        </div>
      
        <div class="col-4">
          <div class="mb-3">
            <label for="">State</label>
            <ng-select [items]="stateList" class="form-control form-select" bindLabel="name" bindValue="name"
              formControlName="state" placeholder="Select state">
            </ng-select>
          </div>
        </div>
      
      </div>

      <div class="row">
          <div class="mb-6">
          <label for="" >Description</label>
          <angular-editor formControlName="description" [config]="config"></angular-editor>
        </div>
      </div>

    </div>

    <div class="modal-footer">
      <div class="spinner-border" *ngIf="projectapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter"
        (click)="modal.close('Save click') ; this.uploadsubmitted=false  ; this.submitted = false ; resetBackendProjecterror()"
        *ngIf="!projectapicall">Cancel</button>
      <button type="button" class="blue-btn" (click)="Updatejobposting()" *ngIf="!projectapicall">Save</button>

    </div>

  </form>

</div>

<div class="col-lg-6">
  <div class="modal-header" style="border-bottom: unset !important;padding-bottom: 0px;">
    <div style="display: flex;">
      <h5 class="modal-title" id="modal-basic-title"><strong>Documents</strong></h5>
    </div>

  </div>

  <form [formGroup]="UpdateProjectForm">

    <div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="CreatDocumentsErrorDocuments">{{CreatDocumentsErrorDocuments}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger"
        *ngIf="CreatDocumentsErrorDocumentsname">{{CreatDocumentsErrorDocumentsname}}</div>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-lg-12">
          <div>

            <div class="mb-3">
              <input type="file" multiple accept=".pdf,.doc,.docx" placeholder="Document is required"
                max-file-size="10000000" formControlName="document" class="form-control" (change)="attachFiles($event)">
            </div>

            <div class="col-lg-12" *ngIf="projectapicall">{{fileName}}</div>

          </div>
          <div class="row">
            <div id="panelsStayOpen-collapsesix" class="accordion-collapse collapse show scrolldoc"
              aria-labelledby="panelsStayOpen-headingsix">
              <div class="accordion-body" *ngFor="let document of documentData ">
                <div class="experience d-flex justify-content-between">
                  <div class="experience-left col-lg-10">
                    <h6 class="modal-title" id="modal-basic-title"><a href="{{document?.path}}" target="_blank"
                        style="color: gray;text-decoration: unset !important;font-size: 14px;">
                        {{document?.name}}</a></h6>
                  </div>
                  <div class="inner-edit col-lg-2 " style="text-align: right;">
                    <a href="javascript:void(0)" (click)="deleteDocuments(document.id)"> <em class="bi bi-trash"
                        title="Delete" style="color: black;"></em></a>
                  </div>
                </div>
                <hr style="margin-top: 2px;">
              </div>
            </div>
            <div class="accordion-body" *ngIf="documentData?.length==0" style="margin-left: 20px;">No Documents
              added</div>
            <div style="margin-top: 10px;">
              <div class="spinner-border" *ngIf="projectapicall2">
                <span class="visually-hidden">Uploading...</span>
              </div>
              <button type="button" class="blue-btn ms-" (click)="UploadDocuments()" style="margin-top: 10px;float: right;"
                *ngIf="!projectapicall2">Upload & Save </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </form>

</div>
</div>


</ng-template>