import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions } from 'chart.js';
import { StorageKey, StorageService } from 'src/app/core/common/storage.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { DashboardService } from 'src/app/core/services/dashboard/dashboard.service';
import { UserService } from 'src/app/core/services/users/user.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  pageTitle: string = "Dashboard";
  showCreateButton: string = "";
  isSearchable: boolean = false;
  activeSearchesCount: number = 0;
  statusWiseSearchesCount: any[] = [];
  ytdProductionKeyMetrics: any;
  ttmProductionKeyMetrics: any;
  newBusinessPipeline: any[] = [];
  clientExpansion: any[] = [];
  referenceable_clients: any[] = [];
  userList: any = [];
  DashboardForm!: FormGroup;
  nodataFound: string = "";
  is_client_module_access_disabled: any

  candidate_YTD: number = 0;
  candidate_TTM: number = 0;
  client_YTD: number = 0;
  client_TTM: number = 0;
  AveLength_YTD: number = 0;
  AveLength_TTM: number = 0;

  public pieChartLabels: any[] = [];
  public pieChartDatasets: any[] = [];
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartOptions: any = {
    legend: {
      position: 'left',
      labels: {
        fontSize: 10
      }
    },
    responsive: false
  }
  currentUser: any;
  totalClientCount: number = 0;
  totalclientExpansionCount: number = 0;
  candidate_sources: any[] = [];


  Referenceable = {
    "client_count": 2,
    "client_names": "Kotak,Client Owner",
    "candidate_ids": "0,1"
  };



  constructor(public storageService: StorageService,
    private userService: UserService,
    private notificationService: NotificationService,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private router: Router) {
    this.is_client_module_access_disabled = this.storageService.getValue('is_client_module_access_disabled')
    let loginUserDetail: any = this.storageService.getValue(StorageKey.currentUser)
    this.currentUser = JSON.parse(loginUserDetail)
    let currentTenantId = this.storageService.getValue(StorageKey.currentTenantId)

    if (this.currentUser.role.name == "Candidate") {
      this.router.navigate(['candidate-profile']);
    }

    // else {

    // if (is_client_module_access_disabled == '1' || currentTenantId == '0')
    //   this.router.navigate(['search-list']);

    if (currentTenantId == '0')
      this.router.navigate(['search-list']);

    // }

  }


  ngOnInit(): void {
    this.getUserList();
    this.onBuildForm();
    this.getDashboardDetails();
  }

  onBuildForm() {
    this.DashboardForm = this.formBuilder.group({
      userId: [null]

    });
  }



  getDashboardDetails() {
    let userId = null;
    if (this.currentUser.role.id == 1)
      userId = this.DashboardForm.controls['userId'].value;
    else
      userId = this.currentUser.id;

    this.dashboardService.dashboardDetails({ user_id: userId }).subscribe({
      next: (response) => {

        this.activeSearchesCount = response.active_searches;
        this.statusWiseSearchesCount = response.candidate_status_wise_candidate_counts;
        // YTD
        this.ytdProductionKeyMetrics = response.ytd_production_key_metrics;
        this.candidate_YTD = this.ytdProductionKeyMetrics[2]?.value ?? 0;
        this.client_YTD = this.ytdProductionKeyMetrics[1]?.value ?? 0;
        this.AveLength_YTD = this.ytdProductionKeyMetrics[0]?.value ?? 0;
        // TTM
        this.ttmProductionKeyMetrics = response.ttm_production_key_metrics;
        this.candidate_TTM = this.ttmProductionKeyMetrics[2]?.value ?? 0;
        this.client_TTM = this.ttmProductionKeyMetrics[1]?.value ?? 0;
        this.AveLength_TTM = this.ttmProductionKeyMetrics[0]?.value ?? 0;


        this.newBusinessPipeline = response.new_business_pipeline;
        this.clientExpansion = response.client_expansion;
        this.candidate_sources = response.candidate_sources;


        this.referenceable_clients = response.referenceable_clients;

        this.calculateTotalClientCount();
        this.calculateclientExpansionCount();
        // let data: any[] = [];
        this.pieChartLabels = [];

        if (this.candidate_sources.length === 0) {
          this.nodataFound = "No Data Found";
        } else {
          // this.candidate_sources.forEach((e: any) => {
          //   e.percentage = Math.trunc(e.percentage.replace('%', ''));
          //   this.pieChartLabels.push(e.source + ' (' + e.percentage + '%)');
          //   data.push(Math.floor(e.percentage));
          // });
          // this.pieChartDatasets = [];
          // this.pieChartDatasets = [{
          //   data: data
          // }]

          const data: number[] = [];
          this.candidate_sources.forEach((e: any) => {
            // Remove the '%' character and convert to number
            let percentage = parseFloat(e.percentage.replace('%', ''));
            // Round the percentage
            percentage = Math.round(percentage);
            // Update the percentage property of the object
            e.percentage = percentage;
            // Push the label and rounded data
            this.pieChartLabels.push(e.source + ' (' + percentage + '%)');
            data.push(percentage);
          });
          this.pieChartDatasets = [];
          this.pieChartDatasets = [{
            data: data
          }];
        }
      },
      error: (err) => { }
    });
  }


  calculateTotalClientCount(): void {
    this.totalClientCount = this.newBusinessPipeline.reduce((total, item) => {
      return total + parseInt(item.client_count || '0', 10);
    }, 0);
  }

  calculateclientExpansionCount(): void {
    this.totalclientExpansionCount = this.clientExpansion.reduce((total, item) => {
      return total + parseInt(item.client_count || '0', 10);
    }, 0);
  }

  // getUserList(term: any = null) {
  //   let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
  //   if (currentTenant == '0') {
  //     return
  //   }
  //   let data = {};
  //   if (term && term.term) {
  //     data = { search: term.term };
  //   }
  //   this.userService.listByFilter(data).subscribe({
  //     next: (response) => {
  //       this.userList = response.users.data;

  //       if (this.currentUser.is_super_admin != 1) {
  //         let currentUserid = this.storageService.getValue(StorageKey.currentUserid);
  //         let isUserIdInList: boolean = false;
  //         this.userList.forEach((e: any) => {
  //           if (e.id == currentUserid) {
  //             isUserIdInList = true;
  //           }
  //         })
  //         if (!isUserIdInList) {
  //           this.userList.push(this.currentUser);
  //         }
  //       }
  //     },
  //     error: (error) => {
  //       ;
  //       console.log(error)
  //       // this.notificationService.showError(error.message);
  //     }
  //   });

  // }


  // getUserList(term: any = null) {
  //   let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
  //   if (currentTenant == '0') {
  //     return;
  //   }
  //   let data = {};
  //   if (term && term.term) {
  //     data = { search: term.term };
  //   }
  //   this.userService.listByFilter(data).subscribe({
  //     next: (response) => {
  //       this.userList = response.users.data;

  //       // Sort the userList in ascending order based on a property, e.g., 'name'
  //       this.userList.sort((a: any, b: any) => {
  //         if (a.name < b.name) {
  //           return -1;
  //         }
  //         if (a.name > b.name) {
  //           return 1;
  //         }
  //         return 0;
  //       });

  //       if (this.currentUser.is_super_admin != 1) {
  //         let currentUserid = this.storageService.getValue(StorageKey.currentUserid);
  //         let isUserIdInList: boolean = false;
  //         this.userList.forEach((e: any) => {
  //           if (e.id == currentUserid) {
  //             isUserIdInList = true;
  //           }
  //         });
  //         if (!isUserIdInList) {
  //           this.userList.push(this.currentUser);
  //         }
  //       }
  //     },
  //     error: (error) => {
  //       console.log(error);
  //       // this.notificationService.showError(error.message);
  //     }
  //   });
  // }


  getUserList(term: any = null) {
    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    if (currentTenant == '0') {
      return;
    }

    let data = {};
    if (term && term.term) {
      data = { search: term.term };
    }

    this.userService.listByFilter(data).subscribe({
      next: (response) => {
        this.userList = response.users.data;

        // Sort the userList in ascending order based on the 'name' property
        this.sortUserListByName(this.userList);

        if (this.currentUser.is_super_admin != 1) {
          let currentUserid = this.storageService.getValue(StorageKey.currentUserid);
          let isUserIdInList = this.userList.some((e: any) => e.id == currentUserid);
          if (!isUserIdInList) {
            this.userList.push(this.currentUser);
            // Re-sort the list to maintain the ascending order after adding the current user
            this.sortUserListByName(this.userList);
          }
        }
      },
      error: (error) => {
        console.log(error);
        // this.notificationService.showError(error.message);
      }
    });
  }

  sortUserListByName(userList: any[]) {
    userList.sort((a: any, b: any) => {
      if (!a.name) return 1; // Place items with no name at the end
      if (!b.name) return -1; // Place items with no name at the end
      return a.name.localeCompare(b.name); // Use localeCompare for proper alphabetical sorting
    });
  }





  public searchData() {

  }
}
