<app-app-jobheader></app-app-jobheader>

<div class="header">
    <div class="logo-left">
        <img [src]="logo_path" alt="" height="80" width="100">
        <h3>{{tenant_name}}</h3>
    </div>
</div>

<div class="section-hr"></div>

<div class="container">
    <div class="left-column">
        <h5>Applied Jobs</h5>
        <ul class="vertical-tab">
            <li *ngFor="let job of appliedjoblist?.data" [class.active]="openedJobId === job.id"
                (click)="openJobDetails(job.id)">
                <h3><strong>{{ job.name }}</strong></h3>
                <p>{{ job.city }}, {{ job.state }}</p>
                <small>Applied on {{ job?.created_at | formatDate }}</small>
            </li>
        </ul>
    </div>

    <div class="right-column">
        <div *ngIf="appliedjoblist">
            <div class="job-details" *ngFor="let job of appliedjoblist.data" [id]="'job' + job.id"
                [class.active]="openedJobId === job.id">
                <div class="jd-heading">
                    <div class="jdHeadLeft">
                        <h3><strong>{{ job.name }}</strong></h3>
                        <p>{{ job.city }}, {{ job.state }}</p>
                    </div>
                    <div class="jdHeadRight">
                        <a>Applied on {{ job?.created_at | formatDate }}</a>
                    </div>
                </div>
                <div class="hr"></div>
                <div class="job-desc">
                    <div [innerHTML]="safeDescriptions[appliedjoblist.data.indexOf(job)]"></div>
                </div>
            </div>
        </div>
    </div>

</div>