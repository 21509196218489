import { Component, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { CandidatejobsService } from 'src/app/core/services/candidatejobs/candidatejobs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PagerService } from 'src/app/core/common/pager.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
// import { StorageService } from 'src/app/core/common/storage.service';
import { StorageKey, StorageService } from 'src/app/core/common/storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface Location {
  city: string;
  state: string;
}


@Component({
  selector: 'app-public-joblist',
  templateUrl: './public-joblist.component.html',
  styleUrls: ['./public-joblist.component.css']
})
export class PublicJoblistComponent implements OnInit {

  publicjoblist: any = [];
  tenantlist: any = [];
  openedJobId: number | null = null;
  tenant: any;

  pageNumber: number = 10;
  current_page: any = 1;
  currentSearchValue: any;
  totalRows: number = 0;
  pager: any = {};

  // selectedLocation: string = '';
  safeDescriptions: SafeHtml[] = [];

  pageSize: number = 3;
  // allLocations: any[] = [];
  allLocations: Location[] = [];
  filteredJobs: any;
  job_id: any;
  candidate_id: any
  candidateData: any
  imgSrc: any
  userinitials: any;
  tenantid: any;
  @ViewChild('ApplyjobModal', { static: false }) ApplyjobModal: any;
  closeResult: string = "";

  pplyJobError: any;
  project_idError: any;
  urlError: any;
  tenant_idError: any;
  applysuccessMsg: any;
  applyJobError: any;
  uploadresumesuccess: any
  AddDocumentForm!: FormGroup;
  submitted: boolean = false;

  files: any;
  fileName!: string;
  filePath!: string;
  showuploadButton: boolean = false;

  jobapplyForm: FormGroup;
  showlinkedininput: boolean = false;
  linkedin_url: any;
  CreatDocumentsError: any;

  apply_job_id: any

  redirectUrl: string = '';
  selectedLocation: Location | null = null;


  constructor(
    private notificationService: NotificationService,
    private CandidatejobsService: CandidatejobsService,
    private route: ActivatedRoute,
    private pagerService: PagerService,
    private authservice: AuthService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private storageService: StorageService,
    private authServcie: AuthService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder
  ) {

    this.candidate_id = this.storageService.getValue('candidate_id')

    this.jobapplyForm = this.formBuilder.group({
      linkedinurl: [null],
      tenantid: [null],
    })

    this.AddDocumentForm = this.formBuilder.group({
      document: [null, Validators.required],
    })

  }

  get documentDetails() { return this.AddDocumentForm.controls }
  get validation() { return this.jobapplyForm.controls; }



  isLoggedIn(): boolean {
    return this.authservice.isLoggedIn();
  }

  ngOnInit(): void {

    this.tenant = this.route.snapshot.paramMap.get('tenant');
    this.current_page = localStorage.getItem('apply_current_page');

    if (this.tenant) {
      this.getjobList(null, this.tenant);
    } else
      this.getjobList();


    this.getlocations()


    if (this.authservice.isLoggedIn()) {
      this.apply_job_id = localStorage.getItem('apply_job_id');
      this.current_page = localStorage.getItem('apply_current_page');
      this.onload();
      // this.modalService.open(this.ApplyjobModal, { ariaLabelledBy: 'modal-basic-title', size: "md" }).result.then((result) => {
      //   this.closeResult = `Closed with: ${result}`;
      // }, () => {
      //   this.closeResult = `Dismissed `;
      // });

      const currentTenantId = this.storageService.getValue(StorageKey.currentTenantId);
      console.log(currentTenantId)
      if (currentTenantId === 'null') {
        console.log("Role is Null");
        // this.router.navigate(['dashboard']);
      } else {
        // console.log("Role is not Null");
        this.router.navigate(['dashboard']);
      }
    }
  }

  openJobDetails(jobId: number) {
    this.openedJobId = jobId;
    this.resetapplyerror()
  }

  onSearchClick(value: any) {
    this.currentSearchValue = value;
    this.pageNumber = 10;
    this.current_page = 1;
    this.getjobList({
      per_page: this.pageNumber,
      search: value,
      location: this.selectedLocation,
      page: this.current_page,
      candidate_id: this.candidate_id
    });
  }

  getjobList(data1: any = null, reset: boolean = false) {

    let data = {
      per_page: this.pageNumber,
      search: this.currentSearchValue,
      // location: this.selectedLocation,
      location: data1 && data1.location ? data1.location : null,
      page: this.current_page,
      candidate_id: this.candidate_id
    };


    if (data1) {
      data = { ...data, ...data1 };
    }


    this.CandidatejobsService.getjobList(this.tenant, data).subscribe({
      next: (response: any) => {
        this.publicjoblist = response.projects;
        this.tenantlist = response.tenant;
        this.current_page = response.projects.current_page;
        this.totalRows = response.projects.total;
        this.pager = this.pagerService.getPager(this.totalRows, this.current_page);
        this.updateSafeDescriptions();

        if (this.publicjoblist && this.publicjoblist.data.length > 0) {
          if (this.apply_job_id) {
            this.openJobDetails(Number(this.apply_job_id));
            this.applyjob(Number(this.apply_job_id))
          } else {
            this.openJobDetails(this.publicjoblist.data[0].id);
          }
        }
      },
      error: (error: any) => {
        switch (error.status) {
          case 404:
            this.notificationService.showError("Tenant Not Found");
            break;
          case 500:
            this.notificationService.showError("Tenant Not Found");
            break;
          default:
            this.notificationService.showError(error.message);
            break;
        }
      }
    });

  }


  sanitizeDescription(description: any): string {
    if (description == null) {
      return 'No job description found';
    }
    return this.sanitizer.bypassSecurityTrustHtml(description) as string;
  }


  updateSafeDescriptions(): void {
    this.safeDescriptions = this.publicjoblist.data.map((job: any) => this.sanitizeDescription(job.description));
  }

  filterByLocation() {
    this.pageNumber = 10;
    this.current_page = 1;
    this.getjobList({
      per_page: this.pageNumber,
      search: this.currentSearchValue,
      location: this.selectedLocation,
      page: this.current_page,
      candidate_id: this.candidate_id
    });
  }




  applyjob(jobid: number) {

    if (this.authservice.isLoggedIn()) {
      // console.log("Click On Apply")

      this.job_id = jobid
      this.filteredJobs = this.publicjoblist.data.filter((ele: any) => ele.id == jobid);
      if (this.filteredJobs.length > 0) {

        this.tenantid = this.filteredJobs[0].tenant_id;
        this.onload();
        this.modalService.open(this.ApplyjobModal, { ariaLabelledBy: 'modal-basic-title', size: "md" }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, () => {
          this.closeResult = `Dismissed `;
        });


        // localStorage.setItem('job_name', this.filteredJobs[0].name);
        // localStorage.setItem('job_city_name', this.filteredJobs[0].city);
        // const stateString = this.filteredJobs[0].state.toString();
        // localStorage.setItem('job_state_name', stateString);

        // const jobName = this.filteredJobs[0].name;
        // const jobCity = this.filteredJobs[0].city;
        // const jobState = this.filteredJobs[0].state.toString();

        // const queryParams = {
        //   job_id: this.job_id,
        //   job_name: jobName,
        //   job_city_name: jobCity,
        //   job_state_name: jobState
        // };

        // // // Navigating to the candidate-profile page with query parameters
        // this.router.navigate(['/candidate-profile'], { queryParams: queryParams });

      } else {
        console.log('No job found with ID:', jobid);
      }

      // localStorage.setItem('job_id', this.job_id);
      // console.log(true)
      // this.router.navigate(['/candidate-profile']);
    } else {
      this.job_id = jobid
      localStorage.setItem('apply_job_id', this.job_id);
      localStorage.setItem('apply_tetant_name', this.tenant);
      localStorage.setItem('apply_current_page', this.current_page);
      this.filteredJobs = this.publicjoblist.data.filter((ele: any) => ele.id == jobid);
      if (this.filteredJobs.length > 0) {
        // const jobName = this.filteredJobs[0].name;
        // const jobCity = this.filteredJobs[0].city;
        // let jobState;
        // if (this.filteredJobs[0].state) {
        //   jobState = this.filteredJobs[0].state.toString();
        // }

        let jobState;

        if (this.filteredJobs.length > 0 && this.filteredJobs[0].state !== undefined && this.filteredJobs[0].state !== null) {
          jobState = this.filteredJobs[0].state.toString();
        }

        const queryParams = {
          job_id: this.job_id,
          // job_name: jobName,
          // job_city_name: jobCity,
          // job_state_name: jobState,
          tenantId: this.tenant
        };

        // console.log(this.filteredJobs)
        // this.router.navigate(['/register']);
        // this.router.navigate(['/register'], { queryParams: { tenantId: this.tenant } });
        this.router.navigate(['/register'], { queryParams: queryParams });




      }
      // this.router.navigate(['/jobs/login']);
    }

  }


  setPage(page: number) {
    this.current_page = page;

    this.getjobList({
      per_page: this.pageNumber,
      search: this.currentSearchValue,
      location: this.selectedLocation,
      page: this.current_page,
      candidate_id: this.candidate_id
    });


  }

  getlocations() {
    this.CandidatejobsService.getlocationlist(this.tenant).subscribe({
      next: (response: any) => {
        this.allLocations = response.locations;
        this.allLocations.sort((a, b) => {
          return a.city.localeCompare(b.city);
        });
      },
      error: (error: any) => {
        console.log(error)
      }
    });
  }

  postJob() { }


  onload() {

    // console.log(this.candidate_id, "", this.tenant)

    // if (this.candidate_id ==="undefined" ) {
    // if (typeof this.candidate_id !== "undefined") {

    //   console.log("Onload function")

    // this.CandidatejobsService.candidateDetail(this.candidate_id).subscribe({
    //   next: (response: any) => {
    //     this.candidateData = response.candidate;
    //     this.imgSrc = response.candidate.in_profile_pic_url
    //     // console.log("Candidate Data is celled")
    //     // console.log(this.candidateData)

    //     response["initials"] = this.getInitials(response.candidate.in_first_name);
    //     this.userinitials = response.initials;

    //     this.linkedin_url = this.candidateData.linkedin_url;
    //     if (this.candidateData.in_public_identifier == null) {
    //       this.jobapplyForm.controls['linkedinurl'].setValue(this.linkedin_url);
    //       this.jobapplyForm.controls['linkedinurl'].enable();
    //       this.showlinkedininput = true
    //     }
    //     else {
    //       this.jobapplyForm.controls['linkedinurl'].setValue(this.linkedin_url);
    //       this.jobapplyForm.controls['linkedinurl'].disable();
    //       this.showlinkedininput = false
    //     }

    //     if (this.candidateData.documents && this.candidateData.documents.length > 0) {
    //       // console.log("document id present");
    //       this.showuploadButton = false
    //     } else {
    //       this.showuploadButton = true
    //       // console.log("document not present");
    //     }

    //   },
    //   error: (error) => {
    //     // this.notificationService.showError(error.message);
    //   }
    // });


    // } else {

    //   // this.router.navigate(['dashboard']);
    //   console.log("Candidate Id is Undefined")
    // }



    // console.log("Value of candidate_id:", this.candidate_id);
    if (this.candidate_id === 'undefined') {
      // console.log("Candidate Id is Undefined");
    } else {
      this.CandidatejobsService.candidateDetail(this.candidate_id).subscribe({
        next: (response: any) => {
          this.candidateData = response.candidate;
          this.imgSrc = response.candidate.in_profile_pic_url
          // console.log("Candidate Data is celled")
          // console.log(this.candidateData)

          response["initials"] = this.getInitials(response.candidate.in_first_name);
          this.userinitials = response.initials;

          this.linkedin_url = this.candidateData.linkedin_url;
          if (this.candidateData.in_public_identifier == null) {
            this.jobapplyForm.controls['linkedinurl'].setValue(this.linkedin_url);
            this.jobapplyForm.controls['linkedinurl'].enable();
            this.showlinkedininput = true
          }
          else {
            this.jobapplyForm.controls['linkedinurl'].setValue(this.linkedin_url);
            this.jobapplyForm.controls['linkedinurl'].disable();
            this.showlinkedininput = false
          }

          if (this.candidateData.documents && this.candidateData.documents.length > 0) {
            // console.log("document id present");
            this.showuploadButton = false
          } else {
            this.showuploadButton = true
            // console.log("document not present");
          }

        },
        error: (error) => {
          // this.notificationService.showError(error.message);
        }
      });
      // this.router.navigate(['dashboard']);
    }

  }

  getInitials(name: string): string {
    const words = name.split(' ');
    let initials = '';

    for (let i = 0; i < words.length; i++) {
      const word = words[i].trim();

      if (word.length > 0) {
        initials += word[0].toUpperCase();
      }
    }

    return initials;
  }




  logout() {
    this.authServcie.sign_out().subscribe({
      next: (responce: any) => {
        this.storageService.removeValue(StorageKey.currentTenantId);
        this.authServcie.logout();
        this.router.navigate(['/jobs']);
      },
      error: (err: any) => {
      }
    })
  }




  resetapplyerror() {
    this.project_idError = "";
    this.urlError = "";
    this.applyJobError = "";
    this.tenant_idError = "";
    this.applysuccessMsg = '';
    this.uploadresumesuccess = '';
  }

  resetjobdetails() {
    localStorage.removeItem('apply_job_id');
    localStorage.removeItem('apply_current_page');
  }

  attachFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;
  }


  uploadresume() {

    this.submitted = true;
    this.uploadresumesuccess = ''

    if (this.AddDocumentForm.invalid) {
      return;
    }

    if (!this.files) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('name', this.fileName);
    formData.append('document', this.files);

    this.CandidatejobsService.createDocument(this.candidate_id, formData).subscribe({
      next: (response: any) => {
        this.showuploadButton = false
        // this.onLoad();
        // this.modalService.dismissAll();
        // this.notificationService.showSuccess(response.message);
        this.uploadresumesuccess = "Resume uploaded successfully. Now you can apply for this opportunity."
        this.submitted = false;
        // this.onLoad();
      },
      error: (error) => {
        this.CreatDocumentsError = error.error.message;
        // switch (error.status) {
        //   case 422:
        //     if (error.error.document) {
        //       this.CreatDocumentsErrorDocument = error.error.document[0];
        //     }
        //     if (error.error.description) {
        //       this.CreatDocumentsErrorDescription = error.error.description[0];
        //     }
        //     break;
        //   default:
        //   //Nothing
        // }
        // this.notificationService.showError(error.message);
      }
    })

  }


  onapplysubmit() {
    this.submitted = true;
    this.applysuccessMsg = ""
    this.applyJobError = ""
    this.urlError = ''
    this.project_idError = ''
    this.uploadresumesuccess = ''

    if (this.jobapplyForm.invalid) {
      return;
    }

    let applydata = {
      candidate_id: this.candidate_id,
      project_id: this.job_id,
      tenant_id: this.tenant,
      url: this.jobapplyForm.controls['linkedinurl'].value,
    }

    this.CandidatejobsService.applyjob(applydata.candidate_id, applydata.project_id, applydata).subscribe({
      next: (response: any) => {
        this.jobapplyForm.reset();
        this.applysuccessMsg = response.message
        this.submitted = false;
        this.onload()
        localStorage.removeItem('apply_job_id');
        localStorage.removeItem('apply_current_page');
        this.filteredJobs[0].applied_at = new Date().toISOString();

      },
      error: (error) => {
        this.applyJobError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.project_id) {
              this.project_idError = error.error.project_id[0];
            }
            if (error.error.url) {
              this.urlError = error.error.url[0];
            }
            if (error.error.tenant_id) {
              this.tenant_idError = error.error.tenant_id[0];
            }
            break;
          default:
        }
        // this.notificationService.showError(error.message);
      }
    })

  }



  gotoprofile() {
    this.router.navigate(['/candidate-profile']);
  }


  formatLocation(location: { city: string | null, state: string | null }): string {
    if (location.city && location.state) {
      return `${location.city}, ${location.state}`;
    } else if (location.city) {
      return location.city;
    } else if (location.state) {
      return location.state;
    } else {
      return '';
    }
  }

  formatJobLocation(job: { city: string | null, state: string | null }): string {
    if (job.city && job.state) {
      return `${job.city}, ${job.state}`;
    } else if (job.city) {
      return job.city;
    } else if (job.state) {
      return job.state;
    } else {
      return '';
    }
  }












}
