import { Component,OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageKey, StorageService } from 'src/app/core/common/storage.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { CandidateService } from 'src/app/core/services/candidates/candidate.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { PagerService } from 'src/app/core/common/pager.service';
import { ProjectService } from 'src/app/core/services/projects/project.service';
import { TagserviceService } from 'src/app/core/services/tags/tagservice.service';
@Component({
  selector: 'app-list',
  templateUrl: './candidate-list.component.html',
  styleUrls: ['./candidate-list.component.css'],
})
export class CandidateListComponent implements OnInit {
  //tooltipHover:any=[];
  FilterForm!: FormGroup;
  candidateList: any = [];
  candidateListId: any = [];
  projectList: any = [];
  genderList: any = [{ name: "Male" }, { name: "Female" }, { name: "Other" }];
  diverseList: any = [{ name: "Yes", value: "1" }, { name: "No", value: 0 }];
  languageList: any = [];
  tagList: any = [];
  stateList: any = [];
  activePage: number = 1;
  closeResult: string = "";
  total: number = 10;
  to: number = 10;
  deleteId!: number;
  projectId!: number;
  searchClicked: string = '';
  deleteName: any
  pageTitle: string = 'Candidates';
  isSearchable: boolean = true;
  showCreateButton: string = 'candidate';
  pageNumber: number = 1;
  pageSize: number = 3;
  totalRows: number = 0;
  pager: any = {};
  selectSerchedProject: string | undefined;
  showlength!: number
  selectDeletedCandidte: string | undefined;
  // for sorting list
  faArrowUp = faCaretUp;
  faArrowDown = faCaretDown;

  isFocused = false;

  sortfield: any;
  sortmethod: any;
  multipleSorting: any = [];
  mouseOnColumnName: string = "";
  iconVisible: boolean = true;
  iconVisibleAsc: boolean = false;
  iconVisibleDesc: boolean = false;
  staticarrowsVisible: boolean = false;
  currentSortedColumn: any;
  currentSortedMethod: any;
  sortedColumn: any;
  rowIndex: any;
  showit!: boolean;
  tdElement: any;
  fullname: any;
  len!: number;
  currentSearchValue: string = "";
  currentAppliedFilter: any;
  showFilterOptions: boolean = false;
  languageSearchValue: string = "";
  sendToSearchDiv: boolean = false;
  projectapicall: boolean = false;
  get filterForm() { return this.FilterForm.controls; }
  getSearchList: any;
  selectedItems: number[] = [];
  selectAll: boolean = false;
  isChecked: boolean = false;
  CreateTagForm!: FormGroup
  submitted: boolean = false;
  projectList2: any = [];
  createTagData: any = {};
  imagepath: string = "";
  strengthId: any;
  imageselected: boolean = false;
  filterStrengthId: string = "";

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authservice: AuthService,
    private storageService: StorageService,
    private candidateService: CandidateService,
    private tagService: TagserviceService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private pagerService: PagerService

  ) { }
  ngOnInit(): void {
    this.onBuildForm();
    this.onLoad();

  }

  onLoad() {
    let listSettings: any = this.storageService.getValue(StorageKey.listSettings);
    if (listSettings !== "undefined" && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.candidates) {
      let existing_filter = this.getSearchList.candidates.apply_filter;
      if (existing_filter) {
        this.showFilterOptions = true;
        let apply_filter = existing_filter.split('&');
        apply_filter.forEach((e: any) => {
          let f_option = e.split('=');
          if (f_option[0] == 'search_current_position')
            this.FilterForm.controls['f_position'].setValue(f_option[1]);

          if (f_option[0] == 'search_current_company')
            this.FilterForm.controls['f_company'].setValue(f_option[1]);

          if (f_option[0] == 'search_in_gender')
            this.FilterForm.controls['f_gender'].setValue(f_option[1]);

          if (f_option[0] == 'search_custom_diversity')
            this.FilterForm.controls['f_diversity'].setValue(f_option[1]);

          if (f_option[0] == 'search_custom_compensation')
            this.FilterForm.controls['f_compensation'].setValue(f_option[1]);

          if (f_option[0] == 'language_id')
            this.FilterForm.controls['f_language'].setValue(f_option[1]);

          if (f_option[0] == 'tag_id')
            this.FilterForm.controls['f_tag'].setValue(f_option[1]);

          if (f_option[0] == 'contact_after')
            this.FilterForm.controls['f_contactAfter'].setValue(f_option[1]);

          if (f_option[0] == 'strength')
            this.showfilterimage('./assets/img/' + f_option[1] + '.png', f_option[1]);
        });
      }
      this.getCandidateList(null, this.getSearchList.candidates);
    } else
      this.getCandidateList({});


  }

  get createTagForm() { return this.CreateTagForm.controls; }

 

  onBuildForm() {

    this.CreateTagForm = this.formBuilder.group({
      projectId: [null]

    });

    this.FilterForm = this.formBuilder.group({
      f_position: [null],
      f_gender: [null],
      f_diversity: [null],
      f_city: [null],
      f_language: [null],
      f_state: [null],
      f_company: [null],
      f_tag: [null],
      f_compensation: [null],
      f_contactAfter: [null]
    });
  };

  getStateList() {
    this.projectService.listStateAll().subscribe({
      next: (response) => {
        this.stateList = response.states;
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      }
    });
  }

  getLanguageList(searchTerm: any = "") {
    let data: any;
    if (searchTerm.length >= 2)
      data = { search: this.languageSearchValue };
    this.candidateService.languageList(data).subscribe({
      next: (response) => {
        this.languageList = response;
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getTagList(searchTerm: any = "") {
    let data: any;
    if (searchTerm.length >= 2)
      data = { search: this.languageSearchValue };
    this.tagService.searchTagList(data).subscribe({
      next: (response: any) => {
        if (response && response.tags.data)
          this.tagList = response.tags.data
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getCandidateList(data1: any, data = {
    page: this.pageNumber,
    sort_field: this.currentSortedColumn,
    sort_method: this.currentSortedMethod,
    column_name: this.sortedColumn,
    apply_filter: this.currentAppliedFilter,
    search: this.currentSearchValue,
    multipleSorting: this.multipleSorting
  }, reset: boolean = false) {

    if (data1) {
      data.sort_field = data1.sort_field;
      data.sort_method = data1.sort_method;
      data.column_name = data1.column_name;
      if (data1.sort_field && data1.sort_method) {
        this.multipleSorting[data1.sort_field] = [data1.sort_field, data1.sort_method];
        data.multipleSorting = this.multipleSorting;
      }
    }


    //data={page :this.pageNumber, sort_field: string = '', sort_method: string ='', column_name: string=''}
    if (!reset) {
      if (this.getSearchList) {
        this.currentSearchValue = data.search;
        this.getSearchList.candidates = data;
        this.storageService.setValue('listSettings', JSON.stringify(this.getSearchList));
      } else
        this.storageService.setValue('listSettings', JSON.stringify({ candidates: data }));
    } else {
      data.search = "";
      data.page = 1;
    }

    this.candidateService.listByFilter(data).subscribe({
      next: (response) => {
        this.candidateList = response.candidates.data;
        this.candidateListId = response.candidates_ids;
        this.pageNumber = response.candidates.current_page;
        this.totalRows = response.candidates.total;
        this.pager = this.pagerService.getPager(this.totalRows, this.pageNumber);
        if (data.sort_field) {
          this.iconVisible = false;
          this.staticarrowsVisible = true;
          this.currentSortedColumn = data.column_name;
          this.sortedColumn = data.column_name;
          if (data.sort_method === 'desc') {
            this.iconVisibleDesc = true;
            this.iconVisibleAsc = false;
          }
          if (data.sort_method === 'asc') {
            this.iconVisibleAsc = true;
            this.iconVisibleDesc = false;
          }
          this.sortfield = response.candidates.sort_field;
          this.sortmethod = response.candidates.sort_method;
        }
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  resetListSettings() {
    let listSettings: any = this.storageService.getValue(StorageKey.listSettings);
    if (listSettings !== "undefined" && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.candidates) {
      delete this.getSearchList.candidates;
      this.storageService.setValue('listSettings', JSON.stringify(this.getSearchList));
    }
    this.currentSearchValue = "";
    this.multipleSorting = [];
    this.FilterForm.reset();
    this.currentAppliedFilter = null;
    this.imageselected = false;
    this.showFilterOptions = false;
    this.getCandidateList({}, undefined, true);
  }

  openSerchedProjectView(projectId: number, i: number, count: number) {
    this.projectId = projectId;
    if (count > 0) {
      this.candidateService.showProjectsCandidatewise(this.projectId).subscribe({
        next: (response) => {
          this.projectList = response.projects;

        },
        error: (error) => {
          this.notificationService.showError(error.message);
        },
      });
    }

  }

  applyFilter(page: number = 1) {
    let apply_filter = "";
    this.pageNumber = page;
    if (this.FilterForm.controls['f_position'].value) {
      if (apply_filter != "")
        apply_filter += "&";
      apply_filter += "search_current_position=" + this.FilterForm.controls['f_position'].value;
    }

    if (this.FilterForm.controls['f_company'].value) {
      if (apply_filter != "")
        apply_filter += "&";
      apply_filter += "search_current_company=" + this.FilterForm.controls['f_company'].value;
    }

    if (this.FilterForm.controls['f_gender'].value) {
      if (apply_filter != "")
        apply_filter += "&";
      apply_filter += "search_in_gender=" + this.FilterForm.controls['f_gender'].value;
    }

    if (this.FilterForm.controls['f_city'].value) {
      if (apply_filter != "")
        apply_filter += "&";
      apply_filter += "search_in_city=" + this.FilterForm.controls['f_city'].value;
    }

    if (this.FilterForm.controls['f_state'].value) {
      if (apply_filter != "")
        apply_filter += "&";
      apply_filter += "search_in_state=" + this.FilterForm.controls['f_state'].value;
    }

    if (this.FilterForm.controls['f_diversity'].value != undefined) {
      if (apply_filter != "")
        apply_filter += "&";
      apply_filter += "search_custom_diversity=" + this.FilterForm.controls['f_diversity'].value;
    }

    if (this.FilterForm.controls['f_compensation'].value) {
      if (apply_filter != "")
        apply_filter += "&";
      apply_filter += "search_custom_compensation=" + this.FilterForm.controls['f_compensation'].value;
    }

    if (this.FilterForm.controls['f_language'].value) {
      if (apply_filter != "")
        apply_filter += "&";
      apply_filter += "language_id=" + this.FilterForm.controls['f_language'].value;
    }

    if (this.FilterForm.controls['f_tag'].value) {
      if (apply_filter != "")
        apply_filter += "&";
      apply_filter += "tag_id=" + this.FilterForm.controls['f_tag'].value;
    }

    if (this.FilterForm.controls['f_contactAfter'].value) {
      if (apply_filter != "")
        apply_filter += "&";
      apply_filter += "contact_after=" + this.FilterForm.controls['f_contactAfter'].value;
    }

    if (this.filterStrengthId) {
      if (apply_filter != "")
        apply_filter += "&";

      apply_filter += "strength=" + this.filterStrengthId;
    }
    this.currentAppliedFilter = apply_filter;
    
    let data = {
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
      apply_filter: apply_filter
    };

    this.getCandidateList(data);
  }


  mouseEnter(columnname: any) {

    this.iconVisible = true;
    this.currentSortedColumn = columnname;
    this.mouseOnColumnName = columnname;

   
  }


  mouseLeave(columnname: any) {
    this.iconVisible = false;
    this.staticarrowsVisible = true;
    this.mouseOnColumnName = "";
  }

 


  openDeleteModal(content: any, deleteId: number, value: any) {
    this.selectDeletedCandidte = value.in_first_name + " " + value.in_last_name;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.deleteCandidate(deleteId);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }



  deleteCandidate(deleteId: number) {
    this.candidateService.deleteCandidateData(deleteId)
      .subscribe({
        next: (response) => {

          this.notificationService.showSuccess(response.message);
          this.getCandidateList({});

        },
        error: (error) => {
          this.notificationService.showError(error.message);
        },
      });
  }

  showCandidate(id: number) {
    this.router.navigate(['/candidate/' + id]);
  }

  public showFilter() {
    this.getStateList();
    this.showFilterOptions = !this.showFilterOptions;
    if (!this.showFilterOptions) {
      this.currentAppliedFilter = null;
    }
  }

  public searchData(value: any) {
    this.currentSearchValue = value;
    this.pageNumber = 1;
    this.getCandidateList({ page: this.pageNumber, sort_field: this.currentSortedColumn, sort_method: this.currentSortedMethod, column_name: this.sortedColumn, search: value });
  }

  setPage(page: number) {
    this.pageNumber = page;
    this.applyFilter(page)
   
  }

  showProject(id: number) {
    this.router.navigate(['/search/' + id]);
  }



  openSerchedproject(content: any, projectId: number, count: number, value: any) {
    if (count > 0) {
      this.selectSerchedProject = value.name;
      this.fullname = value?.in_first_name + " " + value?.in_last_name;
      this.serchedProjectViewCandidatewise(projectId, count);
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
        (result) => {

          this.closeResult = `Closed with: ${result}`;

        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
    }

  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  serchedProjectViewCandidatewise(projectId: number, count: number) {
    this.projectId = projectId;

    this.projectList = [];
    if (count > 0) {
      this.candidateService.showProjectsCandidatewise(this.projectId).subscribe({
        next: (response) => {
          this.projectList = response.projects;

          let length = this.projectList.length;
          this.len = length - 1
          this.showlength = this.len;

        },
        error: (error) => {
          this.notificationService.showError(error.message);
        },
      });
    }

  }

 

  toggleItemSelection(id: number) {
    if (this.selectedItems.includes(id)) {
        this.selectedItems = this.selectedItems.filter(item => item !== id);
    } else {
        this.selectedItems.push(id);
    }

    this.sendToSearchDiv = this.selectedItems.length > 0;

    // Move the sort operation to separate variables
    const sortedSelectedItems = this.selectedItems.sort().join(',');
    const sortedCandidateListId = this.candidateListId.sort().join(',');

    if (sortedSelectedItems !== sortedCandidateListId) {
        this.selectAll = false;
    }
}

  toggleSelectAll() {
    if (this.selectAll) {
      // If "Select All" checkbox is checked, select all items
      this.selectedItems = this.candidateListId.map((element: number) => element);
    } else {
      // If "Select All" checkbox is unchecked, clear the selection
      this.selectedItems = [];
    }
    this.sendToSearchDiv = this.selectedItems.length > 0;


  }

  openPopupOfSearch(content: any, selectedItems: number[]) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {

        this.closeResult = `Closed with: ${result}`;

      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
    this.getProjectList();

  }




  getProjectList() {
    let currentTenant = this.storageService.getValue(StorageKey.currentTenantId);
    if (currentTenant == '0') {
      return
    }
    this.projectService.listAll().subscribe({
      next: (response) => {
        this.projectList2 = response.projects.data;
        this.projectList2 = this.projectList2.filter((ele: any) => ele.project_status_id == 1);
        this.projectList2.forEach((ele: any) => { ele["dropdown"] = ele?.name + (ele.client?.name ? " [" + ele.client?.name + "]" : "") })
      },
      error: (error) => {
      }
    });
  }

  onProjectSearch(term: any) {
    let searchValue = term.term;

    if (true)
    // searchValue.length>=2
    {
      let data = { search: searchValue };
      this.projectService.searchProjectwiseList(data).subscribe({
        next: (response: any) => {

          this.projectList2 = response.projects.data;
          this.projectList2 = this.projectList2.filter((ele: any) => ele.project_status_id == 1);
          this.projectList2.forEach((ele: any) => { ele["dropdown"] = ele?.name + (ele.client?.name ? " [" + ele.client?.name + "]" : "") })
        },
        error: (err) => {

        }
      })
    }
  }

  attachMultipleCandidatetoSearchForBulkAttach() {
    this.submitted = true;
    if (this.CreateTagForm.invalid) {
      return;
    }


    if (this.createTagForm['projectId'].value) {
      this.createTagData.project_id = this.createTagForm['projectId'].value;
    }


    let candId = this.selectedItems;

    this.createTagData.candidate_ids = candId;


    if (this.createTagData.candidate_ids == 'null' || this.createTagData.candidate_ids == "") {
      return
    }


    this.projectapicall = true;
    this.candidateService.attachMultipleCandidatetoSearch(this.createTagData).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.CreateTagForm.reset();
        this.notificationService.showSuccess(response.message);
        this.projectapicall = false;
        this.submitted = false;
        this.selectedItems = [];
        this.sendToSearchDiv = false;
      },
      error: (error) => {
        this.projectapicall = false;
      }
    });

  }

  showimage(data: string, strengthId: any, candidateId: any, changetype: any) {
    this.imagepath = data;
    this.strengthId = strengthId;

  }

  showfilterimage(data: string, strengthId: any) {
    this.imagepath = data;
    this.imageselected = true;
    this.filterStrengthId = strengthId;


  }

  // showimage(data:string,strengthId:any,candidateId:any,changetype:any)
  // {
  //   this.imagepath=data;
  //   // this.imageselected=true;
  //   this.strengthId=strengthId;
  //   this.candidatechange(candidateId,changetype);

  // }

  // candidatechange(candidateid:any = "",changetype:any,selectedStatus?:any)
  // {
  //   let projectId = this.route.snapshot.params['id']
  //   let responseMessage:string = "";
  //   // let data={
  //   //   project_id:projectId,
  //   //   // strength:0,
  //   //   rank:0,
  //   //   // candidate_status_id:0,
  //   //   // allow_export:0
  //   // };
  //   switch (changetype){
  //     case 'candidateRank':

  //       let inputValue=(<HTMLInputElement>document.getElementById('candidateRank_'+candidateid)).value;
  //       this.projectRank= Number(inputValue);
  //       this.projectUpdate={
  //         project_id:projectId,
  //         rank:this.projectRank,
  //       }
  //       responseMessage = "Candidate rank updated";

  //     break;

  //     case 'candidateStatus':
  //       this.projectUpdate={
  //         project_id:projectId,
  //         candidate_status_id:selectedStatus=='null'?null:selectedStatus
  //       }


  //     break;
  //     case 'candidate_strength':
  //       this.projectUpdate={
  //         project_id:projectId,
  //         strength:this.strengthId
  //       }

  //     break;
  //     case 'allow_export':
  //       this.projectUpdate={
  //         project_id:projectId,
  //         allow_export:this.allowExportcheck
  //       }


  //     break;
  //   }


  //   this.candidateService.updateProjectStatus(candidateid,this.projectUpdate).subscribe({
  //     next:(response:any)=>{
  //       this.onLoad();
  //       // window.location.reload()
  //       this.notificationService.showSuccess(responseMessage);
  //     },
  //     error:(err:any)=>{

  //     }
  //   })

  // }




}


