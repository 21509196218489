import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../common/storage.service';

@Injectable({
  providedIn: 'root'
})
export class TenantserviceService {

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService) { }

    getTenentData(data:any)
    {
      return this.httpClient.get(`${environment.apiUrl2}tenants/`+data.id);
    }

    updateTenentData(id:number,data:any): Observable<any>{
      return this.httpClient.post(`${environment.apiUrl2}tenants/`+id, data);
    }


    listByFilter(data?: any): Observable<any>{
      // console.log(data)
      let get_params:string = '';
  
      if(data.sort_field && data.sort_method){
        get_params += `sort_field=`+ data.sort_field + `&sort_method= `+ data.sort_method;
      }
      if(data.page){
        if(get_params != ''){
          get_params +=`&`
        }
        get_params += `page=`+ data.page ;
      }
      if(data.search){
        if(get_params != ''){
          get_params +=`&`
        }
        get_params += `search=`+ data.search;
      }
      
      return this.httpClient.get(`${environment.apiUrl2}tenants?`+get_params);
    }

    // getdata()
    // {
    //   return this.httpClient.get(`${environment.apiUrl2}tenants?`); 
    // }

    create(data:any): Observable<any>{
      return this.httpClient.post(`${environment.apiUrl2}tenants`, data);
    }

    listAll(): Observable<any>{
      return this.httpClient.get(`${environment.apiUrl2}tenants`);
    }
    getDeleteOTP(id:any): Observable<any>{
      return this.httpClient.get(`${environment.apiUrl2}tenants/${id}/two-factor`);
    }
    deleteTenant(id:any,data:any): Observable<any>
    {
      return this.httpClient.post(`${environment.apiUrl2}tenants/${id}/delete`,data);
    }
}
