<div class="navbar-collapse" id="n_bar" *ngIf="expanded" @slidein style="margin-top: 50px !important;">
  <ul class="navbar-nav">
  

    <li class="nav-item" *ngIf="(tenantRoleNotshow)">
      <a class=" text-light" title="Dashboard" routerLink="/dashboard">
        <em class="bi tag-icon bi-speedometer"></em><span class="me-1">Dashboard</span></a>
    </li>





    <li class="nav-item" *ngIf="(tenantRoleNotshow && is_client_module_access_disabled == '0')">
      <a class="text-light" title="Searches" [routerLink]="['/search-list']" [queryParams]="{ type: 'search' }"  (click)="Searches()">
        <img src="./assets/img/teal-logo.png" class="menu-svg tag-icon" alt="">
        <span class="me-1">Searches</span>
      </a>
    </li>

    <li class="nav-item"
      *ngIf="(tenantRoleNotshow && is_client_module_access_disabled == '0' && is_public_search == '1')">
      <a class="text-light" title="Job Posting" [routerLink]="['/search-list']" [queryParams]="{ type: 'jobposting' }"  (click)="handleClick()">
        <img src="./assets/img/job-logo2.png" class="menu-svg tag-icon" alt="">
        <span class="me-1">Job Posting</span>
      </a>
    </li>

    <li class="nav-item"
      *ngIf="(tenantRoleNotshow && is_client_module_access_disabled == '1' && is_public_search =='1')">
      <a class="text-light" title="Job Posting" routerLink="/search-list" [queryParams]="{ type: 'jobposting' }" (click)="handleClick()">
        <img src="./assets/img/job-logo2.png" class="menu-svg tag-icon" alt="">
        <span class="me-1">Job Posting</span>
      </a>
    </li>

  

    <li class="nav-item" *ngIf="tenantRoleNotshow">
      <a class="text-light" title="Candidates" routerLink="/candidate-list">
        <img src="./assets/img/users.svg" class="menu-svg tag-icon" alt="">
        <span class="me-1">Candidates</span></a>
    </li>

    <li class="nav-item i-link" *ngIf="(tenantRoleNotshow && is_client_module_access_disabled == '0')">
      <a class="text-light" title="Clients" routerLink="/client-list">
        <em class="bi tag-icon bi-buildings-fill" style="top: 0;"></em>
        <span class="me-1"> Clients</span></a>
    </li>


    <li class="nav-item i-link" *ngIf="tenantRoleshow">
      <a class="text-light" title="Tenants" routerLink="/tenants-list"><em class="bi tag-icon bi-building-fill"></em><span
          class="me-1">Tenants</span></a>
    </li>

  

    <li class="nav-item i-link" *ngIf="tenantRoleshow && userRoleshow"><a class="text-light" title="Client Status"
        routerLink="/client-status"><em class="bi tag-icon bi-card-checklist"></em><span class="me-1">Client
          Stage</span></a>
    </li>

    <li class="nav-item i-link" *ngIf="tenantRoleshow && userRoleshow"><a class="text-light" title="Candidate Status"
        routerLink="/candidate-status"><em class="bi tag-icon bi-person-lines-fill"></em><span class="me-1">Candidate
          Status</span></a>
    </li>


  </ul>
</div>