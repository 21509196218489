<app-app-sub-header class="top-row d-flex justify-content-between" (onRefreshClicked)="resetListSettings()"
  (searchClicked)="searchData($event)" [isSearchable]="isSearchable" [pageTitle]="pageTitle"
  [showCreateButton]="showCreateButton" [EditId]="EditId"></app-app-sub-header>
<div class="bottom-table">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col" style="width:35%" (mouseenter)="mouseEnter('name')" (mouseleave)="mouseLeave('name')">
          Name
          <span *ngIf="currentSortedColumn != 'name' && mouseOnColumnName == 'name'"
            (click)="getUserList({sort_field:'name',sort_method:'desc',column_name:'name'})" class="sortbyspanr"
            [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'name'" [hidden]="!iconVisibleDesc"
            (click)="getUserList({sort_field:'name',sort_method:'asc',column_name:'name'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'name'" [hidden]="!iconVisibleAsc"
            (click)="getUserList({sort_field:'name',sort_method:'desc',column_name:'name'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>
        <th scope="col" style="width:40%" (mouseenter)="mouseEnter('email')" (mouseleave)="mouseLeave('email')">
          Email
          <span *ngIf="currentSortedColumn != 'email' && mouseOnColumnName == 'email'"
            (click)="getUserList({sort_field:'email',sort_method:'desc',column_name:'email'})" class="sortbyspanr"
            [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'email'" [hidden]="!iconVisibleDesc"
            (click)="getUserList({sort_field:'email',sort_method:'asc',column_name:'email'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'email'" [hidden]="!iconVisibleAsc"
            (click)="getUserList({sort_field:'email',sort_method:'desc',column_name:'email'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>

        <th scope="col" style="width:10%" (mouseenter)="mouseEnter('role_id')" (mouseleave)="mouseLeave('role_id')">
          Role
          <span *ngIf="currentSortedColumn != 'role_id' && mouseOnColumnName == 'role_id'"
            (click)="getUserList({sort_field:'role_id',sort_method:'desc',column_name:'role_id'})" class="sortbyspanr"
            [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'role_id'" [hidden]="!iconVisibleDesc"
            (click)="getUserList({sort_field:'role_id',sort_method:'asc',column_name:'role_id'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'role_id'" [hidden]="!iconVisibleAsc"
            (click)="getUserList({sort_field:'role_id',sort_method:'desc',column_name:'role_id'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>

        <th scope="col" style="width:10%;text-align: center;" (mouseenter)="mouseEnter('is_active')"
          (mouseleave)="mouseLeave('is_active')">
          Status
          <span *ngIf="currentSortedColumn != 'is_active' && mouseOnColumnName == 'is_active'"
            (click)="getUserList({sort_field:'is_active',sort_method:'desc',column_name:'is_active'})"
            class="sortbyspanr" [hidden]="!iconVisible ">
            <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanr" *ngIf="currentSortedColumn == 'is_active'" [hidden]="!iconVisibleDesc"
            (click)="getUserList({sort_field:'is_active',sort_method:'asc',column_name:'is_active'})">
            <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
          </span>
          <span class="sortbyspanl" *ngIf="currentSortedColumn == 'is_active'" [hidden]="!iconVisibleAsc"
            (click)="getUserList({sort_field:'is_active',sort_method:'desc',column_name:'is_active'})">
            <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
          </span>
        </th>
        <th scope="col" style="width:5%">
          Action
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of userList">
        <td> <span class="name">
            <div class="form-check">
              <label class="form-check-label" for="flexCheckDefault">
                <button class="profile-name" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                  style="padding: 0px;">
                  <img *ngIf="user.avatar_path !== null" src="{{user.avatar_path}}" alt="" />
                  <span *ngIf="user.avatar_path === null" style="padding-left: 0px;">{{user.initials}}</span>
                </button>
                <span style="margin-left: 20px;">{{user.name}} </span>
              </label>
            </div>
          </span> </td>
        <td>{{user.email}}</td>
        <td style="text-align: left;">{{user.role.name}}</td>
        <td>
          <span *ngIf="user.is_active === 1" class="badge text-bg-success">Active</span>
          <span *ngIf="user.is_active === 0" class="badge text-bg-warning">Inactive</span>
        </td>
        <td>
          <div style="cursor: pointer;">
            <a (click)="open(UpdateUser,user.id)"><em class="bi bi-pencil-square me-1" title="Edit"></em></a>
            <a (click)="openDeleteModal(content,user.id,user)" [hidden]="userid == user.id"><em class="bi bi-trash me-1"
                title="Delete"></em></a>
            <a *ngIf="user.ms_graph_access_token_generated_at" (click)="openUnauthorizeModal(unauthorize,user.id,user)">
              <em class="bi bi-box-arrow-right" title="Disconnect Email Login"></em></a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="pagination-main">
  <label for="" class="list-totalrecords">Total Records: {{totalRows}}</label>
  <ul *ngIf="pager.pages && pager.pages.length && totalRows > pageSize" class="pagination justify-content-end m-0">
    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
      <a *ngIf="pager.currentPage === 1; else elseFirst" class="page-link page-show"><<</a>
      <ng-template #elseFirst>
        <a (click)="setPage(1)" class="page-link"><<</a>
      </ng-template>
    </li>
    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
      <a *ngIf="pager.currentPage === 1; else elsePrevious" class="page-link page-show"><</a>
      <ng-template #elsePrevious>
        <a (click)="setPage(pager.currentPage - 1)" class="page-link"><</a>
      </ng-template>
    </li>
 
    <li class="page-item ">
      <a class="page-link">{{pager.currentPage}}</a>
    </li>
    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
      <a *ngIf="pager.currentPage === pager.totalPages; else elseNext" class="page-link page-show">></a>
      <ng-template #elseNext>
        <a (click)="setPage(pager.currentPage + 1)" class="page-link">></a>
      </ng-template>
    </li>
    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
      <a *ngIf="pager.currentPage === pager.totalPages; else elseLast" class="page-link page-show">>></a>
      <ng-template #elseLast>
        <a (click)="setPage(pager.totalPages)" class="page-link">>></a>
      </ng-template>
    </li>
  </ul>
</div>


<ng-template #UpdateUser let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit User</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="CreateUserForm">
    <div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserError">
        {{createUserError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorAvatar">
        {{createUserErrorAvatar}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorName">
        {{createUserErrorName}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorEmail">
        {{createUserErrorEmail}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorRoleid">
        {{createUserErrorRoleid}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createUserErrorIsactive">
        {{createUserErrorIsactive}}</div>

    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for="" >Image</label>
        <div class="inp-group">
          <input type="file" [hidden]="!dropimg" accept="image/*" multiple #selectFile max-file-size="1024"
            formControlName="avatar" class="form-control" (change)="attachFiles($event)" />
        </div>
        <div class="col-lg-12 row" style="margin-top: 10px;" *ngIf="previewImg">
          <div class="col-3" style="padding-right: 0px;">
            <img [src]="url" *ngIf="url !== null" height="100" width="100" alt="">
            <span *ngIf="url === null"
              style="padding-left:0px; font-size: 60px; padding:14px; border:1px solid gray; border-radius:0px">{{editedInitials}}</span>

          </div>
          <div class="col-1" style="padding-right: -30px;padding-left: 10px;">
            <a type="button" class="btn btn-xs btn-danger rmv" (click)="resetImageFile(selectFile)"
              style="margin-top: 70px;" title="Remove image">X</a>
          </div>
          <div class="col-2" style="padding-right: 0px;padding-left: 0px;">
            <button type="button" class="blue-btn" (click)="selectFile.click()">Change</button>
          </div>
          <div class="col-3" style="display:none">
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="">Name</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="text" placeholder="Enter name" formControlName="name" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f['name'].errors }">
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && f['name'] && f['name'].errors && f['name'].errors['required']">
          Name is required
        </div>
      </div>

      <div class="mb-3">
        <label for="">Email</label>
        <input type="text" placeholder="Enter email Id" formControlName="email" class="form-control">
        <div style="color: red; font-size: 14px;"
          *ngIf="submitted && f['email'] && f['email'].errors && f['email'].errors['pattern']">
          Please enter valid Email address.
        </div>
      </div>

      <div class="row">

        <div class="col-6" *ngIf="currentTenantId!='0'">
          <div class="mb-3">
            <label for="">Role</label>
            <select class="form-select" formControlName="role_id">
              <option value="1">Admin</option>
              <option value="2">User</option>
            </select>
            <div style="color: red; font-size: 14px;"
              *ngIf="submitted && f['role_id'] && f['role_id'].errors && f['role_id'].errors['required']">
              User Role is required.
            </div>
          </div>

        </div>

        <div class="{{(currentTenantId!='0')?'col-6':'col-12'}}">
          <label for=""> Status</label>
          <select name="" id="" class="form-select" formControlName="is_active">
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
          <div style="color: red; font-size: 14px;"
            *ngIf="submitted && f['is_active'] && f['is_active'].errors && f['is_active'].errors['required']">
            Status is required.
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="mb-3 password-container">
            <label for="">Password</label>
            <input type="password" placeholder="Enter password" formControlName="password" class="form-control"
              *ngIf="!showPassword">
            <input type="text" placeholder="Enter password" formControlName="password" class="form-control"
              *ngIf="showPassword">
            <em class="fa fa-eye" (click)="togglePassword()" *ngIf="showPassword" style="top: 47%;"></em>
            <em class="fa fa-eye-slash" (click)="togglePassword()" *ngIf="!showPassword" style="top: 47%;"></em>
         
            <small class="text-primary">Leave this field empty unless you want to change it.</small>
          </div>
        </div>


      </div>
    </div>
    <div class="modal-footer">
      <div class="spinner-border" *ngIf="projectapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter" (click)="modal.close('Save click')" *ngIf="!projectapicall">Cancel</button>
      <button type="button" class="blue-btn ms" (click)="UpdateUsers()" *ngIf="!projectapicall">Save</button>
    </div>

  </form>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete User</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3">
        <p>
          Are you sure you want to delete {{selectDeletedUser}} ?
        </p>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary cancelbutton"
      (click)="modal.dismiss('cancel click')">Cancel</button>
    <button type="button" class="btn btn-danger dangerbtn" (click)="modal.close('Ok click')">Delete</button>
  </div>
</ng-template>

<!-- unauthorize User Modal  -->
<ng-template #unauthorize let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Disconnect Email Login</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3">
        <p>
          Are you sure you want to disconnect the email login of  {{selectDeletedUser}} ?
        </p>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary cancelbutton"
      (click)="modal.dismiss('cancel click')">Cancel</button>
    <button type="button" class="btn btn-danger dangerbtn" (click)="modal.close('Ok click')">Disconnect</button>
  </div>
</ng-template>