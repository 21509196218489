import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '../../common/storage.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(private httpClient: HttpClient,
    private storageService: StorageService) { }

  updateTaskData(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}tasks/` + id, data);
  }

  getTaskData(data?: any): Observable<any> {
    // console.log(data,"in servce")
    return this.httpClient.get(`${environment.apiUrl}tasks/` + data.id);
  }

  create(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}tasks`, data);
  }

  listAll(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}tasks`);
  }

  listByFilter(data?: any): Observable<any> {
    let get_params: string = '';
    if (data.load_more && data.next_page_url) {
      return this.httpClient.get(data.next_page_url);
    } else {
      if (data.sort_field && data.sort_method) {
        get_params += `sort_field=` + data.sort_field + `&sort_method= ` + data.sort_method;
      }
      if (data.page) {
        if (get_params != '') {
          get_params += `&`
        }
        get_params += `page=` + data.page;
      }
      if (data.search) {
        if (get_params != '') {
          get_params += `&`
        }
        get_params += `search=` + data.search;
      }
      if (data.type) {
        if (get_params != '') {
          get_params += `&`
        }
        get_params += `type=` + data.type;
      }


      if (data.user_id && data.user_id != null) {

        if (get_params != '') {
          get_params += `&`
        }
        get_params += `user_id=` + data.user_id;
      }

      return this.httpClient.get(`${environment.apiUrl}tasks?` + get_params);
    }
  }


  deleteTaskData(id: number): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}tasks/` + id);
  }
}
