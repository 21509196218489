import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../../common/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService) { }

  create(data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}clients`, data);
  }

  listAll(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}clients`);
  }

  listPagewise(data?: any): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}clients?page=` + data.page);
  }
  listSearch(data?: any): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}clients?search=` + data.search);
  }

  listSort(data?: any): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}clients?sort_field=` + data.sort_field + `&sort_method= ` + data.sort_method);
  }

  getClientData(data?: any): Observable<any> {
    //console.log(data,"in servce")
    return this.httpClient.get(`${environment.apiUrl}clients/` + data.id);
  }
  updateClientData(id: number, data: any): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}clients/` + id, data);
  }

  listByFilter(data?: any): Observable<any> {
    let get_params: string = '';
    if (data && data.sort_field && data.sort_method) {
      get_params += `sort_field=` + data.sort_field + `&sort_method= ` + data.sort_method;
    }
    if (data && data.page) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `page=` + data.page;
    }
    if (data && data.search) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `search=` + data.search;
    }
    return this.httpClient.get(`${environment.apiUrl}clients?` + get_params);
  }

  deleteClientData(id: number): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}clients/` + id);
  }


  listStateAll(): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}states`);
  }

  searchClientwiseList(data?: any) {
    let get_params: string = '';

    if (data.search) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `search=` + data.search;
    }

    if (data.search_name) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `search_name=` + data.search_name;
    }
    return this.httpClient.get(`${environment.apiUrl}clients?` + get_params);
  }

  createDocument(id: number, data: any) {
    return this.httpClient.post(`${environment.apiUrl}clients/` + id + "/documents", data);
  }

  deleteDocument(id: number, documentId: any) {
    return this.httpClient.delete(`${environment.apiUrl}clients/` + id + "/documents/" + documentId);
  }

  getDocumentData(id: number): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}clients/` + id);
  }

  clientStatusListByFilter(data?: any): Observable<any> {
    let get_params: string = '';
    if (data.sort_field && data.sort_method) {
      get_params += `sort_field=` + data.sort_field + `&sort_method= ` + data.sort_method;
    }

    if (data.page) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `page=` + data.page;
    }
    if (data.search) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += `search=` + data.search;
    }
    if (data.apply_filter) {
      if (get_params != '') {
        get_params += `&`
      }
      get_params += data.apply_filter;
    }
    return this.httpClient.get(`${environment.apiUrl}client-statuses?` + get_params);
  }

  deleteClientStatus(id: number): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}client-statuses/` + id);
  }

  createClientStatus(data: any): Observable<any> {
    data.is_active = 1;
    return this.httpClient.post(`${environment.apiUrl}client-statuses`, data);
  }

  getClientStatus() {
    return this.httpClient.get(`${environment.apiUrl}client-statuses`);
  }

  getClientStatusDetail(id: number) {
    return this.httpClient.get(`${environment.apiUrl}client-statuses/` + id);
  }

  updateClientStatus(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${environment.apiUrl}client-statuses/` + id, data);
  }


  getclientprobability() {
    return this.httpClient.get(`${environment.apiUrl}client-probability-statuses`);
  }

  createclient(id: number, client_id: any, is_already_linked: any) {
    return this.httpClient.post(`${environment.apiUrl}candidates/` + id + "/clients",
      { client_id: client_id, is_already_linked: is_already_linked });
  }

  deleteclient(id: number, client_id: any) {
    const requestBody = { client_id: client_id };
    return this.httpClient.post(`${environment.apiUrl}candidates/${id}/clients/${client_id}`, requestBody);
  }


}
