<div class="login_page">
  <div class="container custom_login" id="container">
    <div class="form-container log-in-container">
      <div class="left-container">
        <form [formGroup]="loginFrm" class="w-100 row" *ngIf="showLogin">
          <p class="text-start" style="color: rgb(24, 105, 24); font-size: 14px; text-align: left"
            *ngIf="passwordRecoveryMailSent">
            {{ passwordRecoveryMailSent }}
          </p>
          <div *ngIf="showforgot">
            <h1>Login</h1>

            <div>
              <input type="email" [readonly]="tenantsadmin" class="form-control" formControlName="email"
                placeholder="Email" autofocus [ngClass]="{ 'is-invalid': submitted2 && f['email'].errors }" />
              <div class="rememberMe" *ngIf="!tenantsadmin">
                <label>
                  <input style="width: unset" type="checkbox" formControlName="remember_me" checked />
                  &nbsp;Remember me
                </label>
              </div>

              <span style="color: red; font-size: 14px; text-align: left" class="w-100 col-12 ps-0" *ngIf="
                  submitted2 &&
                  f['email'] &&
                  f['email'].errors &&
                  f['email'].errors['required']
                ">
                Email is required
              </span>
              <span style="color: red; font-size: 14px; text-align: left" class="w-100 col-12 ps-0" *ngIf="
                  f['email'] &&
                  f['email'].errors &&
                  f['email'].errors['pattern']
                ">
                Invalid Email Address
              </span>
            </div>

            <div *ngIf="tenantsadmin && !enterotp" style="width: 284px">
              <div style="margin-top: 20px" [hidden]="
                  (this.superadminflag === false && this.len == 1) ||
                  this.candidateflag === true
                ">
                <ng-select [items]="tenantsList" [clearable]="false" style="text-align: left"
                  [(ngModel)]="selectedtenantValue" (ngModelChange)="dropdownselect($event)"
                  class="form-control form-select" bindLabel="name" bindValue="id" formControlName="tenant">
                </ng-select>
              </div>



              <div style="margin-top: 20px" class="password-container">
                <div class="password-container" style="padding: 10; width: 284px; height: 40px !important">
                  <input type="password" style="padding: 10; width: 260px; height: 40px !important" class="form-control"
                    placeholder="Password" formControlName="password" [ngClass]="{
                      'is-invalid': submitted && f['password'].errors
                    }" *ngIf="!showPassword" />
                  <input type="text" style="padding: 10; width: 260px; height: 40px !important" class="form-control"
                    placeholder="Password" formControlName="password" [ngClass]="{
                      'is-invalid': submitted && f['password'].errors
                    }" *ngIf="showPassword" />
                  <!-- <em class="fa fa-eye" (click)="togglePassword()" *ngIf="showPassword"
                    style="margin-right: 40px !important"></em>
                  <em class="fa fa-eye-slash" (click)="togglePassword()" *ngIf="!showPassword"
                    style="margin-right: 40px !important"></em> -->
                  <em class="fa" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'" (click)="togglePassword()"
                    style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer;"></em>
                </div>

                <div style="padding: 10; width: 260px; height: 40px !important" class="conn">
                  <span style="
                      color: red;
                      font-size: 14px;
                      text-align: left;
                      width: 260px !important;
                    " class="w-100 col-12 ps-0" *ngIf="
                      submitted &&
                      f['password'] &&
                      f['password'].errors &&
                      f['password'].errors['required']
                    ">
                    Password is required.
                  </span>
                  <span style="
                      color: red;
                      font-size: 14px;
                      text-align: left;
                      width: 260px !important;
                    " class="w-100 col-12 ps-0" *ngIf="
                      f['password'].errors &&
                      (f['password'].errors['minlength'] ||
                        f['password'].errors['maxlength'])
                    ">
                    Password must be between 6 - 16 characters.
                  </span>

                </div>
              </div>

              <div style="margin-top: 10px">
                <a href="#" class="forgot_password" style="margin: 0px"
                  (click)="forgotpwshow(); showLogin = false">Forgot your password?</a>

              </div>
            </div>
            <input #otp *ngIf="enterotp && tenantsadmin" autofocus type="text"
              style="padding-top: 20;height: 40px !important" formControlName="otp" class="form-control"
              placeholder="Enter OTP" />
            <div style="color: rgb(36, 112, 36); font-size: 12px; text-align: left" *ngIf="enterotp && tenantsadmin">
              {{ otpmessage }}
            </div>

            <div class="mt-2" *ngIf="enterotp && tenantsadmin"
              style="color: red; font-size: 14px; text-align: left; color: #333">
              We have sent you one time password (OTP) to your email address.
            </div>

            <div class="mt-3" style="color: red; font-size: 14px; text-align: left"
              *ngIf="enterotp && tenantsadmin && !showResend">
              Didn't receive OTP?<br />
              Re-send in {{ otpcount }} seconds
            </div>
            <a class="sample-link" (click)="resendOtp()" *ngIf="enterotp && tenantsadmin && showResend">Resend OTP</a>



            <div style="margin-top: 20px">
              <div style="color: red; font-size: 14px; text-align: left" *ngIf="loginError">
                {{ loginError }}
              </div>
              <div style="color: red; font-size: 14px; text-align: left" *ngIf="loginError2">
                {{ loginError2 }}
              </div>
              <div style="color: red; font-size: 14px; text-align: left" *ngIf="loginEmailError">
                {{ loginEmailError }}
              </div>
              <div style="color: red; font-size: 14px; text-align: left" *ngIf="signinError">
                {{ signinError }}
              </div>
              <div style="color: red; font-size: 14px; text-align: left" *ngIf="signinEmailError">
                {{ signinEmailError }}
              </div>
            </div>
            <button (click)="continuefortenants()" *ngIf="!loginApiCall && continuebutton && !tenantsadmin">
              Continue
            </button>

            <button (click)="signin()" *ngIf="!loginApiCall2 && loginbutton && tenantsadmin && !enterotp"
              style="margin-left: 10px; width: 260px">
              Log In
            </button>
            <button (click)="loggedin()" *ngIf="!loginApiCall2 && loginbutton && tenantsadmin && enterotp"
              style="margin-left: 10px; width: 260px">
              Log In
            </button>

            <div style="margin-top: 10px">
              <a href="" class="forgot_password signup-btn-l" style="margin: 0px"
                [routerLink]="['/register']">SignUp</a>
            </div>
          </div>

          <div style="margin-top: 10px">
            <a href="" class="forgot_password" style="margin: 0px" *ngIf="tenantsadmin" (click)="gobacktologin()">Go
              back</a>
          </div>
        </form>

        <form [formGroup]="forgetPasswordFrm" class="w-100 row" *ngIf="!showLogin">
          <h2 style="padding: 0">Forgot Password</h2>
          <h5 style="padding: 0" *ngIf="!this.superadminflag && this.tenantsList.length > 1">
            Select your tenant & submit
          </h5>
          <div>
            <input type="email" [readonly]="!showLogin" class="form-control" formControlName="useremail"
              placeholder="Email" [ngClass]="{
                'is-invalid':
                  forgetPasswordSubmitted &&
                  ForgetPasswordFrm['useremail'].errors
              }" />
            <span style="color: red; font-size: 14px; text-align: left" class="w-100 col-12 ps-0" *ngIf="
                forgetPasswordSubmitted &&
                ForgetPasswordFrm['useremail'] &&
                ForgetPasswordFrm['useremail'].errors &&
                ForgetPasswordFrm['useremail'].errors['required']
              ">
              Email is required
            </span>
            <span style="color: red; font-size: 14px; text-align: left" class="w-100 col-12 ps-0" *ngIf="
                ForgetPasswordFrm['useremail'] &&
                ForgetPasswordFrm['useremail'].errors &&
                ForgetPasswordFrm['useremail'].errors['pattern']
              ">
              Invalid Email Address
            </span>
          </div>

          <div>
            <div style="margin-top: 20px" *ngIf="!this.superadminflag && this.len > 0">
              <ng-select [items]="tenantsList" [clearable]="false" style="text-align: left"
                [(ngModel)]="selectedtenantValueforForgotPw" (ngModelChange)="dropdownselectforForgotPw($event)"
                class="form-control form-select" bindLabel="name" bindValue="id" formControlName="tenant">
              </ng-select>
            </div>
          </div>

          <span style="color: red; font-size: 14px; text-align: left" class="w-100 col-12 ps-0"
            *ngIf="forgetPasswordError">{{ forgetPasswordError }}
          </span>
          <div style="display: flex; align-items: center; padding: 0">
            <button (click)="showLogin = true" class="filter" style="margin-top: 5%; margin-right: 2%">
              Cancel
            </button>
            <button (click)="forgetPassword()" class="blue-btn" style="margin-top: 5%">
              Submit
            </button>
          </div>

        </form>


      </div>
    </div>
    <div class="overlay-container">
      <div class="overlay">
        <div class="overlay-panel overlay-right">
          <img style="width: 100%" src="{{ applogo }}" *ngIf="showAdminlogo" alt="" />
          <img style="width: 100%" src="assets/img/TATlogo.PNG" *ngIf="!tenantlogo && !showAdminlogo" alt="" />
          <img src="{{ url ? url : logo }}" *ngIf="tenantlogo" style="width: 100%" alt="" />
        </div>
      </div>
    </div>
  </div>
  <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"
    integrity="sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4"
    crossorigin="anonymous"></script>
</div>