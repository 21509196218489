
    <app-app-sub-header class="top-row d-flex justify-content-between" (onRefreshClicked)="resetListSettings()" (searchClicked)="searchData($event)" [isSearchable]="isSearchable" [pageTitle]="pageTitle"  [showCreateButton]="showCreateButton" [EditId]="EditId" ></app-app-sub-header>
    <div class="bottom-table">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" style="width:35%" (mouseenter)="mouseEnter('name')" (mouseleave)="mouseLeave('name')">
                    Name
                    <span *ngIf="currentSortedColumn != 'name' && mouseOnColumnName == 'name'" (click)="getTagList({sort_field:'name',sort_method:'desc',column_name:'name'})" class="sortbyspanr"
                      [hidden]="!iconVisible ">
                      <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
                    </span>
                    <span class="sortbyspanr" *ngIf="currentSortedColumn == 'name'" [hidden]="!iconVisibleDesc" (click)="getTagList({sort_field:'name',sort_method:'asc',column_name:'name'})">
                      <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
                    </span>
                    <span class="sortbyspanl"  *ngIf="currentSortedColumn == 'name'" [hidden]="!iconVisibleAsc" (click)="getTagList({sort_field:'name',sort_method:'desc',column_name:'name'})">
                      <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
                    </span>
                  </th>
                  <th scope="col" style="width:35%" (mouseenter)="mouseEnter('description')" (mouseleave)="mouseLeave('description')">
                    Description
                    <span *ngIf="currentSortedColumn != 'description' && mouseOnColumnName == 'description'" (click)="getTagList({sort_field:'description',sort_method:'desc',column_name:'description'})" class="sortbyspanr"
                      [hidden]="!iconVisible ">
                      <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
                    </span>
                    <span class="sortbyspanr" *ngIf="currentSortedColumn == 'description'" [hidden]="!iconVisibleDesc" (click)="getTagList({sort_field:'description',sort_method:'asc',column_name:'description'})">
                      <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
                    </span>
                    <span class="sortbyspanl"  *ngIf="currentSortedColumn == 'description'" [hidden]="!iconVisibleAsc" (click)="getTagList({sort_field:'description',sort_method:'desc',column_name:'description'})">
                      <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
                    </span>
                  </th>
                  <th scope="col" style="width:10%;text-align: center;" (mouseenter)="mouseEnter('is_active')" (mouseleave)="mouseLeave('is_active')" >
                    Status
                    <span *ngIf="currentSortedColumn != 'is_active' && mouseOnColumnName == 'is_active'" (click)="getTagList({sort_field:'is_active',sort_method:'desc',column_name:'is_active'})" class="sortbyspanr"
                      [hidden]="!iconVisible ">
                      <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
                    </span>
                    <span class="sortbyspanr" *ngIf="currentSortedColumn == 'is_active'" [hidden]="!iconVisibleDesc" (click)="getTagList({sort_field:'is_active',sort_method:'asc',column_name:'is_active'})">
                      <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
                    </span>
                    <span class="sortbyspanl"  *ngIf="currentSortedColumn == 'is_active'" [hidden]="!iconVisibleAsc" (click)="getTagList({sort_field:'is_active',sort_method:'desc',column_name:'is_active'})">
                      <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
                    </span>
                  </th>
                  <th scope="col" style="width:15%" (mouseenter)="mouseEnter('updated_by')" (mouseleave)="mouseLeave('updated_by')">
                    Updated By
                    <span *ngIf="currentSortedColumn != 'updated_by' && mouseOnColumnName == 'updated_by'" (click)="getTagList({sort_field:'updated_by',sort_method:'desc',column_name:'updated_by'})" class="sortbyspanr"
                      [hidden]="!iconVisible ">
                      <fa-icon [icon]="faArrowUp" style="color:grey;font-size: x-large;"></fa-icon>
                    </span>
                    <span class="sortbyspanr" *ngIf="currentSortedColumn == 'updated_by'" [hidden]="!iconVisibleDesc" (click)="getTagList({sort_field:'updated_by',sort_method:'asc',column_name:'updated_by'})">
                      <fa-icon [icon]="faArrowUp" style="color:black;font-size: x-large;"></fa-icon>
                    </span>
                    <span class="sortbyspanl"  *ngIf="currentSortedColumn == 'updated_by'" [hidden]="!iconVisibleAsc" (click)="getTagList({sort_field:'updated_by',sort_method:'desc',column_name:'updated_by'})">
                      <fa-icon [icon]="faArrowDown" style="color:black;font-size: x-large;"></fa-icon>
                    </span>
                  </th>
                  <th scope="col" style="width:5%" >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let tag of tagList" >
                  <td> <span class="name"> {{tag.name}}</span> </td>
                  <td >{{tag.description}}</td>
                  <td class="text-center">
                    <span *ngIf="tag.is_active === 1" class="badge text-bg-success">Active</span> 
                    <span *ngIf="tag.is_active === 0" class="badge text-bg-warning">Inactive</span>
                  </td>
                  <td style="text-align: left;">{{tag.updater.name}}</td>
                  <td><div style="cursor: pointer;">
                    <a (click)="open(UpdateTag,tag.id)"><em class="bi bi-pencil-square me-3" title="Edit"></em></a>
                    <a (click)="openDeleteModal(content,tag.id,tag)"><em class="bi bi-trash" title="Delete"></em></a>
                  </div></td>
                </tr>
              </tbody>
            </table>
        </div>
  
    
        <div class="pagination-main">
          <label for="" class="list-totalrecords">Total Records: {{totalRows}}</label>
          <ul *ngIf="pager.pages && pager.pages.length && totalRows > pageSize" class="pagination justify-content-end m-0">
            
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                <a *ngIf="pager.currentPage === 1; else elseFirst" class="page-link page-show" > <<</a>
                <ng-template #elseFirst>
                    <a (click)="setPage(1)" class="page-link"><<</a>
                </ng-template>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
                <a *ngIf="pager.currentPage === 1; else elsePrevious" class="page-link page-show" > <</a>
                <ng-template #elsePrevious>
                    <a (click)="setPage(pager.currentPage - 1)" class="page-link"> <</a>
                </ng-template>
            </li>
          
            <li class="page-item ">
              <a class="page-link">{{pager.currentPage}}</a>
          </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a *ngIf="pager.currentPage === pager.totalPages; else elseNext" class="page-link page-show">></a>
                <ng-template #elseNext>
                    <a (click)="setPage(pager.currentPage + 1)" class="page-link">></a>
                </ng-template>
            </li>
            <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
                <a *ngIf="pager.currentPage === pager.totalPages; else elseLast" class="page-link page-show">>></a>
                <ng-template #elseLast>
                    <a (click)="setPage(pager.totalPages)" class="page-link">>></a>
                </ng-template>
            </li>
        </ul>
        </div>
  
  
<ng-template #UpdateTag let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit Tag</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="CreateTagForm">  
    <div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createTagError">{{createTagError}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createTagErrorName">{{createTagErrorName}}</div>
      <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="createTagErrorDescription">{{createTagErrorDescription}}</div>
      <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="createTagErrorIsactive">{{createTagErrorIsactive}}</div>
    </div>
  <div class="modal-body">
    <div class="mb-3">
      <label for="">Tag Name</label>
      <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
      <input type="text" placeholder="Enter name" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" >
      <div style="color: red; font-size: 14px;" *ngIf="submitted && f['name'] && f['name'].errors && f['name'].errors['required']">
         Name is required
        </div>
    </div>

 

    <div class="mb-3">
      <label for="">Description</label>
      <input type="text" placeholder="Enter description" formControlName="description" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f['description'].errors }">
    
      <div style="color: red; font-size: 14px; text-align: left" class="w-100 col-12 ps-0"
        *ngIf="submitted && (f['description'].errors?.['minlength'] || f['description'].errors?.['maxlength'])">
        Description must be between 1 - 15 characters.
      </div>
    
      <div style="color: red; font-size: 14px;" *ngIf="submitted && f['description'].errors?.['required']">
        Description is required.
      </div>
    
      <small class="text-primary">Max 15 characters.</small>
    </div>

    <div class="mb-3">
      <label for=""> Status</label>
      <select name="" id="" class="form-select" formControlName="is_active">
        <option value="1">Active</option>
        <option value="0">Inactive</option>
      </select>
      <div style="color: red; font-size: 14px;" *ngIf="submitted && f['is_active'] && f['is_active'].errors && f['is_active'].errors['required']">
         Status is required.
        </div>
    </div>

   
  </div>
  <div class="modal-footer">
    <div class="spinner-border" *ngIf="projectapicall">
      <span class="visually-hidden">Loading...</span>
    </div>
    <button type="button" class="filter" (click)="modal.close('Save click')" *ngIf="!projectapicall">Cancel</button>
    <button type="button" class="blue-btn ms-" (click)="UpdateTags()"  *ngIf="!projectapicall">Save</button>
  </div>

</form>
</ng-template>

<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Delete Tag</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">
			
        <p>
          Are you sure you want to delete {{selectDeletedTag}} ?
        </p>
			</div>
		</form>
	</div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary cancelbutton" (click)="modal.dismiss('cancel click')">Cancel</button>
    <button type="button" class="btn btn-danger dangerbtn" (click)="modal.close('Ok click')">Delete</button>
  </div>
</ng-template>