<app-app-sub-header
  class="top-row d-flex justify-content-between"
  (onRefreshClicked)="resetListSettings()"
  (searchClicked)="searchData($event)"
  (resetListClicked)="resetListSettings()"
  [isSearchable]="isSearchable"
  [pageTitle]="pageTitle"
  [showCreateButton]="showCreateButton"
  (onShowFilter)="showFilter()"
></app-app-sub-header>

<div class="bottom-table">
  <form [formGroup]="FilterForm" *ngIf="showFilterOptions">
    <div class="row">
      <div class="col-2">
        <input
          type="text"
          formControlName="f_position"
          class="form-control"
          placeholder="Position"
        />
      </div>

      <div class="col-2">
        <input
          type="text"
          formControlName="f_company"
          class="form-control"
          placeholder="Company"
        />
      </div>

      <div class="col-2">
        <input
          type="text"
          formControlName="f_compensation"
          class="form-control"
          placeholder="Compensation"
        />
      </div>

      <div class="col-2">
        <div class="dropdown">
          <button
            class="btn btn-secondary custom-d w-100 bg-white border-0 dropdown-toggle text-start"
            type="button"
            id="dropdownMenuButto"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span class="strength" *ngIf="!imageselected">Select strength</span>
            <img *ngIf="imageselected" src="{{ imagepath }}" alt="" /><span
              *ngIf="imageselected"
              style="color: black"
              ><span *ngIf="filterStrengthId != '5'">& Up</span></span
            >
          </button>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li (click)="showfilterimage('./assets/img/1.png', '1')">
              <a class="dropdown-item">
                <img src="./assets/img/1.png" alt="" />&Up</a
              >
            </li>

            <li (click)="showfilterimage('./assets/img/2.png', '2')">
              <a class="dropdown-item"
                ><img src="./assets/img/2.png" alt="" />& Up</a
              >
            </li>
            <li (click)="showfilterimage('./assets/img/3.png', '3')">
              <a class="dropdown-item"
                ><img src="./assets/img/3.png" alt="" />& Up</a
              >
            </li>
            <li (click)="showfilterimage('./assets/img/4.png', '4')">
              <a class="dropdown-item"
                ><img src="./assets/img/4.png" alt="" />& Up</a
              >
            </li>
            <li (click)="showfilterimage('./assets/img/5.png', '5')">
              <a class="dropdown-item"
                ><img src="./assets/img/5.png" alt=""
              /></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-2">
        <input
          type="text"
          formControlName="f_city"
          class="form-control"
          placeholder="City"
        />
      </div>

      <div class="col-2">
        <ng-select
          [items]="stateList"
          class="form-control form-select"
          placeholder="State"
          bindLabel="name"
          formControlName="f_state"
          bindValue="name"
        >
        </ng-select>
      </div>
    </div>

    <div class="row" style="margin-top: 5px; margin-bottom: 5px">
      <div class="col-2">
        <ng-select
          [items]="genderList"
          class="form-control form-select"
          placeholder="Gender"
          bindLabel="name"
          formControlName="f_gender"
          bindValue="name"
        >
        </ng-select>
      </div>
      <div class="col-2">
        <ng-select
          [items]="diverseList"
          class="form-control form-select"
          placeholder="Diverse candidate"
          bindLabel="name"
          formControlName="f_diversity"
          bindValue="value"
        >
        </ng-select>
      </div>

      <div class="col-2">
        <ng-select
          [items]="languageList.languages"
          class="form-control form-select"
          placeholder="Language"
          bindLabel="name"
          formControlName="f_language"
          bindValue="id"
          #f_language
          (keydown)="getLanguageList(f_language)"
          (click)="getLanguageList()"
        >
        </ng-select>
      </div>

      <div class="col-2">
        <!-- <input placeholder="Contact After" type="text" formControlName="f_contactAfter" class="form-control contactAfter">
            <i style="position: relative;bottom: 50%;left: 90%;cursor: pointer;" class="fa fa-calendar"></i> -->
        <!-- <input type="date" formControlName="f_contactAfter" class="form-control contactAfter"> -->
        <input
          [ngStyle]="{
            color:
              FilterForm.get('f_contactAfter')?.value || isFocused
                ? 'black'
                : 'transparent'
          }"
          type="date"
          formControlName="f_contactAfter"
          class="form-control contactAfter"
          #dateInput
          (click)="isFocused = true"
          (blur)="isFocused = false"
        />

        <!-- Placeholder-like label -->
        <label
          (click)="isFocused = true;dateInput.focus()"
          [ngStyle]="{
            color:
              !FilterForm.get('f_contactAfter')?.value && !isFocused
                ? 'black'
                : 'transparent'
          }"
          style="position: relative; bottom: 48%; left: 5%"
          class="placeholder-label"
          [class.active]="dateInput.value"
          for="dateInput"
        >
          Last contacted after
        </label>
      </div>

      <div class="col-2">
        <ng-select
          [items]="tagList"
          class="form-control form-select"
          placeholder="Tag"
          bindLabel="name"
          formControlName="f_tag"
          bindValue="id"
          #f_tag
          (keydown)="getTagList(f_tag)"
          (click)="getTagList()"
        >
        </ng-select>
      </div>

      <div class="col-2" style="text-align: right">
        <button
          type="button"
          class="filter me-3"
          (click)="
            this.FilterForm.reset();
            this.filterStrengthId = '';
            this.currentAppliedFilter = null;
            this.filterStrengthId = '';
            this.getCandidateList({});
            this.imageselected = false;
            this.showFilterOptions = false
          "
        >
          Clear
        </button>
        <button type="button" class="blue-btn ms-2" (click)="applyFilter()">
          Apply
        </button>
      </div>
    </div>
  </form>

  <hr *ngIf="sendToSearchDiv && showFilterOptions" />

  <div *ngIf="sendToSearchDiv" style="padding: 5px">
    <div style="display: flex">
      <span
        >Selected {{ selectedItems.length }} out of
        {{ totalRows }} candidates</span
      >
      <button
        type="button"
        class="blue-btn ms-2"
        (click)="openPopupOfSearch(UpdateTag, selectedItems)"
        style="margin-bottom: 7px"
      >
        Add to Search
      </button>
    </div>
  </div>

  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col">
          <div style="padding-left: 15px !important">
            <input
              type="checkbox"
              [(ngModel)]="selectAll"
              (change)="toggleSelectAll()"
            />
            <!-- Select All -->
          </div>
        </th>
        <th
          scope="col"
          style="width: 15%"
          (mouseenter)="mouseEnter('in_last_name')"
          (mouseleave)="mouseLeave('in_last_name')"
        >
          Name
          <span
            (click)="
              getCandidateList({
                sort_field: 'in_last_name',
                sort_method: 'desc',
                column_name: 'in_last_name'
              })
            "
            class="sortbyspanr"
            [hidden]="!iconVisible"
            *ngIf="
              !multipleSorting['in_last_name'] &&
              mouseOnColumnName == 'in_last_name'
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanr"
            (click)="
              getCandidateList({
                sort_field: 'in_last_name',
                sort_method: 'asc',
                column_name: 'in_last_name'
              })
            "
            [hidden]="
              !(
                multipleSorting['in_last_name'] &&
                multipleSorting['in_last_name'][1] == 'desc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanl"
            (click)="
              getCandidateList({
                sort_field: 'in_last_name',
                sort_method: 'desc',
                column_name: 'in_last_name'
              })
            "
            [hidden]="
              !(
                multipleSorting['in_last_name'] &&
                multipleSorting['in_last_name'][1] == 'asc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        </th>
        <th
          scope="col"
          style="width: 20%"
          (mouseenter)="mouseEnter('current_position')"
          (mouseleave)="mouseLeave('current_position')"
        >
          Current Position
          <span
            (click)="
              getCandidateList({
                sort_field: 'current_position',
                sort_method: 'desc',
                column_name: 'current_position'
              })
            "
            class="sortbyspanr"
            [hidden]="!iconVisible"
            *ngIf="
              !multipleSorting['current_position'] &&
              mouseOnColumnName == 'current_position'
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanr"
            (click)="
              getCandidateList({
                sort_field: 'current_position',
                sort_method: 'asc',
                column_name: 'current_position'
              })
            "
            [hidden]="
              !(
                multipleSorting['current_position'] &&
                multipleSorting['current_position'][1] == 'desc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanl"
            (click)="
              getCandidateList({
                sort_field: 'current_position',
                sort_method: 'desc',
                column_name: 'current_position'
              })
            "
            [hidden]="
              !(
                multipleSorting['current_position'] &&
                multipleSorting['current_position'][1] == 'asc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        </th>
        <th
          scope="col"
          style="width: 15%"
          (mouseenter)="mouseEnter('current_company')"
          (mouseleave)="mouseLeave('current_company')"
        >
          Current Company
          <span
            (click)="
              getCandidateList({
                sort_field: 'current_company',
                sort_method: 'desc',
                column_name: 'current_company'
              })
            "
            class="sortbyspanr"
            [hidden]="!iconVisible"
            *ngIf="
              !multipleSorting['current_company'] &&
              mouseOnColumnName == 'current_company'
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanr"
            (click)="
              getCandidateList({
                sort_field: 'current_company',
                sort_method: 'asc',
                column_name: 'current_company'
              })
            "
            [hidden]="
              !(
                multipleSorting['current_company'] &&
                multipleSorting['current_company'][1] == 'desc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanl"
            (click)="
              getCandidateList({
                sort_field: 'current_company',
                sort_method: 'desc',
                column_name: 'current_company'
              })
            "
            [hidden]="
              !(
                multipleSorting['current_company'] &&
                multipleSorting['current_company'][1] == 'asc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        </th>
        <th
          scope="col"
          style="width: 8%"
          (mouseenter)="mouseEnter('projects_count')"
          (mouseleave)="mouseLeave('projects_count')"
        >
          Searches
          <span
            (click)="
              getCandidateList({
                sort_field: 'projects_count',
                sort_method: 'desc',
                column_name: 'projects_count'
              })
            "
            class="sortbyspanr"
            [hidden]="!iconVisible"
            *ngIf="
              !multipleSorting['projects_count'] &&
              mouseOnColumnName == 'projects_count'
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanr"
            (click)="
              getCandidateList({
                sort_field: 'projects_count',
                sort_method: 'asc',
                column_name: 'projects_count'
              })
            "
            [hidden]="
              !(
                multipleSorting['projects_count'] &&
                multipleSorting['projects_count'][1] == 'desc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanl"
            (click)="
              getCandidateList({
                sort_field: 'projects_count',
                sort_method: 'desc',
                column_name: 'projects_count'
              })
            "
            [hidden]="
              !(
                multipleSorting['projects_count'] &&
                multipleSorting['projects_count'][1] == 'asc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        </th>
        <th
          scope="col"
          style="width: 5%"
          (mouseenter)="mouseEnter('strength')"
          (mouseleave)="mouseLeave('strength')"
        >
          Strength
          <span
            (click)="
              getCandidateList({
                sort_field: 'strength',
                sort_method: 'desc',
                column_name: 'strength'
              })
            "
            class="sortbyspanr"
            [hidden]="!iconVisible"
            *ngIf="
              !multipleSorting['strength'] && mouseOnColumnName == 'strength'
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanr"
            (click)="
              getCandidateList({
                sort_field: 'strength',
                sort_method: 'asc',
                column_name: 'strength'
              })
            "
            [hidden]="
              !(
                multipleSorting['strength'] &&
                multipleSorting['strength'][1] == 'desc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanl"
            (click)="
              getCandidateList({
                sort_field: 'strength',
                sort_method: 'desc',
                column_name: 'strength'
              })
            "
            [hidden]="
              !(
                multipleSorting['strength'] &&
                multipleSorting['strength'][1] == 'asc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        </th>
        <th
          scope="col"
          style="width: 10%"
          (mouseenter)="mouseEnter('in_personal_phone')"
          (mouseleave)="mouseLeave('in_personal_phone')"
        >
          Phone
          <span
            (click)="
              getCandidateList({
                sort_field: 'in_personal_phone',
                sort_method: 'desc',
                column_name: 'in_personal_phone'
              })
            "
            class="sortbyspanr"
            [hidden]="!iconVisible"
            *ngIf="
              !multipleSorting['in_personal_phone'] &&
              mouseOnColumnName == 'in_personal_phone'
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanr"
            (click)="
              getCandidateList({
                sort_field: 'in_personal_phone',
                sort_method: 'asc',
                column_name: 'in_personal_phone'
              })
            "
            [hidden]="
              !(
                multipleSorting['in_personal_phone'] &&
                multipleSorting['in_personal_phone'][1] == 'desc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanl"
            (click)="
              getCandidateList({
                sort_field: 'in_personal_phone',
                sort_method: 'desc',
                column_name: 'in_personal_phone'
              })
            "
            [hidden]="
              !(
                multipleSorting['in_personal_phone'] &&
                multipleSorting['in_personal_phone'][1] == 'asc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        </th>
        <th
          scope="col"
          style="width: 15%"
          (mouseenter)="mouseEnter('in_personal_email')"
          (mouseleave)="mouseLeave('in_personal_email')"
        >
          Email
          <span
            (click)="
              getCandidateList({
                sort_field: 'in_personal_email',
                sort_method: 'desc',
                column_name: 'in_personal_email'
              })
            "
            class="sortbyspanr"
            [hidden]="!iconVisible"
            *ngIf="
              !multipleSorting['in_personal_email'] &&
              mouseOnColumnName == 'in_personal_email'
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanr"
            (click)="
              getCandidateList({
                sort_field: 'in_personal_email',
                sort_method: 'asc',
                column_name: 'in_personal_email'
              })
            "
            [hidden]="
              !(
                multipleSorting['in_personal_email'] &&
                multipleSorting['in_personal_email'][1] == 'desc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanl"
            (click)="
              getCandidateList({
                sort_field: 'in_personal_email',
                sort_method: 'desc',
                column_name: 'in_personal_email'
              })
            "
            [hidden]="
              !(
                multipleSorting['in_personal_email'] &&
                multipleSorting['in_personal_email'][1] == 'asc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        </th>
        <th
          scope="col"
          style="width: 12%"
          (mouseenter)="mouseEnter('last_contacted_at')"
          (mouseleave)="mouseLeave('last_contacted_at')"
        >
          Last Contacted
          <span
            (click)="
              getCandidateList({
                sort_field: 'last_contacted_at',
                sort_method: 'desc',
                column_name: 'last_contacted_at'
              })
            "
            class="sortbyspanr"
            [hidden]="!iconVisible"
            *ngIf="
              !multipleSorting['last_contacted_at'] &&
              mouseOnColumnName == 'last_contacted_at'
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanr"
            (click)="
              getCandidateList({
                sort_field: 'last_contacted_at',
                sort_method: 'asc',
                column_name: 'last_contacted_at'
              })
            "
            [hidden]="
              !(
                multipleSorting['last_contacted_at'] &&
                multipleSorting['last_contacted_at'][1] == 'desc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
          <span
            class="sortbyspanl"
            (click)="
              getCandidateList({
                sort_field: 'last_contacted_at',
                sort_method: 'desc',
                column_name: 'last_contacted_at'
              })
            "
            [hidden]="
              !(
                multipleSorting['last_contacted_at'] &&
                multipleSorting['last_contacted_at'][1] == 'asc'
              )
            "
          >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        </th>
        <th style="width: 5%">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let cl of candidateList">
        <td style="vertical-align: middle">
          <label style="padding-left: 12px !important">
            <input
              type="checkbox"
              value="{{ cl.id }}"
              (change)="toggleItemSelection(cl.id)"
              [checked]="selectedItems.includes(cl.id)"
            />
          </label>
        </td>

        <td
          (click)="showCandidate(cl.id)"
          onKeyPress="handleKeyPress(event)"
          onKeyDown="handleKeyDown(event)"
          onKeyUp="handleKeyUp(event)"
        >
          <div class="form-check">
            <label class="form-check-label" for="flexCheckDefault">
              <div class="top-left-details w-60 d-flex align-middle">
                <div class="top_profile">
                  <img src="{{ cl?.in_profile_pic_url }}" alt="" />
                </div>
                <div class="top_right_profile ps-3 d-inline-block">
                  <p>
                    <span style="padding-left: 0px">
                      {{ cl?.in_first_name }} {{ cl?.in_last_name }}</span
                    >
                  </p>
                </div>
              </div>
            </label>
          </div>
        </td>

        <td
          (click)="showCandidate(cl.id)"
          onKeyPress="handleKeyPress(event)"
          onKeyDown="handleKeyDown(event)"
          onKeyUp="handleKeyUp(event)"
        >
          {{ cl?.current_position }}
        </td>
        <td
          (click)="showCandidate(cl.id)"
          onKeyPress="handleKeyPress(event)"
          onKeyDown="handleKeyDown(event)"
          onKeyUp="handleKeyUp(event)"
        >
          {{ cl?.current_company }}
        </td>

        <td title="Click to see searches">
          <span
            class="circle-num"
            (click)="
              openSerchedproject(SearchProject, cl.id, cl.projects_count, cl)
            "
            onKeyPress="handleKeyPress(event)"
            onKeyDown="handleKeyDown(event)"
            onKeyUp="handleKeyUp(event)"
            >{{ cl?.projects_count }}</span
          >
        </td>

        <td (click)="showCandidate(cl.id)">
          <img
            alt=""
            *ngIf="cl.strength"
            src="{{ './assets/img/' + cl.strength + '.png' }}"
          />
        </td>
        <td (click)="showCandidate(cl.id)">
          {{ cl?.in_personal_phone }} <br />
          {{ cl?.in_work_phone }}
        </td>
        <td (click)="showCandidate(cl.id)">
          {{ cl?.in_personal_email }} <br />
          {{ cl?.in_work_email }}
        </td>
        <td (click)="showCandidate(cl.id)">
          {{ (cl?.last_contacted_at | date : "M/d/yyyy") || "-" }}
        </td>

        <td style="vertical-align: middle">
          <a (click)="openDeleteModal(content, cl.id, cl)"
            ><em
              class="bi bi-trash"
              style="margin-left: 9px"
              title="Delete"
            ></em
          ></a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="pagination-main">
  <label for="totalRows" class="list-totalrecords"
    >Total Records: {{ totalRows }}</label
  >
  <ul
    *ngIf="pager.pages && pager.pages.length && totalRows > pageSize"
    class="pagination justify-content-end m-0"
  >
    <li class="page-item" [ngClass]="{ disabled: pager.currentPage === 1 }">
      <a
        *ngIf="pager.currentPage === 1; else elseFirst"
        class="page-link page-show"
      >
        <<</a
      >
      <ng-template #elseFirst>
        <a (click)="setPage(1)" class="page-link"><<</a>
      </ng-template>
    </li>
    <li class="page-item" [ngClass]="{ disabled: pager.currentPage === 1 }">
      <a
        *ngIf="pager.currentPage === 1; else elsePrevious"
        class="page-link page-show"
      >
        <</a
      >
      <ng-template #elsePrevious>
        <a (click)="setPage(pager.currentPage - 1)" class="page-link"> <</a>
      </ng-template>
    </li>

    <li class="page-item">
      <a class="page-link">{{ pager.currentPage }}</a>
    </li>
    <li
      class="page-item"
      [ngClass]="{ disabled: pager.currentPage === pager.totalPages }"
    >
      <a
        *ngIf="pager.currentPage === pager.totalPages; else elseNext"
        class="page-link page-show"
        >></a
      >
      <ng-template #elseNext>
        <a (click)="setPage(pager.currentPage + 1)" class="page-link">></a>
      </ng-template>
    </li>
    <li
      class="page-item"
      [ngClass]="{ disabled: pager.currentPage === pager.totalPages }"
    >
      <a
        *ngIf="pager.currentPage === pager.totalPages; else elseLast"
        class="page-link page-show"
        >>></a
      >
      <ng-template #elseLast>
        <a (click)="setPage(pager.totalPages)" class="page-link">>></a>
      </ng-template>
    </li>
  </ul>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Candidte</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3">
        <p>Are you sure you want to delete {{ selectDeletedCandidte }} ?</p>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary cancelbutton"
      (click)="modal.dismiss('cancel click')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-danger dangerbtn"
      (click)="modal.close('Ok click')"
    >
      Delete
    </button>
  </div>
</ng-template>

<ng-template #SearchProject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Searches of {{ fullname }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body scrollsearch">
    <div
      class="experience row"
      *ngFor="let project of projectList; let lastItem = last"
      style="cursor: pointer; padding: 2px"
    >
      <div class="experience-left col-lg-9" style="float: left">
        <h6
          (click)="showProject(project.id); modal.dismiss('Cross click')"
          style="margin-bottom: 2px"
        >
          {{ project.name }} [ {{ project.client }} ]
        </h6>
        <p class="small m-0">
          Updated by {{ project.updated_by }} on
          {{ project.updated_at | date : "MM/dd/yyyy" }}
        </p>
      </div>
      <div class="inner-edit col-lg-2" style="float: right">
        <button
          (click)="showProject(project.id); modal.dismiss('Cross click')"
          type="button"
          class="blue-btn ng-star-inserted btn-sm"
          style="cursor: pointer"
        >
          Open
        </button>
      </div>
      <div class="col-lg-1" style="display: none"></div>
      <hr *ngIf="!lastItem" style="margin-top: 10px" />
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="filter" (click)="modal.close('Save click')">
      Close
    </button>
  </div>
</ng-template>

<ng-template #UpdateTag let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Candidates in Search</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="CreateTagForm">
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-12">
          <small class="text-primary">
            Adding <strong>{{ selectedItems.length }}</strong> candidates in to
            selected Search.
          </small>
        </div>
      </div>
      <div class="mb-3">
        <label for="project_main">Searches</label>
        <ng-select
          [items]="projectList2"
          class="form-control form-select"
          placeholder="Select Search"
          bindLabel="dropdown"
          bindValue="id"
          (search)="onProjectSearch($event)"
          formControlName="projectId"
        >
        </ng-select>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="filter"
        (click)="modal.close('Save click')"
        *ngIf="!projectapicall"
      >
        Cancel
      </button>
      <button
        type="button"
        class="blue-btn ms-"
        (click)="attachMultipleCandidatetoSearchForBulkAttach()"
        *ngIf="!projectapicall"
      >
        Add
      </button>
    </div>
  </form>
</ng-template>
