import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from 'src/app/core/common/storage.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordFrm !: FormGroup;
  resetPasswordSubmitted:boolean = false;
  showNewPassword:boolean = false;
  showConfirmPassword:boolean = false;
  resetPasswordError:string="";
  resetSuccessmsg="";
  resetSuccessflag=false;
  token:string = "";
  resetPasswordTokenError: any;
  resetPasswordpasswordError: any;
  passwordPattern  = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
  get f() { return this.resetPasswordFrm.controls; }
  constructor( private notificationService: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authservice: AuthService,
    private storageService: StorageService,
    private route: ActivatedRoute){

  }
  ngOnInit(): void {
    this.onBuildForm();
   console.log(this.route.snapshot.queryParams['tenant']);
  }
  onBuildForm(){
    this.resetPasswordFrm = this.formBuilder.group({
      newpassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      confirmpassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
    });
  }

  resetPassword(){
    this.resetPasswordSubmitted = true;
    if (this.resetPasswordFrm.invalid)
      return;

    if(this.resetPasswordFrm.controls['newpassword'].value.trim() !== this.resetPasswordFrm.controls['confirmpassword'].value.trim())  
    {
      this.resetPasswordError = "New password and confirm password must be same.";  
      return;
    }  

    this.token = this.route.snapshot.params['token'];
    let tenant = null;
    if(this.route.snapshot.queryParams['tenant'])
      tenant=this.route.snapshot.queryParams['tenant']

    // console.log(this.route.snapshot.params);
    
    let resetPasswordData :any = {};

    if(tenant){
      resetPasswordData = { password: this.resetPasswordFrm.controls['newpassword'].value.trim(), token: this.token, tenant:tenant}
    }else{
      resetPasswordData = { password: this.resetPasswordFrm.controls['newpassword'].value.trim(), token: this.token}
    }

    this.authservice.ResetPassword(resetPasswordData).subscribe({
        next: (response)=>{
          this.resetSuccessmsg=response.message
          this.resetSuccessflag=true
          this.notificationService.showSuccess(response.message);
          // this.router.navigate(['']);
        },
        error: (error)=>{
          //this.resetPasswordError = error.message;
          switch(error.status) {
            case 422:
              if(error.error.token) {
                this.resetPasswordTokenError = error.error.token[0];
              }
              if(error.error.password) {
                this.resetPasswordpasswordError = error.error.password[0];
              }
          
              break;
            default:
              
              //Nothing
    
          }
          // if(error.message)
          // this.resetPasswordError = error.message;

          // if(error.error.token)
          // this.resetPasswordError = error.error.token[0];

          this.resetPasswordSubmitted = false;

        } 
      });
  }

  toggleNewPassword(){
    this.showNewPassword = !this.showNewPassword;
  }
  gobacktologin()
  {
    this.router.navigate([''])
  }

  toggleConfirmPassword(){
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
