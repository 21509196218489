<div class="login_page">
  <div class="container custom_login" id="container">
    <div class="form-container log-in-container">
      <div class="left-container">
        <form [formGroup]="resetPasswordFrm" class="w-100 row" *ngIf="!resetSuccessflag">

          <h2 style="padding:0">Reset Password</h2>
          <span
            style="display: inline-flex; align-items: center; background-color: #aef9f3; color: #004d66; font-size: 12px; font-weight: 500; padding: 12px 15px; border-radius: 8px; border: 1px solid #cce0ff; box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);"
            class="w-100 col-12 ps-0">
            Password must contain at least one: lowercase, uppercase, number, and special character.
          </span>

          <div class="password-container" style="padding:0">
            <input type="password" class="form-control" placeholder="New Password" formControlName="newpassword"
              [ngClass]="{ 'is-invalid': resetPasswordSubmitted && f['newpassword'].errors }"
              *ngIf="!showNewPassword" />
            <input type="text" class="form-control" placeholder="New Password" formControlName="newpassword"
              [ngClass]="{ 'is-invalid': resetPasswordSubmitted && f['newpassword'].errors }" *ngIf="showNewPassword" />
            <em class="fa fa-eye" (click)="toggleNewPassword()" style="margin-right: 30px !important;"
              *ngIf="showNewPassword"></em>
            <em class="fa fa-eye-slash" (click)="toggleNewPassword()" style="margin-right: 30px !important;"
              *ngIf="!showNewPassword"></em>
          </div>
          <div class="conn">
            <span style="color: red; font-size: 14px;text-align: left;" class="w-100 col-12 ps-0"
              *ngIf="resetPasswordSubmitted && f['newpassword'] && f['newpassword'].errors && f['newpassword'].errors['required']">
              New password is required.
            </span>
            <span style="color: red; font-size: 14px;text-align: left;" class="w-100 col-12 ps-0"
              *ngIf="f['newpassword'].errors && (f['newpassword'].errors['minlength'] || f['newpassword'].errors['maxlength'])">
              New password must be between 6 - 16 characters.
            </span>

          </div>
          <div class="password-container" style="padding:0">
            <input type="password" class="form-control" placeholder="Confirm Password" formControlName="confirmpassword"
              [ngClass]="{ 'is-invalid': resetPasswordSubmitted && f['confirmpassword'].errors }"
              *ngIf="!showConfirmPassword" />
            <input type="text" class="form-control" placeholder="Confirm Password" formControlName="confirmpassword"
              [ngClass]="{ 'is-invalid': resetPasswordSubmitted && f['confirmpassword'].errors }"
              *ngIf="showConfirmPassword" />
            <em class="fa fa-eye" (click)="toggleConfirmPassword()" style="margin-right: 30px !important;"
              *ngIf="showConfirmPassword"></em>
            <em class="fa fa-eye-slash" (click)="toggleConfirmPassword()" style="margin-right: 30px !important;"
              *ngIf="!showConfirmPassword"></em>
          </div>
          <div class="conn">
            <span style="color: red; font-size: 14px;text-align: left;" class="w-100 col-12 ps-0"
              *ngIf="resetPasswordSubmitted && f['confirmpassword'] && f['confirmpassword'].errors && f['confirmpassword'].errors['required']">
              Password is required
            </span>
            <span style="color: red; font-size: 14px;text-align: left;" class="w-100 col-12 ps-0"
              *ngIf="f['confirmpassword'].errors && (f['confirmpassword'].errors['minlength'] || f['confirmpassword'].errors['maxlength'])">
              Password must be between 6 - 16 characters
            </span>

          </div>

          <div>
            <div style="color:red; font-size: 14px;text-align: left;" *ngIf="resetPasswordError">{{resetPasswordError}}
            </div>
            <div style="color:red; font-size: 14px;text-align: left;" *ngIf="resetPasswordTokenError">
              {{resetPasswordTokenError}}</div>
            <div style="color:red; font-size: 14px;text-align: left;" *ngIf="resetPasswordpasswordError">
              {{resetPasswordpasswordError}}</div>
          </div>
          <div style="display: flex;align-items: center;padding: 0;">
            <button (click)="resetPassword()" style="margin-top:5%">Reset Password</button>
          </div>

        </form>
        <div style="color:rgb(20, 99, 20); font-size: 14px;text-align: left;" *ngIf="resetSuccessflag">
          {{resetSuccessmsg}}</div>
        <div style="margin-top: 25px;">
          <a href="" class="forgot_password" style="margin: 15px;" *ngIf="resetSuccessflag" (click)="gobacktologin()">Go
            back</a>
        </div>

      </div>
    </div>
    <div class="overlay-container">
      <div class="overlay">
        <div class="overlay-panel overlay-right">
          <img style="width:100%" src="assets/img/TATlogo.PNG" alt="">
        </div>
      </div>
    </div>
  </div>
  <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"
    integrity="sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4"
    crossorigin="anonymous"></script>
</div>