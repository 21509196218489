import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagsRoutingModule } from './tags-routing.module';
import { TagsListComponent } from './pages/tags-list/tags-list.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TagsRoutingModule
  ]
})
export class TagsModule { }
