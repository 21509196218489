import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PagerService } from 'src/app/core/common/pager.service';
import { TagserviceService } from 'src/app/core/services/tags/tagservice.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageKey, StorageService } from 'src/app/core/common/storage.service';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.css']
})
export class TagsListComponent {

  activePage: number = 1;
  tagList: any = [];
  total: number = 10;
  closeResult: string = "";
  createTagError: string = ""
  createTagSuccess: string = ""
  CreateTagForm!: FormGroup
  showCreateButton: string = "tag";
  EditId!: number;
  pageNumber: number = 1;
  pageSize: number = 3;
  totalRows: number = 0;
  pager: any = {};
  pageTitle: string = "Tags";
  isSearchable: boolean = true;
  to: number = 10;
  submitted: boolean = false;
  selectDeletedTag: string | undefined;
  projectapicall: boolean = false;
  currentSearchValue: any;

  // for sorting list
  faArrowUp = faCaretUp;
  faArrowDown = faCaretDown;
  sortfield: any;
  sortmethod: any;
  iconVisible: boolean = true;
  iconVisibleAsc: boolean = false;
  iconVisibleDesc: boolean = false;
  staticarrowsVisible: boolean = false;
  currentSortedColumn: any;
  sortedColumn: any;
  createTagErrorName: any;
  createTagErrorDescription: any;
  createTagErrorIsactive: any;
  currentSortedMethod: any;
  getSearchList: any;
  mouseOnColumnName: string = "";
  constructor(private tagservice: TagserviceService,
    private notificationService: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private pagerService: PagerService,
    private storageService: StorageService
  ) {

  }


  ngOnInit() {
    let listSettings: any = this.storageService.getValue(StorageKey.listSettings);
    if (listSettings !== "undefined" && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.tags) {
      this.getTagList(null, this.getSearchList.tags);
    } else
      this.getTagList({});
  }


  get f() { return this.CreateTagForm.controls; }

  generateForm() {
    this.CreateTagForm = this.formBuilder.group({
      TagId: [null],
      name: [null, [Validators.required]],
      // description:[null],
      description: [null, [Validators.minLength(0), Validators.maxLength(15)]],
      is_active: [null]
    })
  }




  getTagList(data1: any, data = {
    page: this.pageNumber,
    sort_field: this.currentSortedColumn,
    sort_method: this.currentSortedMethod,
    column_name: this.sortedColumn,
    search: this.currentSearchValue,
  }, reset: boolean = false) {
    //data={page :this.pageNumber, sort_field: string = '', sort_method: string ='', column_name: string=''}

    if (data1) {
      data.sort_field = data1.sort_field;
      data.sort_method = data1.sort_method;
      data.column_name = data1.column_name;
    }
    if (!reset) {
      if (this.getSearchList) {
        this.getSearchList.tags = data;
        this.storageService.setValue('listSettings', JSON.stringify(this.getSearchList));
        this.currentSearchValue = data.search;
      } else
        this.storageService.setValue('listSettings', JSON.stringify({ tags: data }));
    } else {
      data.search = "";
      data.page = 1;
    }

    this.tagservice.listByFilter(data).subscribe({
      next: (response) => {
        this.tagList = response.tags.data;
        this.pageNumber = response.tags.current_page;
        this.totalRows = response.tags.total;
        this.pager = this.pagerService.getPager(this.totalRows, this.pageNumber);
        if (data.sort_field) {
          this.iconVisible = false;
          this.staticarrowsVisible = true;

          this.currentSortedColumn = data.column_name;
          this.sortedColumn = data.column_name;
          if (data.sort_method === 'desc') {
            this.iconVisibleDesc = true;
            this.iconVisibleAsc = false;
            this.currentSortedMethod = 'desc';
          }
          if (data.sort_method === 'asc') {
            this.iconVisibleAsc = true;
            this.iconVisibleDesc = false;
            this.currentSortedMethod = 'asc';
          }
          this.sortfield = response.tags.sort_field;
          this.sortmethod = response.tags.sort_method;
        }
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  openDeleteModal(content: any, deleteId: number, value: any) {
    this.selectDeletedTag = value.name;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.deleteTag(deleteId);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



  mouseEnter(columnname: any) {
    this.iconVisible = true;
    this.staticarrowsVisible = false;
    this.mouseOnColumnName = columnname;
    //this.currentSortedColumn = columnname;
    if (columnname == 'name' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'description' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'is_active' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'updated_by' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }

  }

  mouseLeave(columnname: any) {
    this.iconVisible = false;
    this.staticarrowsVisible = true;
  }

  EditTag(content: any, EditId: any) {
    if (EditId != undefined || EditId != null) {

      this.tagservice.getTagData({ id: EditId }).subscribe((data) => {
        let response = data.tag
        // console.log(data.tag, "data");
        this.CreateTagForm.controls['TagId'].setValue(response.id);
        this.CreateTagForm.controls['name'].setValue(response.name);
        this.CreateTagForm.controls['description'].setValue(response.description);
        this.CreateTagForm.controls['is_active'].setValue(response.is_active);

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed `;
        });

      })
    }
  }
  open(content: any, EditId: number) {
    // console.log("open model");
    this.generateForm();
    this.EditTag(content, EditId)
  }
  UpdateTags() {
    this.submitted = true;
    if (this.CreateTagForm.invalid) {
      return;
    }


    // if (this.f['name'].value.trim() == "") {
    //   this.notificationService.showError('Please enter name');
    //   return;
    // }
    // if (this.f['description'].value.trim() == "") {
    //   this.notificationService.showError('Please enter description');
    //   return;
    // }

    let UpdatedTagDetails = {
      id: this.CreateTagForm.controls['TagId'].value,
      name: this.CreateTagForm.controls['name'].value,
      description: this.CreateTagForm.controls['description'].value,
      is_active: this.CreateTagForm.controls['is_active'].value,

    };

    this.projectapicall = true;
    this.tagservice.updateTagData(UpdatedTagDetails.id, UpdatedTagDetails).subscribe({
      next: (response) => {
        //console.log("responce 2000",response)
        this.modalService.dismissAll();
        this.CreateTagForm.reset();
        this.notificationService.showSuccess(response.message);
        //  window.location.reload();
        this.projectapicall = false;
        this.submitted = false;
        // this.getTagList({});
        for (let i = 0; i < this.tagList.length; i++) {
          if (this.tagList[i].id === UpdatedTagDetails.id) {
            this.tagList[i] = response.tag;
            break;
          }
        }
      },
      error: (error) => {
        this.projectapicall = false;
        this.createTagError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.name) {
              this.createTagErrorName = error.error.name[0];
            }
            if (error.error.description) {
              this.createTagErrorDescription = error.error.description[0];
            }
            if (error.error.is_active) {
              this.createTagErrorIsactive = error.error.is_active[0];
            }
            break;
          default:
          //Nothing
        }
        // this.notificationService.showError(error.message);
      }
    }
    )

  }

  public searchData(value: any) {
    this.currentSearchValue = value;
    this.pageNumber = 1;
    this.getTagList({ page: this.pageNumber, sort_field: this.currentSortedColumn, sort_method: this.currentSortedMethod, column_name: this.sortedColumn, search: value });

  }
  showTag(id: any) {
    this.router.navigate(['/tag']);
  }


  deleteTag(deleteId: number) {
    this.tagservice.deleteTagData(deleteId)
      .subscribe({
        next: (response) => {
          //  this.tagList = response.tags.data;
          this.notificationService.showSuccess(response.message);
          this.getTagList({});

        },
        error: (error) => {
          this.notificationService.showError(error.message);
        },
      });
  }

  setPage(page: number) {
    this.pageNumber = page;

    this.getTagList({ page: this.pageNumber, sort_field: this.currentSortedColumn, sort_method: this.currentSortedMethod, column_name: this.sortedColumn });
  }

  resetListSettings() {
    let listSettings: any = this.storageService.getValue(StorageKey.listSettings);
    if (listSettings !== "undefined" && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.tags) {
      delete this.getSearchList.tags;
      this.storageService.setValue('listSettings', JSON.stringify(this.getSearchList));
    }
    this.iconVisible = true;
    this.currentSearchValue = "";
    this.currentSortedColumn = "";

    this.getTagList({}, undefined, true);
  }

}
