import { Component, OnInit } from '@angular/core';
import { StorageKey, StorageService } from 'src/app/core/common/storage.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ActivatedRoute, ChildActivationStart, NavigationStart, Params, RouteConfigLoadStart, Router, RoutesRecognized } from '@angular/router';
import { UserService } from 'src/app/core/services/users/user.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { CandidatejobsService } from 'src/app/core/services/candidatejobs/candidatejobs.service';



@Component({
  selector: 'app-app-jobheader',
  templateUrl: './app-jobheader.component.html',
  styleUrls: ['./app-jobheader.component.css']
})
export class AppJobheaderComponent implements OnInit {

  production_environment: boolean = true;
  uat_environment: boolean = false;
  imgSrc: any;

  projectapicall: boolean = false;
  submitted: boolean = false;
  tenantlogin: any = "1";
  CreateUserForm!: FormGroup
  files: any;
  filePath!: string;
  fileName!: string;
  url: any;
  url3: number | any = 1;
  previewImg: boolean = true;
  createUserError: string = ""
  createUserErrorAvatar: any;
  createUserErrorName: any;
  createUserErrorPassword: any;
  showPassword: boolean = false;
  editedInitials: any;
  dropimg: boolean = false;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  closeResult!: string;
  userprofile = [];
  userinitials: any;

  step: string = "1";
  showsubmitButton: boolean = false;
  userID: any;
  // OTPForm :FormGroup;

  optsuccesmsg: any;
  verifyemail: any
  opterrormsg: any;
  otpError: any;
  emailError: any;

  showPasswordinput: boolean = true;
  candidate_id: any;
  candidateData: any


  get f() { return this.CreateUserForm.controls; }

  constructor(
    private authServcie: AuthService,
    private storageService: StorageService,
    private userservice: UserService,
    private router: Router, private modalService: NgbModal,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private CandidatejobsService: CandidatejobsService,
  ) {

    this.CreateUserForm = this.formBuilder.group({
      UserId: [null],
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern(this.emailPattern)]],
      password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      role_id: [null, [Validators.required]],
      is_active: [null],
      avatar: [null],
      otp: [null],
    })

    this.candidate_id = this.storageService.getValue('candidate_id')


  }


  ngOnInit(): void {


    // this.getUserProfile();
    // this.onload();
    if (this.candidate_id) {
      this.getUserProfile();
      this.onload();
    }


  }


  logout() {
    this.authServcie.sign_out().subscribe({
      next: (responce: any) => {
        this.storageService.removeValue(StorageKey.currentTenantId);
        this.authServcie.logout();
        // this.router.navigate(['']);
        this.router.navigate(['/jobs']);

      },
      error: (err: any) => {
      }
    })
  }

  isActiveTab(route: string): boolean {
    return this.router.isActive(route, true);
  }

  UpdateProfiles() {

    this.submitted = true;

    // let UpdatedUserDetails = {
    //   name: this.CreateUserForm.controls['name'].value,
    //   password: this.CreateUserForm.controls['password'].value,

    // };
    this.projectapicall = true;
    const formData: FormData = new FormData();
    // console.log(this.url);
    if (this.fileName != null && (this.CreateUserForm.controls['password'].value != undefined || this.CreateUserForm.controls['password'].value != null)) {
      formData.append('name', this.fileName);
      formData.append('avatar', this.files);
      formData.append('name', this.CreateUserForm.controls['name'].value);
      formData.append('password', this.CreateUserForm.controls['password'].value);
    } else if (this.fileName == null && (this.CreateUserForm.controls['password'].value != undefined || this.CreateUserForm.controls['password'].value != null && this.url == null)) {
      //    alert(4);

      formData.append('is_avatar_removed', this.url3);
      formData.append('name', this.CreateUserForm.controls['name'].value);
      formData.append('password', this.CreateUserForm.controls['password'].value);
    } else if ((this.CreateUserForm.controls['password'].value != undefined || this.CreateUserForm.controls['password'].value != null) && this.fileName == null && this.url == null) {
      //  alert(1);
      // formData.append('avatar_path', this.url2);
      formData.append('is_avatar_removed', this.url3);
      formData.append('name', this.CreateUserForm.controls['name'].value);
    }
    else if (this.fileName != null && (this.CreateUserForm.controls['password'].value != undefined || this.CreateUserForm.controls['password'].value != null) && this.url == null) {
      // alert(2);
      formData.append('is_avatar_removed', this.url3);
      formData.append('name', this.fileName);
      formData.append('avatar', this.files);
      formData.append('name', this.CreateUserForm.controls['name'].value);
    }
    else if (this.CreateUserForm.controls['password'].value == undefined && this.fileName == null && this.url == null) {
      //  alert(3);
      formData.append('is_avatar_removed', this.url3);
      formData.append('name', this.CreateUserForm.controls['name'].value);

    } else if (this.CreateUserForm.controls['password'].value == undefined && this.fileName == null) {
      //  alert(5);
      formData.append('name', this.CreateUserForm.controls['name'].value);
    } else if (this.fileName != null && this.CreateUserForm.controls['password'].value == undefined) {
      //  alert(6)
      formData.append('name', this.fileName);
      formData.append('avatar', this.files);
      formData.append('name', this.CreateUserForm.controls['name'].value);
    }



    this.userservice.candidateupdateProfile(formData).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.CreateUserForm.reset();
        // this.dropimg = false;
        this.previewImg = true;
        this.projectapicall = false;
        this.submitted = false;
        this.getUserProfile();
        // window.location.reload();
        this.notificationService.showSuccess(response.message);

      },
      error: (error) => {
        this.projectapicall = false;
        this.createUserError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.avatar) {
              this.createUserErrorAvatar = error.error.avatar[0];
            }
            if (error.error.name) {
              this.createUserErrorName = error.error.name[0];
            }
            if (error.error.password) {
              this.createUserErrorPassword = error.error.password[0];
            }
            break;
          default:
        }
        // this.notificationService.showError(error.message);
        console.log(error)
      }
    })
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  resetImageFile(element: any) {
    element.value = "";
    this.url = null;
  }

  attachFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;

    if (event.target.files) {
      var reader = new FileReader;
      reader.onload = (event: any) => {
        this.url = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
    // this.dropimg = false;
    this.previewImg = true;
    this.dropimg = false;

  }

  open(content: any, typeOfModal: any, data: any = {}) {

    if (typeOfModal == "EditProfile") {
      this.EditProfile(content)
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed `;
    });
  }

  EditProfile(content: any) {
    this.userservice.candidateuser_profile().subscribe((data) => {
      let response = data.user
      this.CreateUserForm.controls['name'].setValue(response.name);
      this.CreateUserForm.controls['email'].setValue(response.email);
      this.CreateUserForm.controls['email'].disable();
      this.CreateUserForm.controls['password'].setValue(response.password);
    })
  }


  getInitials(name: string): string {
    const words = name.split(' ');
    let initials = '';

    for (let i = 0; i < words.length; i++) {
      const word = words[i].trim();

      if (word.length > 0) {
        initials += word[0].toUpperCase();
      }
    }

    return initials;
  }

  getUserProfile() {
    this.userservice.candidateuser_profile().subscribe({
      next: (response) => {
        this.userprofile = response.user;
        this.userID = response.user.id
        this.imgSrc = response.user.avatar_path;
        response["initials"] = this.getInitials(response.user.name);
        this.userinitials = response.initials;
      },
      error: (error: any) => {
        // this.notificationService.showError(error.message);
      }
    });


  }


  editEmail() {
    this.showsubmitButton = true;
    this.CreateUserForm.controls['email'].enable();
    this.showPasswordinput = false;
    this.createUserErrorPassword = "";
  }

  submitforOTP() {
    this.submitted = true;
    this.emailError = ''

    let UpdatedUserDetails = {
      email: this.CreateUserForm.controls['email'].value,
      is_register: 0,
    };

    this.userservice.sendOTPforVerify(this.userID, UpdatedUserDetails).subscribe({
      next: (response: any) => {
        this.optsuccesmsg = response.message;
        this.step = '2';
      },
      error: (error) => {
        this.emailError = error.error.message;
        console.log(error)
        switch (error.status) {
          case 422:
            if (error.error.email) {
              this.emailError = error.error.email[0];
            }
            break;
          default:
        }
        // this.notificationService.showError(error.message);
      }
    })

  }


  SendOTP() {

    this.otpError = ''

    let Details = {
      email: this.CreateUserForm.controls['email'].value,
      is_register: 0,
      otp: this.CreateUserForm.controls['otp'].value,
    };

    this.userservice.VerifyOTP(this.userID, Details).subscribe({
      next: (response: any) => {
        this.verifyemail = response.message;
        this.step = '3';
        this.CreateUserForm.reset();
      },
      error: (error) => {
        switch (error.status) {
          case 422:
            if (error.error.otp) {
              this.otpError = error.error.otp[0];
            }
            break;
          default:
        }
      }
    })
  }


  closed() {
    this.modalService.dismissAll();
    this.step = '1';
    this.optsuccesmsg = "";
    this.verifyemail = "";
    this.showsubmitButton = false
    this.otpError = ''
    this.showPasswordinput = true;

  }

  clearall() {
    this.step = '1';
    this.optsuccesmsg = "";
    this.verifyemail = "";
    this.showsubmitButton = false;
    this.otpError = '';
    this.modalService.dismissAll();
    this.showPasswordinput = true;
    this.createUserError = "";
    this.createUserErrorName = "";
    this.createUserErrorPassword = "";
    this.emailError = "";
  }



  onload() {
    this.CandidatejobsService.candidateDetail(this.candidate_id).subscribe({
      next: (response: any) => {
        this.candidateData = response.candidate;
        this.imgSrc = response.candidate.in_profile_pic_url
      },
      error: (error) => {
        // this.notificationService.showError(error.message);
      }
    });
  }








}
