import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PagerService } from 'src/app/core/common/pager.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/core/services/users/user.service';
import { StorageKey, StorageService } from 'src/app/core/common/storage.service';
import { MicrosoftEmailService } from 'src/app/core/services/microsoftEmail/microsoft-email.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent {

  activePage: number = 1;
  userList: any = [];
  total: number = 10;
  closeResult: string = "";
  createUserError: string = ""
  CreateUserForm!: FormGroup
  showCreateButton: string = "user";
  EditId!: number;
  pageNumber: number = 1;
  pageSize: number = 3;
  totalRows: number = 0;
  pager: any = {};
  pageTitle: string = "Users";
  isSearchable: boolean = true;
  to: number = 10;
  submitted: boolean = false;
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  selectDeletedUser: string | undefined;
  droppedImage: boolean = false;
  // selectedFile: File | null = null;
  projectapicall: boolean = false;

  files: any;
  filePath!: string;
  fileName!: string;
  url: any;
  length: any
  currentSearchValue: string = "";
  dropimg: boolean = false;
  previewImg: boolean = true;
  // for sorting list
  faArrowUp = faCaretUp;
  faArrowDown = faCaretDown;
  sortfield: any;
  sortmethod: any;
  iconVisible: boolean = true;
  iconVisibleAsc: boolean = false;
  iconVisibleDesc: boolean = false;
  staticarrowsVisible: boolean = false;
  currentSortedColumn: any;
  currentSortedMethod: any;
  sortedColumn: any;
  createUserErrorAvatar: any;
  createUserErrorName: any;
  createUserErrorEmail: any;
  createUserErrorRoleid: any;
  createUserErrorIsactive: any;
  showPassword: boolean = false;
  userid!: number | any
  showUserdeleteicon: boolean = true;
  deleteuserId: number | any;
  myuserid: any;
  // url2!:any ;
  url2 = '../assets/img/avatar-placeholder.png';
  url3: number | any = 1;
  userShortname!: string | null;
  sortname!: string;
  editedInitials: any;
  getSearchList: any;
  currentTenantId = this.storageService.getValue(StorageKey.currentTenantId);
  mouseOnColumnName: string = "";
  // url3:number |any  = 1;
  constructor(private userservice: UserService,
    private notificationService: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private pagerService: PagerService,
    private storageService: StorageService,
    private microsoftService: MicrosoftEmailService,) { }

  ngOnInit() {
    let listSettings: any = this.storageService.getValue(StorageKey.listSettings);
    if (listSettings !== "undefined" && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.users) {
      this.getUserList(null, this.getSearchList.users);
    } else
      this.getUserList({});
    let currentUserid = this.storageService.getValue(StorageKey.currentUserid);
    this.userid = currentUserid;

  }





  getInitials(name: string): string {
    const words = name.split(' ');
    let initials = '';

    for (let i = 0; i < words.length; i++) {
      const word = words[i].trim();

      if (word.length > 0) {
        initials += word[0].toUpperCase();
      }
    }

    return initials;
  }


  get f() { return this.CreateUserForm.controls; }

  generateForm() {
    this.CreateUserForm = this.formBuilder.group({
      UserId: [null],
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.pattern(this.emailPattern)]],
      password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      role_id: [null, [Validators.required]],
      is_active: [null],
      avatar: [null],
    })
  }

  getUserList(data1: any, data = {
    page: this.pageNumber,
    sort_field: this.currentSortedColumn,
    sort_method: this.currentSortedMethod,
    column_name: this.sortedColumn,
    search: this.currentSearchValue,
  }, reset: boolean = false) {
    //data={page :this.pageNumber, sort_field: string = '', sort_method: string ='', column_name: string=''}


    if (data1) {
      data.sort_field = data1.sort_field;
      data.sort_method = data1.sort_method;
      data.column_name = data1.column_name;
    }
    if (!reset) {
      if (this.getSearchList) {
        this.currentSearchValue = data.search;
        this.getSearchList.users = data;
        this.storageService.setValue('listSettings', JSON.stringify(this.getSearchList));
      } else
        this.storageService.setValue('listSettings', JSON.stringify({ users: data }));
    } else {
      data.search = "";
      data.page = 1;
    }

    this.userservice.listByFilter(data).subscribe({
      next: (response) => {
        this.userList = response.users.data;
        this.userList.forEach((element: any) => {
          element["initials"] = this.getInitials(element.name);
        });

        this.pageNumber = response.users.current_page;
        this.totalRows = response.users.total;
        this.pager = this.pagerService.getPager(this.totalRows, this.pageNumber);
        if (data.sort_field) {
          this.iconVisible = false;
          this.staticarrowsVisible = true;

          this.currentSortedColumn = data.column_name;
          this.sortedColumn = data.column_name;
          if (data.sort_method === 'desc') {
            this.iconVisibleDesc = true;
            this.iconVisibleAsc = false;
            this.currentSortedMethod = 'desc';
          }
          if (data.sort_method === 'asc') {
            this.iconVisibleAsc = true;
            this.iconVisibleDesc = false;
            this.currentSortedMethod = 'asc';
          }
          this.sortfield = response.users.sort_field;
          this.sortmethod = response.users.sort_method;
        }
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  openDeleteModal(content: any, deleteId: number, value: any) {
    this.selectDeletedUser = value.name;

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.deleteUser(deleteId);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



  mouseEnter(columnname: any) {
    this.iconVisible = true;
    this.staticarrowsVisible = false;
    //this.currentSortedColumn = columnname;
    this.mouseOnColumnName = columnname;
    if (columnname == 'name' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'email' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'role_id' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'is_active' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }

  }

  mouseLeave(columnname: any) {
    this.iconVisible = false;
    this.staticarrowsVisible = true;
  }


  // dropImage(){
  //   // this.dropimg = true;
  //   // this.previewImg =  false;
  // }




  EditUser(content: any, EditId: any) {
    if (EditId != undefined || EditId != null) {
      this.userservice.getUserData({ id: EditId }).subscribe((data) => {
        let response = data.user
        // let response= data.user;
        this.url = data.user.avatar_path;
        response["initial"] = this.getInitials(response.name);
        this.editedInitials = response.initial;

        // this.dropimg =false;
        if (this.url == null) {
          this.previewImg = false;
          this.dropimg = true;
        } else if (this.url != null) {
          this.previewImg = true;
          this.dropimg = false;
        }
        this.CreateUserForm.controls['UserId'].setValue(response.id);
        this.CreateUserForm.controls['name'].setValue(response.name);
        this.CreateUserForm.controls['email'].setValue(response.email);
        this.CreateUserForm.controls['password'].setValue(response.password);
        this.CreateUserForm.controls['role_id'].setValue((this.currentTenantId != '0') ? response.role.id : 1);
        this.CreateUserForm.controls['is_active'].setValue(response.is_active);
        //  this.CreateUserForm.controls['avatar'].setValue(response.avatar_path);

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed `;
        });
      })
    }
  }
  open(content: any, EditId: number) {
    // console.log("open model");
    this.generateForm();
    this.EditUser(content, EditId);
  }
  UpdateUsers() {

    this.submitted = true;
    // if (this.CreateUserForm.invalid) {
    //   return;
    // }
    // if(!this.files || this.CreateUserForm.invalid)
    // {
    //   return; 
    // }
    // if (this.f['name'].value.trim() == "") {
    //   this.notificationService.showError('Please enter name');
    //   return;
    // }
    // if (this.f['email'].value.trim() == "") {
    //   this.notificationService.showError('Please enter email');
    //   return;
    // }
    let UpdatedUserDetails = {
      id: this.CreateUserForm.controls['UserId'].value,
      name: this.CreateUserForm.controls['name'].value,
      email: this.CreateUserForm.controls['email'].value,
      password: this.CreateUserForm.controls['password'].value,
      role_id: this.CreateUserForm.controls['role_id'].value,
      is_active: this.CreateUserForm.controls['is_active'].value,
      //  is_avatar_removed:1
      // avatar:this.CreateUserForm.controls['avatar'].value,

    };
    //  this.dropimg =false;
    // console.log(UpdatedUserDetails,"updated data")
    this.projectapicall = true;
    const formData: FormData = new FormData();
    if (this.fileName != null && (this.CreateUserForm.controls['password'].value != undefined || this.CreateUserForm.controls['password'].value != null)) {
      formData.append('name', this.fileName);
      formData.append('avatar', this.files);
      formData.append('UserId', this.CreateUserForm.controls['UserId'].value);
      formData.append('name', this.CreateUserForm.controls['name'].value);
      formData.append('email', this.CreateUserForm.controls['email'].value);
      formData.append('password', this.CreateUserForm.controls['password'].value);
      formData.append('role_id', this.CreateUserForm.controls['role_id'].value);
      formData.append('is_active', this.CreateUserForm.controls['is_active'].value);
    } else if (this.fileName == null && (this.CreateUserForm.controls['password'].value != undefined || this.CreateUserForm.controls['password'].value != null && this.url == null)) {
      //  alert(4);
      formData.append('is_avatar_removed', this.url3);
      formData.append('UserId', this.CreateUserForm.controls['UserId'].value);
      formData.append('name', this.CreateUserForm.controls['name'].value);
      formData.append('email', this.CreateUserForm.controls['email'].value);
      formData.append('password', this.CreateUserForm.controls['password'].value);
      formData.append('role_id', this.CreateUserForm.controls['role_id'].value);
      formData.append('is_active', this.CreateUserForm.controls['is_active'].value);
    } else if ((this.CreateUserForm.controls['password'].value != undefined || this.CreateUserForm.controls['password'].value != null) && this.fileName == null && this.url == null) {
      //   alert(1);
      // formData.append('avatar_path', this.url2);
      formData.append('is_avatar_removed', this.url3);
      formData.append('UserId', this.CreateUserForm.controls['UserId'].value);
      formData.append('name', this.CreateUserForm.controls['name'].value);
      formData.append('email', this.CreateUserForm.controls['email'].value);
      formData.append('role_id', this.CreateUserForm.controls['role_id'].value);
      formData.append('is_active', this.CreateUserForm.controls['is_active'].value);
    }
    else if (this.fileName != null && (this.CreateUserForm.controls['password'].value != undefined || this.CreateUserForm.controls['password'].value != null) && this.url == null) {
      // formData.append('avatar_path', this.url2);
      // alert(2);
      formData.append('is_avatar_removed', this.url3);
      formData.append('name', this.fileName);
      formData.append('avatar', this.files);
      formData.append('UserId', this.CreateUserForm.controls['UserId'].value);
      formData.append('name', this.CreateUserForm.controls['name'].value);
      formData.append('email', this.CreateUserForm.controls['email'].value);
      formData.append('role_id', this.CreateUserForm.controls['role_id'].value);
      formData.append('is_active', this.CreateUserForm.controls['is_active'].value);
    }
    else if (this.CreateUserForm.controls['password'].value == undefined && this.fileName == null && this.url == null) {
      // formData.append('avatar_path', this.url2);
      //  alert(3);
      formData.append('is_avatar_removed', this.url3);
      formData.append('UserId', this.CreateUserForm.controls['UserId'].value);
      formData.append('name', this.CreateUserForm.controls['name'].value);
      formData.append('email', this.CreateUserForm.controls['email'].value);
      formData.append('role_id', this.CreateUserForm.controls['role_id'].value);
      formData.append('is_active', this.CreateUserForm.controls['is_active'].value);
    }
    //  else if(this.fileName != null && this.CreateUserForm.controls['password'].value == undefined && this.url == null){
    //   // formData.append('avatar_path', this.url2);
    //   alert(4);
    //   formData.append('is_avatar_removed', this.url3 );
    //   formData.append('name', this.fileName);
    //   formData.append('avatar', this.files);
    //   formData.append('UserId', this.CreateUserForm.controls['UserId'].value);
    //   formData.append('name', this.CreateUserForm.controls['name'].value);
    //   formData.append('email', this.CreateUserForm.controls['email'].value);
    //   formData.append('role_id', this.CreateUserForm.controls['role_id'].value);
    //   formData.append('is_active', this.CreateUserForm.controls['is_active'].value);

    //  }
    else if (this.CreateUserForm.controls['password'].value == undefined && this.fileName == null) {
      formData.append('UserId', this.CreateUserForm.controls['UserId'].value);
      formData.append('name', this.CreateUserForm.controls['name'].value);
      formData.append('email', this.CreateUserForm.controls['email'].value);
      formData.append('role_id', this.CreateUserForm.controls['role_id'].value);
      formData.append('is_active', this.CreateUserForm.controls['is_active'].value);
    } else if (this.fileName != null && this.CreateUserForm.controls['password'].value == undefined) {
      formData.append('name', this.fileName);
      formData.append('avatar', this.files);
      formData.append('UserId', this.CreateUserForm.controls['UserId'].value);
      formData.append('name', this.CreateUserForm.controls['name'].value);
      formData.append('email', this.CreateUserForm.controls['email'].value);
      formData.append('role_id', this.CreateUserForm.controls['role_id'].value);
      formData.append('is_active', this.CreateUserForm.controls['is_active'].value);
    }



    // console.log(formData);

    this.userservice.updateUserData(UpdatedUserDetails.id, formData).subscribe({
      next: (response) => {
        // console.log("responce 2000",response)
        this.modalService.dismissAll();
        this.CreateUserForm.reset();
        // this.dropimg = false;
        this.previewImg = true;
        this.notificationService.showSuccess(response.message);
        // window.location.reload();
        this.projectapicall = false;
        this.submitted = false;
        //this.getUserList({});
        for (let i = 0; i < this.userList.length; i++) {
          if (this.userList[i].id === UpdatedUserDetails.id) {
            this.userList[i] = response.user;
            break;
          }
        }

        this.userList.forEach((element: any) => {
          element["initials"] = this.getInitials(element.name);
        });

      },
      error: (error) => {
        this.projectapicall = false;
        this.createUserError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.avatar) {
              this.createUserErrorAvatar = error.error.avatar[0];
            }
            if (error.error.name) {
              this.createUserErrorName = error.error.name[0];
            }
            if (error.error.email) {
              this.createUserErrorEmail = error.error.email[0];
            }
            if (error.error.role_id) {
              this.createUserErrorRoleid = error.error.role_id[0];
            }
            if (error.error.is_active) {
              this.createUserErrorIsactive = error.error.is_active[0];
            }
            break;
          default:

          //Nothing

        }
        // this.notificationService.showError(error.message);
      }
    }
    )

  }

  // changeFiles(){
  //   this.attachFiles({});
  // }

  attachFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;
    //   if (event.target.files &&  this.files) {

    //     const maxAllowedSize = 3 * 1024 * 1024;
    //     if (event.target.files[0].size > maxAllowedSize) {
    //         alert('Choose max 3mb files');
    //         event.target.value = '';
    //     }
    // }
    if (event.target.files) {
      var reader = new FileReader;
      reader.onload = (event: any) => {
        this.url = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
    this.dropimg = false;
    this.previewImg = true;

  }




  public searchData(value: any) {
    this.currentSearchValue = value;
    this.pageNumber = 1;
    this.getUserList({ page: this.pageNumber, sort_field: this.currentSortedColumn, sort_method: this.currentSortedMethod, column_name: this.sortedColumn, search: value });

  }
  showUser(id: any) {
    this.router.navigate(['/user']);
  }




  deleteUser(deleteId: number) {
    this.userservice.deleteUserData(deleteId)
      .subscribe({
        next: (response) => {
          //  this.tagList = response.tags.data;
          this.notificationService.showSuccess(response.message);
          this.getUserList({});

        },
        error: (error) => {
          this.notificationService.showError(error.message);
        },
      });
  }

  setPage(page: number) {
    this.pageNumber = page;
    this.getUserList({ page: this.pageNumber, sort_field: this.currentSortedColumn, sort_method: this.currentSortedMethod, column_name: this.sortedColumn });
  }




  resetImageFile(element: any) {
    element.value = "";
    this.url = null;

  }


  resetListSettings() {
    let listSettings: any = this.storageService.getValue(StorageKey.listSettings);
    if (listSettings !== "undefined" && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.users) {
      delete this.getSearchList.users;
      this.storageService.setValue('listSettings', JSON.stringify(this.getSearchList));
    }

    this.iconVisible = true;
    this.currentSearchValue = "";
    this.currentSortedColumn = "";

    this.getUserList({}, undefined, true);
  }



  openUnauthorizeModal(unauthorize: any, user_id: number, value: any) {
    this.selectDeletedUser = value.name;

    this.modalService.open(unauthorize, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        this.unauthorizeMs(user_id);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  unauthorizeMs(user_id: any) {
    let data = {
      user_id: user_id
    }
    this.microsoftService.unauthorizeMs(data).subscribe({
      next: (response: any) => {
        // console.log(response)
        this.notificationService.showSuccess("User email login disconnected successfully!");
        this.getUserList({});
      },
      error: (error) => {
        switch (error.status) {
          case 401:
            this.notificationService.showError(error.message);
            break;
          default:
        }
      }
    });

  }



}
