import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/core/services/users/user.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent implements OnInit {

  url: any;
  logo = 'assets/img/TATlogo.PNG'
  showAdminlogo: boolean = false;
  tenantlogo: boolean = false;
  applogo: string = "";
  otp: string | null = null;
  email_id: string | null = null;
  id: string | null = null;
  loginData: any;
  optSendsuccesmsg: any;
  OTPError: any;
  verifysuccessMsg: any;

  constructor(
    private userservice: UserService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,

  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.otp = params['otp'];
      this.email_id = params['email_id'];
      this.id = params['id'];
    });
    this.verifyemail()
  }

  verifyemail() {
    if (this.id) {
      this.loginData = {
        email: this.email_id,
        otp: this.otp,
        is_register: 0,
      };
      this.userservice.VerifyOTP(this.id, this.loginData).subscribe({
        next: (response: any) => {
          this.verifyemail = response.message;
          this.router.navigate(['/candidate-profile']);
          this.notificationService.showSuccess("Email Verification Done Successfully!")
          // this.step = '3';
        },
        error: (error) => {
          console.log(error)
          this.router.navigate(['/candidate-profile']);
          this.notificationService.showError("Please try again.");
          // switch (error.status) {
          //   case 422:
          //     if (error.error.otp) {
          //       this.otpError = error.error.otp[0];
          //     }
          //     break;
          //   default:
          // }
        }
      })

    } else {

      this.loginData = {
        email: this.email_id,
        otp: this.otp,
        is_register: 1,
      };


      this.userservice.VerifyRegisterOTP(this.loginData).subscribe({
        next: (response: any) => {
          this.verifysuccessMsg = response.message;
          this.router.navigate(['/jobs']);
          // this.notificationService.showSuccess(response.message)
          this.notificationService.showSuccess("Email Verification Done Successfully!")
        },
        error: (error) => {
          this.router.navigate(['/jobs']);
          switch (error.status) {
            case 422:
              if (error.error.otp) {
                this.OTPError = error.error.otp[0];
              }
              break;
            default:
          }
          this.notificationService.showError("Please try again.");
        }
      })

    }






  }



  gobacktologin() {
    this.router.navigate(['jobs']);
  }







}
