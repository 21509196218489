import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TenantsRoutingModule } from './tenants-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { TenantsListComponent} from './pages/tenants-list/tenants-list.component';

@NgModule({
  declarations: [
  
  ],
  imports: [
    CommonModule,
    TenantsRoutingModule
  ]
})
export class TenantsModule { }
