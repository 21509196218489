import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { StorageKey, StorageService } from 'src/app/core/common/storage.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { ProjectService } from 'src/app/core/services/projects/project.service';
import { environment } from 'src/environments/environment';
import { CandidatejobsService } from 'src/app/core/services/candidatejobs/candidatejobs.service';
import { UserService } from 'src/app/core/services/users/user.service';



@Component({
  selector: 'app-candidate-login',
  templateUrl: './candidate-login.component.html',
  styleUrls: ['./candidate-login.component.css']
})
export class CandidateLoginComponent implements OnInit, AfterViewChecked {

  loginFrm!: FormGroup;
  sub!: Subscription
  otpcount: any;
  showResend = false
  showforgot = true
  forgetPasswordFrm!: FormGroup;
  returnUrl: any;
  submitted: boolean = false;
  forgetPasswordSubmitted: boolean = false;
  loginApiCall: boolean = false;
  loginData: any;
  loginError: string = "";
  forgetPasswordError: string = "";
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
  showPassword: boolean = false;
  showLogin: boolean = true;
  passwordRecoveryMailSent: string = "";
  tenantsadmin: boolean = false;
  continuebutton: boolean = true;
  loginbutton: boolean = false;
  tenantData: any;
  enterotp: boolean = false;
  tenantsList: any = [];
  selectedOption: any;
  selectedtagValue: any;
  otpmessage: any;
  tenantlogo: boolean = false;
  url: any;
  logo = 'assets/img/TATlogo.PNG'
  selectedtenantValue: any;
  selectedtenantValueforForgotPw: any;
  newObject!: {};
  selectedTenant: any;
  mytenantid: any;
  tenantRole: any;
  loginError2: string = "";
  superadminflag: any;
  loginEmailError: any;
  submitted2: boolean = false;
  loginApiCall2: boolean = false;
  superadminid: any;
  showAdminlogo: boolean = false;
  url2 = 'assets/img/TATlogo.PNG';
  applogo: string = "";
  signinError: any;
  signinEmailError: any;
  storedTenantName: any;
  tenantName: any;
  showtenantDropdown: boolean = false;
  len: any;
  forgotpwData: any;
  tenantsforforgotpwList: any = [];
  resetpwtenant: any;
  @ViewChild('otp', { static: false })
  otp!: ElementRef;

  candidateflag: any;
  tenantId: any;
  tenantdetails: any

  job_id: any;
  job_name: any;
  job_city_name: any;
  job_state_name: any;

  emailverifyerror: any
  showVerificationLink: any

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authservice: AuthService,
    private storageService: StorageService,
    private CandidatejobsService: CandidatejobsService,
    private userservice: UserService,
    private route: ActivatedRoute,) {
    if (this.authservice.isLoggedIn()) {
      if (StorageService.getTenant() != '0') {
        let is_client_module_access_disabled = this.storageService.getValue('is_client_module_access_disabled')
        if (is_client_module_access_disabled == '1')
          this.router.navigate(['search-list']);
        else
          this.router.navigate(['dashboard']);
      } else
        this.router.navigate(['tenants-list']);
    }
  }
  ngAfterViewChecked(): void {
    if (this.enterotp) {
      this.otp.nativeElement.focus();
    }
  }
  ngOnInit(): void {

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

    this.onLoad();
    if (this.getCookie('last_login_mail')) {
      this.f['email'].setValue(this.getCookie('last_login_mail'))
    }



    this.route.queryParams.subscribe(params => {
      this.tenantId = params['tenantId'];

      this.job_id = params['job_id'];
      this.job_name = params['job_name'];
      this.job_city_name = params['job_city_name'];
      this.job_state_name = params['job_state_name'];

      if (this.tenantId === 'jobs') {
        // Handle the case where tenantId is 'jobs'
      } else {
        this.GetTenantDetails();
      }
    });


  }

  GetTenantDetails() {
    this.CandidatejobsService.GetTenantdetails(this.tenantId).subscribe({
      next: (response: any) => {
        this.tenantdetails = response.data
        this.url = this.tenantdetails.logo_path;
        this.tenantlogo = true;
      },
      error: (error: any) => {

      }
    })
  }

  get f() { return this.loginFrm.controls; }
  get ForgetPasswordFrm() { return this.forgetPasswordFrm.controls; }

  onLoad() {
    this.onBuildForm();
  }

  onBuildForm() {
    this.loginFrm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.pattern(this.emailPattern)]],
      remember_me: [true],
      password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
      tenant: [null],
      otp: [null]
    });

    this.forgetPasswordFrm = this.formBuilder.group({
      useremail: [null, [Validators.required, Validators.pattern(this.emailPattern)]],
      tenant: [null]
    });

    this.forgetPasswordFrm.controls['useremail'] = this.loginFrm.controls['email']

  }
  forgetPassword() {
    this.forgetPasswordSubmitted = true;
    if (this.forgetPasswordFrm.invalid)
      return;

    this.forgotpwData = {
      email: this.forgetPasswordFrm.controls['useremail'].value
    };

    if (!this.superadminflag)
      this.forgotpwData.tenant = this.forgetPasswordFrm.controls['tenant'].value;
    // console.log(this.forgotpwData,"forgot")

    // this.resetpwtenant = this.forgetPasswordFrm.controls['tenant'].value
    // console.log(this.resetpwtenant);
    // var myresetpwtenant =  this.storageService.setValue(StorageKey.currentTenantIdforresetpw, this.resetpwtenant);
    // console.log(myresetpwtenant);

    this.authservice.ForgetPassword(this.forgotpwData).subscribe({
      next: (response) => {
        this.showLogin = true;
        this.showforgot = false
        this.passwordRecoveryMailSent = response.message;
        //this.notificationService.showSuccess(response.message);
      },
      error: (error) => {
        this.forgetPasswordError = error?.error?.email[0];
        this.forgetPasswordSubmitted = false;
      }
    });

  }





  togglePassword() {
    this.showPassword = !this.showPassword;
  }



  resetLoginForm() {
    this.loginError2 = "";
    // this.loginFrm.reset();
    this.loginError = "";
    this.loginEmailError = "";

  }


  continuefortenants() {
    this.resetLoginForm();
    this.continuebutton = true;
    this.submitted2 = true;
    this.tenantData = {
      email: this.f['email'].value
    }
    if (this.f['remember_me'].value)
      this.setCookie('last_login_mail', this.tenantData.email);

    //this.loginFrm.get('email').disable()
    this.loginApiCall = true;
    this.authservice.CheckTenantCreditial(this.tenantData).subscribe({
      next: (response) => {
        this.tenantsList = response.tenants;
        this.tenantsforforgotpwList = this.tenantsList;
        this.superadminflag = response.is_super_admin;
        this.candidateflag = response.is_candidate

        this.len = this.tenantsList.length;

        if (this.superadminflag === false && this.len == 0 && !this.candidateflag) {
          this.loginError2 = "Provided email address is not matching any of our records or the account is inactive. Please contact the support team.";
          this.tenantsadmin = false;
          this.continuebutton = true;
          this.loginbutton = false;
        }
        this.loginApiCall = false;
        //  if((this.superadminflag === true || this.superadminflag === false) && this.len != 0){
        if ((this.superadminflag === true) || (this.superadminflag === false && this.len != 0)) {
          this.tenantsadmin = true;
          this.continuebutton = false;
          this.loginbutton = true;
        }
        else if (this.candidateflag && this.len == 0) {
          // this.loginError2 = "Provided email address is not matching any of our records or the account is inactive. Please contact the support team.";
          this.tenantsadmin = true;
          this.continuebutton = true;
          this.loginbutton = true;
        } else {
          this.loginError2 = "Provided email address is not matching any of our records or the account is inactive. Please contact the support team.";
          this.tenantsadmin = false;
          this.continuebutton = true;
          this.loginbutton = false;
        }

        if (this.superadminflag) {
          this.newObject = {
            id: 0, name: 'TATracker Tenant Administration',
            logo_path: "'../../assets/img/TATlogo.PNG'"
          };
          this.tenantsList.unshift(this.newObject);
          this.selectedtenantValue = this.tenantsList[0].id;
        }

        if (!this.superadminflag && !this.candidateflag) {
          this.selectedtenantValue = this.tenantsList[0].id;
        }

      },
      error: (error) => {
        this.continuebutton = true;
        this.submitted2 = false;
        this.loginApiCall = false;
        this.authservice.logout();
        // this.notificationService.showError(error.message);
        // this.loginError = error.error.message;
        this.continuebutton = true;
        this.loginbutton = false;
        this.tenantsadmin = false;
        switch (error.status) {
          case 422:
            if (error.error.email) {
              this.loginEmailError = error.error.email[0];
            }
            break;
          default:
          //Nothing
        }
      }
    });


  }




  dropdownselect(event: any) {
    if (event != undefined) {
      var selectedTenant: any = this.tenantsList.find((tenant: any) => tenant.id == event);
      this.url = selectedTenant.logo_path;
      this.selectedTenant = selectedTenant.id;
      this.storedTenantName = selectedTenant.name;

      if (this.selectedTenant != 0) {
        this.tenantlogo = true;
        this.showAdminlogo = false;
      } else if (this.selectedTenant == 0) {

        this.tenantlogo = false;
        this.showAdminlogo = true;
        this.applogo = this.url2;
      } else {
        this.tenantlogo = false;
      }
    }
    else {
      this.tenantlogo = false;
    }

    // if (this.selectedTenant != undefined) {
    //   var currentTenantrole = this.storageService.setValue(StorageKey.currentTenantId, this.selectedTenant);
    //   this.tenantRole = currentTenantrole;
    // }

    // if (this.selectedTenant != undefined) {
    //   var currentTenantname = this.storageService.setValue(StorageKey.currentTenantnameId, this.storedTenantName);
    //   this.tenantName = currentTenantname;
    // }

    if (this.selectedTenant !== undefined) {
      this.storageService.setValue(StorageKey.currentTenantId, this.selectedTenant);
      this.tenantRole = this.selectedTenant;
    }

    if (this.selectedTenant !== undefined) {
      this.storageService.setValue(StorageKey.currentTenantnameId, this.storedTenantName);
      this.tenantName = this.storedTenantName;
    }



  }

  loggedin() {
    // console.log("logged in clled");
    this.loginData = {
      email: this.f['email'].value,
      otp: this.f['otp'].value,
      tenant: this.f['tenant'].value
    };
    if (this.f['otp']?.value?.trim() == "" || this.f['otp']?.value?.trim() == null) {
      this.notificationService.showError('Please check email and enter the OTP');
      return;
    }
    // console.log(this.loginData);
    this.login(this.loginData)
  }


  forgotpwshow() {
    if (this.superadminflag === true) {
      // this.newObject = { id : 0, name :'TATracker Tenant Administration', logo_path : "'../../assets/img/TATlogo.PNG'"}
      // this.tenantsList.unshift(this.newObject);
      this.selectedtenantValueforForgotPw = this.tenantsList[0].id;
      // console.log(this.selectedtenantValueforForgotPw);
    }


    if (this.superadminflag === false && !this.candidateflag) {
      this.tenantsList = this.tenantsforforgotpwList;
      // this.loginError2 = "Provided email address is not matching any of our records.";
      this.selectedtenantValueforForgotPw = this.tenantsList[0].id;
      // console.log(this.selectedtenantValueforForgotPw);
    }

  }

  // dropdownselectforForgotPw(event: any) {
  //   if (event != undefined) {
  //     var selectedTenant: any = this.tenantsList.find((tenant: any) => tenant.id == event);
  //     this.url = selectedTenant.logo_path;
  //     this.selectedTenant = selectedTenant.id;
  //     this.storedTenantName = selectedTenant.name;

  //     if (this.selectedTenant != 0) {
  //       this.tenantlogo = true;
  //       this.showAdminlogo = false;
  //     } else if (this.selectedTenant == 0) {

  //       this.tenantlogo = false;
  //       this.showAdminlogo = true;
  //       this.applogo = this.url2;
  //     } else {
  //       this.tenantlogo = false;
  //     }
  //   }
  //   else {
  //     this.tenantlogo = false;
  //   }
  //   var currentTenantrole = this.storageService.setValue(StorageKey.currentTenantIdforresetpw, this.selectedTenant);
  //   this.tenantRole = currentTenantrole;
  //   if (this.selectedTenant != undefined) {
  //     var currentTenantrole = this.storageService.setValue(StorageKey.currentTenantIdforresetpw, this.selectedTenant);
  //     this.tenantRole = currentTenantrole;
  //   }

  //   if (this.selectedTenant != undefined) {
  //     var currentTenantname = this.storageService.setValue(StorageKey.currentTenantnameId, this.storedTenantName);
  //     this.tenantName = currentTenantname;
  //   }



  // }

  dropdownselectforForgotPw(event: any) {
    if (event !== undefined) {
      // Find the selected tenant from the list using the event ID
      const selectedTenant = this.tenantsList.find(
        (tenant: any) => tenant.id === event
      );

      if (selectedTenant) {
        this.url = selectedTenant.logo_path;  // Set the URL to the tenant's logo path
        this.selectedTenant = selectedTenant.id;  // Set the selected tenant ID
        this.storedTenantName = selectedTenant.name;  // Store the tenant name

        // Determine which logo to display based on the selected tenant
        if (this.selectedTenant !== 0) {
          this.tenantlogo = true;
          this.showAdminlogo = false;
        } else {
          this.tenantlogo = false;
          this.showAdminlogo = true;
          this.applogo = this.url2;  // Use the default application logo
        }

        // Save the selected tenant ID and name to storage
        this.storageService.setValue(
          StorageKey.currentTenantIdforresetpw,
          this.selectedTenant
        );
        this.tenantRole = this.selectedTenant;  // Directly assign tenant ID to tenantRole

        this.storageService.setValue(
          StorageKey.currentTenantnameId,
          this.storedTenantName
        );
        this.tenantName = this.storedTenantName;  // Directly assign tenant name to tenantName
      } else {
        // If the selected tenant is not found, reset the logo display flags
        this.tenantlogo = false;
        this.showAdminlogo = false;
      }
    } else {
      // If event is undefined, ensure that no tenant logo is displayed
      this.tenantlogo = false;
      this.showAdminlogo = false;
    }
  }


  setCookie(name: any, value: any) {
    const date = new Date();
    date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000)); // Calculate the expiry date
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + JSON.stringify(value) + ";" + expires + ";path=/";
  }
  getCookie(name: any) {
    const cookieName = name + "=";
    const cookies = decodeURIComponent(document.cookie).split(';');

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return JSON.parse(cookie.substring(cookieName.length, cookie.length));
      }
    }
    return null;
  }




  signin() {
    this.continuebutton = false;
    this.submitted = true;

    this.emailverifyerror = "";

    if (this.loginFrm.invalid)
      return;

    if (this.f['password'].value.trim() == "") {
      this.notificationService.showError('Please Enter Password');
      return;
    }

    if (this.candidateflag) {
      this.loginData = {
        email: this.f['email'].value,
        password: this.f['password'].value,
        tenant: 0,
        otp_token: this.getCookie('otp_token') || null
      };
    } else {
      this.loginData = {
        email: this.f['email'].value,
        password: this.f['password'].value,
        tenant: this.f['tenant'].value,
        otp_token: this.getCookie('otp_token') || null
      };
    }


    this.authservice.twofactorGetOTP(this.loginData).subscribe({
      next: (response: any) => {
        document.getElementById('otp')?.focus();
        switch (response.status) {
          case 202:
            // console.log("202");
            if (response.body.user && response.body.user.password_recovery_token) {
              this.authservice.setTenant(response.body?.tenant);
              this.router.navigate(['reset-password/' + response.body.user.password_recovery_token], { queryParams: { tenant: StorageService.getTenant() } });
            } else {
              this.enterotp = true;

              this.otpmessage = response.message
              this.notificationService.showSuccess(response.message);
              this.sixtySecondInterval();
              this.otp.nativeElement.focus();
            }
            break;
          case 200:

            this.storageService.setValue(StorageKey.authToken, response.body.token);
            this.storageService.setValue(StorageKey.currentUser, JSON.stringify(response.body.user));
            this.storageService.setValue(StorageKey.currentAvtar, response.body.user?.avatar_path);
            this.storageService.setValue(StorageKey.currentUsername, response.body.user?.name);
            this.storageService.setValue(StorageKey.currentUserid, response.body.user?.id);
            this.storageService.setValue(StorageKey.currentUserRoleId, response.body.user?.role.id);
            this.storageService.setValue(StorageKey.brandLogo, response.body.brand_logo);
            this.storageService.setValue(StorageKey.is_client_module_access_disabled, response.body.is_client_module_access_disabled + "");
            this.storageService.setValue(StorageKey.candidate_id, response.body.user?.candidate?.id);
            // this.storageService.setValue(StorageKey.is_public_search, response.body.user?.is_public_search);
            this.storageService.setValue(StorageKey.is_public_search, response.body?.is_public_search);

            // this.storageService.setValue(StorageKey.selectedTenant, 0);
            this.authservice.setTenant(response.body?.tenant);
            localStorage.setItem('tenant', response.body?.tenant)
            let brand_logo = response.body?.brand_logo;
            this.authservice.setlogo(brand_logo);
            let superAdmin = response.body.user.is_super_admin;
            // console.log(superAdmin)
            localStorage.setItem('superadminflag', superAdmin)

            // let otp_token={otp_token:response?.otp_token}
            // this.setCookie('otp_token',otp_token)

            if (this.loginData.tenant != "0") {
              this.storageService.setValue(StorageKey.currentTenantId, this.loginData.tenant);
              environment.apiUrl = environment.apiUrl2 + this.loginData.tenant + '/';
            } else {
              environment.apiUrl = environment.apiUrl2
            }



            // if (this.selectedTenant == 0) {
            //   this.router.navigate(['tenants-list']);
            // } else {
            //   let is_client_module_access_disabled = this.storageService.getValue('is_client_module_access_disabled')
            //   if (is_client_module_access_disabled == '1')
            //     this.router.navigate(['dashboard']);
            //   else
            //     this.router.navigate(['dashboard']);

            // }

            if (this.selectedTenant == 0) {
              this.router.navigate(['tenants-list']);
            } else {
              let is_client_module_access_disabled = this.storageService.getValue('is_client_module_access_disabled')
              if (is_client_module_access_disabled == '1')
                this.router.navigate(['dashboard']);
              else if (response.body.user && response.body.user.role && response.body.user.role.id === 2) {
                this.storageService.setValue(StorageKey.currentTenantId, 'null');

                const apply_job_id = localStorage.getItem('apply_job_id');
                const apply_tetant_name = localStorage.getItem('apply_tetant_name');


                if (apply_job_id && apply_tetant_name) {
                  this.router.navigate([`${apply_tetant_name}/public`]);
                } else {
                  this.router.navigate(['some-other-route']);
                }

              } else {
                this.router.navigate(['dashboard']);
              }

            }
            this.continuebutton = false;
            this.loginApiCall2 = false;


            break;
          default:
        }

      },
      error: (error: any) => {
        console.log(error)
        switch (error.status) {
          case 422:
            if (error.error.email) {
              this.emailverifyerror = error.error.email[0];
            }
            if (error.error.show_verification_link) {
              this.showVerificationLink = !!error.error.show_verification_link;
            }
            break;
          default:
        }
        // this.notificationService.showError(error.error.email);

        console.log(this.showVerificationLink)
      }
    })
    // this.login(this.loginData);
  }

  sixtySecondInterval() {
    this.showResend = false;
    var emittedNo = interval(1000);
    // console.log(emittedNo, "emittedno")
    this.sub = emittedNo.subscribe((res) => {
      this.otpcount = 300 - res;
      if (this.otpcount == 0) {
        this.sub.unsubscribe();
        this.showResend = true;
      }
    });
  }

  resendOtp() {
    this.authservice.twofactorResendOTP(this.loginData).subscribe({
      next: (response: any) => {
        this.sixtySecondInterval();
        this.notificationService.showSuccess(response.message);
      },
      error: (error: any) => {
      }
    })
  }


  resendEmailLink() {

    this.loginData = {
      email: this.f['email'].value,
      password: this.f['password'].value,
      tenant: 0,
      otp_token: this.getCookie('otp_token') || null,
      is_register: 1,
    };

    this.userservice.sendOTPforRegister(this.loginData).subscribe({
      next: (response: any) => {
        this.notificationService.showSuccess(response.message);

      },
      error: (error) => {
        console.log(error)
        // switch (error.status) {
        //   case 422:
        //     if (error.error.email) {
        //       this.emailError = error.error.email[0];
        //     }
        //     if (error.error.password) {
        //       this.passwordError = error.error.password[0];
        //     }
        //     break;
        //   default:
        // }
        // this.notificationService.showError(error.message);
      }
    })


  }

  // this method is called when 1st time login login user is different
  private login(data: any) {
    // return
    this.loginApiCall2 = true;
    this.continuebutton = false;

    if (this.candidateflag) {
      data.tenant = 0;
    }

    // this.authservice.CheckLoginCreditial(data).subscribe({
    this.authservice.twofactorSendOTP(data).subscribe({
      next: (response: any) => {

        this.storageService.setValue(StorageKey.authToken, response.token);
        this.storageService.setValue(StorageKey.currentUser, JSON.stringify(response.user));
        this.storageService.setValue(StorageKey.currentAvtar, response.user.avatar_path);
        this.storageService.setValue(StorageKey.currentUsername, response.user.name);
        this.storageService.setValue(StorageKey.currentUserid, response.user.id);
        this.storageService.setValue(StorageKey.currentUserRoleId, response.user.role.id);
        this.storageService.setValue(StorageKey.brandLogo, response.brand_logo);
        this.storageService.setValue(StorageKey.is_client_module_access_disabled, response.is_client_module_access_disabled + "");
        // this.storageService.setValue(StorageKey.candidate_id, response.body.user?.candidate_id);
        this.storageService.setValue(StorageKey.candidate_id, response.user?.candidate?.id);
        // this.storageService.setValue(StorageKey.is_public_search, response.user?.is_public_search);
        this.storageService.setValue(StorageKey.is_public_search, response?.is_public_search);

        // this.storageService.setValue(StorageKey.selectedTenant, 0);
        let superAdmin = response.user.is_super_admin;
        // console.log(superAdmin);
        this.authservice.setTenant(response.tenant);
        localStorage.setItem('tenant', response.tenant)
        localStorage.setItem('superadminflag', superAdmin)
        let brand_logo = response?.brand_logo;
        this.authservice.setlogo(brand_logo);
        let otp_token = { otp_token: response?.otp_token }
        this.setCookie('otp_token', otp_token)

        if (data.tenant != "0") {
          this.storageService.setValue(StorageKey.currentTenantId, data.tenant);
          environment.apiUrl = environment.apiUrl2 + data.tenant + '/';
        } else {
          environment.apiUrl = environment.apiUrl2
        }

        // if (this.selectedTenant == 0) {
        //   this.router.navigate(['tenants-list']);
        // } else if (response.user.role.id === 2) {
        //   // this.router.navigate(['dashboard']);
        //   this.router.navigate(['register']);
        // }
        // else {
        //   this.router.navigate(['dashboard']);
        // }
        if (this.selectedTenant === 0) {
          this.router.navigate(['tenants-list']);
        } else if (response.user && response.user.role && response.user.role.id === 2) {
          // this.storageService.setValue(StorageKey.currentTenantId, 0);
          this.storageService.setValue(StorageKey.currentTenantId, 'null');
          this.router.navigate(['candidate-profile']);
          const apply_job_id = localStorage.getItem('apply_job_id');
          const apply_tetant_name = localStorage.getItem('apply_tetant_name');

          if (apply_job_id && apply_tetant_name) {
            this.router.navigate([`${apply_tetant_name}/public`]);
          } else {
            this.router.navigate(['some-other-route']);
          }
        } else {
          this.router.navigate(['dashboard']);
        }

        this.continuebutton = false;
        this.loginApiCall2 = false;
      },
      error: (error) => {
        // this.loginError = error.error.email;
        this.submitted = false;
        this.loginApiCall2 = false;
        this.continuebutton = false;
        this.authservice.logout();
        // this.notificationService.showError(error.message);
        this.signinError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.otp) {
              this.signinEmailError = error.error.otp;
              this.notificationService.showError(error.error.otp);
            }

            break;
          default:
        }
      }
    });

  }

  gobacktologin() {
    this.router.navigate(['/jobs']);
    window.location.reload();
  }

  signuppage() {
    this.router.navigate(['/register']);
  }

}
