import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientStatusRoutingModule } from './client-status-routing.module';
import { ClientStatusListComponent } from './pages/client-status-list/client-status-list.component';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ClientStatusRoutingModule
  ]
})
export class ClientStatusModule { }
